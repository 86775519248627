import { OrgUserStatus } from "./User";
import { SchoolUser } from "./SchoolUser";
import { PrimaryGroup, SecondaryGroup } from "./User";

export enum UserMissionStatus {
  OK,
  Bad
}

// Added selected field to User class for checkbox functionality
export class ClassUser extends SchoolUser {
    selected?: boolean;
    lastWorkoutDate: Date;
    mostFrequentWorkout: string;
    fitnessLevel: number;
    missionStatus: UserMissionStatus
    levelUp: boolean;
  
    constructor(
        userId: number,
        fullname: string,
        username: string,
        email: string,
        cellphone: string,
        orgId: number,
        primaryGroup?: PrimaryGroup,
        secondaryGroup?: SecondaryGroup,
        pfpLink?: string,
        userStatus?: OrgUserStatus,
        lastWorkoutDate?: Date,
        mostFrequentWorkout?: string,
        fitnessLevel?: number,
        missionStatus?: UserMissionStatus,
        levelUp?: boolean,
        selected?: boolean
    ) {
        super(userId, fullname, username, email, cellphone, orgId, primaryGroup, secondaryGroup, pfpLink, userStatus);
        this.selected = selected;
        this.lastWorkoutDate = lastWorkoutDate;
        this.mostFrequentWorkout = mostFrequentWorkout;
        this.fitnessLevel = fitnessLevel;
        this.missionStatus = missionStatus;
        this.levelUp = levelUp;
    }
  
    public getSelected(): boolean {
      return this.selected;
    }
  
    public setSelected(selected: boolean): void {
      this.selected = selected;
    }

    public getLastWorkoutDate(): Date {
      return this.lastWorkoutDate;
    }
    
    public setLastWorkoutDate(lastWorkoutDate: Date): void {
      this.lastWorkoutDate = lastWorkoutDate;
    }

    public getMostFrequentWorkout(): string {
      return this.mostFrequentWorkout;
    }

    public setMostFrequentWorkout(mostFrequentWorkout: string): void {
      this.mostFrequentWorkout = mostFrequentWorkout;
    }

    public getFitnessLevel(): number {
      return this.fitnessLevel;
    }

    public setFitnessLevel(fitnessLevel: number): void {
      this.fitnessLevel = fitnessLevel;
    }

    public getMissionStatus(): UserMissionStatus {
      return this.missionStatus;
    }

    public setMissionStatus(missionStatus: UserMissionStatus) {
      this.missionStatus = missionStatus;
    }

    public isLevelUp(): boolean {
      return this.levelUp;
    }

    public setLevelUp(levelUp: boolean) {
      this.levelUp = levelUp;
    }
  }
  