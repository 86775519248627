export enum ClassType {
  Private,
  Public
}

export class ClassCourse {
  private className: string;
  private mainInstructorId: number;
  private mainInstructorName: string;
  private classId: number;
  private orgId: number;
  private capacity: number;
  private classType: ClassType;
  private open: boolean;
  private participantList: number[];
  private classIntro: string;
  private thumbnail: string;

  private startDate: Date;
  private endDate: Date; // duration can be set to null

  constructor(
    className: string,
    classId: number,
    mainInstructorName: string,
    mainInstructorId: number,
    orgId: number,
    capacity: number,
    classType: ClassType,
    open: boolean,
    participantList: number[],
    startDate: Date,
    endDate: Date,
    classIntro: string,
    thumbnail: string
  ) {
    this.className = className;
    this.classId = classId;
    this.mainInstructorName = mainInstructorName;
    this.mainInstructorId = mainInstructorId;
    this.orgId = orgId;
    this.capacity = capacity;
    this.classType = classType;
    this.open = open;
    this.participantList = participantList;
    this.startDate = startDate;
    this.endDate = endDate;
    this.classIntro = classIntro;
    this.thumbnail = thumbnail;
  }

  public getClassName(): string {
    return this.className;
  }

  public setClassName(className: string): void {
    this.className = className;
  }

  public getClassId(): number {
    return this.classId;
  }

  public getMainInstructorName(): string {
    return this.mainInstructorName;
  }

  public setMainInstructorName(mainInstructorName: string): void {
    this.mainInstructorName = mainInstructorName;
  }

  public getMainInstructorId(): number {
    return this.mainInstructorId;
  }

  public setMainInstructorId(mainInstructorId: number): void {
    this.mainInstructorId = mainInstructorId;
  }

  public setClassId(classId: number): void {
    this.classId = classId;
  }

  public getOrgId(): number {
    return this.orgId;
  }

  public setOrgId(orgId: number): void {
    this.orgId = orgId;
  }

  public getCapacity(): number {
    return this.capacity;
  }

  public setCapacity(capacity: number): void {
    this.capacity = capacity;
  }

  public getClassType(): ClassType {
    return this.classType;
  }

  public setClassType(classType: ClassType): void {
    this.classType = classType;
  }

  public isOpen(): boolean {
    return this.open;
  }

  public setOpen(open: boolean): void {
    this.open = open;
  }

  public getParticipantList(): number[] {
    return this.participantList;
  }

  public setParticipantList(participantList: number[]): void {
    this.participantList = participantList;
  }

  public getStartDate(): Date {
    return this.startDate;
  }

  public setStartDate(startDate: Date): void {
    this.startDate = startDate;
  }

  public getEndDate(): Date {
    return this.endDate;
  }

  public setEndDate(endDate: Date): void {
    this.endDate = endDate;
  }

  public getClassIntro(): string {
    return this.classIntro;
  }

  public setClassIntro(classIntro: string) {
    this.classIntro = classIntro;
  }
  
  public getThumbnail(): string {
    return this.thumbnail;
  }

  public setThumbnail(thumbnail: string) {
    this.thumbnail = thumbnail;
  }
}
