import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Member } from '../models';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
  constructor(
    private router: Router,
  ) {}

  canActivate() {
    if (!localStorage.getItem('token')) {
      return true;
    }
    
    // if ( localStorage.getItem('loginStatus') === 'incomplete' ) {
    //   let lang = location.pathname.substr(1,2);
    //   if( lang === 'kr' ) {
    //     this.router.navigate(['kr/incomplete']);
    //   } else if ( lang === 'en' ){
    //     this.router.navigate(['en/incomplete']);
    //   }
    //   return false;
    // }

    this.router.navigate(['/home']);
    
    return false;
  }
}
