import { Injectable } from '@angular/core';
import { OrganizationService } from './organization.service';
import { ClassMission } from '../models/ClassMission';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firstValueFrom, map, tap } from 'rxjs';

const httpOptionsNoToken = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class ClassMissionService {
  private apiOrganizationUrl = environment.apiUrl + '/organization';
  private storageKeyPrefix: string = 'mission';
  private orgId: number;

  constructor(
    private orgService: OrganizationService,
    private http: HttpClient
  ) {
    this.orgId = this.orgService.getOrganizationForAdminUser().getId();
  }

  /**
 * Fetches all missions for a specific class.
 * @param classId the ID of the class
 * @returns a promise resolving to an array of missions for the class
 */
  getMissionsForClass(classId: number): Promise<ClassMission[]> {
    const body = { class_id: classId };

    return firstValueFrom(
      this.http.post<ClassMission[]>(`${this.apiOrganizationUrl}/class/mission/get/class`, body, httpOptionsNoToken).pipe(
        map((missions) => missions.map((mission) => this.deserializeMission(mission))),
        //tap((missions) => console.log('Fetched missions for class:', missions)) // Optional logging
      )
    );
  }

  /**
   * Fetches all missions for a specific user.
   * @param userId the ID of the user
   * @returns a promise resolving to an array of missions for the user
   */
  getMissionsForUser(userId: number): Promise<ClassMission[]> {
    const body = { user_id: userId };

    return firstValueFrom(
      this.http.post<ClassMission[]>(`${this.apiOrganizationUrl}/class/mission/get/user`, body, httpOptionsNoToken).pipe(
        map((missions) => missions.map((mission) => this.deserializeMission(mission))),
        //tap((missions) => console.log('Fetched missions for user:', missions)) // Optional logging
      )
    );
  }

  /**
   * Fetches details of a mission by its ID.
   * @param missionId the ID of the mission
   * @returns a promise resolving to the mission details
   */
  getMissionById(missionId: number): Promise<ClassMission> {
    const body = { mission_id: missionId };

    return firstValueFrom(
      this.http.post<ClassMission>(`${this.apiOrganizationUrl}/class/mission/get/id`, body, httpOptionsNoToken).pipe(
        map((mission) => (this.deserializeMission(mission)))
        , //tap((mission) => console.log('Fetched mission by ID:', mission)) // Optional logging
      )
    );
  }

  /**
 * Creates a new mission.
 * @param mission the mission to create
 * @returns a promise resolving to 1 for success or 0 for failure
 */
  createMission(mission: ClassMission): Promise<number> {
    const formData = new FormData();

    // Append mission fields
    formData.append('userId', mission.getUserId().toString());
    formData.append('classId', mission.getClassId().toString());
    formData.append('sessionsPerWeek', mission.getSessionsPerWeek().toString());
    formData.append('sessionDuration', mission.getSessionDuration().toString());
    formData.append('sessionDays', JSON.stringify(mission.getSessionDays())); // Convert array to JSON string
    formData.append('startDate', new Date(mission.getStartDate()).toISOString());
    formData.append('endDate', new Date(mission.getEndDate()).toISOString());
    formData.append('exerciseGoal', mission.getExerciseGoal());

    return firstValueFrom(
      this.http.post<number>(`${this.apiOrganizationUrl}/class/mission/add`, formData).pipe(
        //tap((res) => console.log('Created mission response:', res)) // Optional logging
      )
    );
  }

  /**
   * Updates the details of an existing mission.
   * @param mission the mission to update
   * @returns a promise resolving to 1 for success or 0 for failure
   */
  saveMission(mission: ClassMission): Promise<number> {
    const formData = new FormData();
    // Append mission fields
    formData.append('missionId', mission.getMissionId().toString());
    formData.append('sessionsPerWeek', mission.getSessionsPerWeek().toString());
    formData.append('sessionDuration', mission.getSessionDuration().toString());
    formData.append('sessionDays', JSON.stringify(mission.getSessionDays())); // Convert array to JSON string
    formData.append('startDate', new Date(mission.getStartDate()).toISOString());
    formData.append('endDate', new Date(mission.getEndDate()).toISOString());
    formData.append('exerciseGoal', mission.getExerciseGoal());

    return firstValueFrom(
      this.http.put<number>(`${this.apiOrganizationUrl}/class/mission/mod`, formData).pipe(
        //tap((res) => console.log('Saved mission response:', res)) // Optional logging
      )
    );
  }

  deserializeMission(mission: any): ClassMission {
    const weeklyExerciseTimeInMin = mission.weeklyExerciseTime?.map(
      (time: number) => time = Math.round(time / 60)
    ) ?? null;

    const startDate = new Date(mission.startDate);
    const endDate = new Date(mission.endDate);

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    return new ClassMission(
      mission.missionId,
      mission.userId,
      mission.classId,
      mission.orgId,
      Math.round(mission.userExerciseTime / 60),
      weeklyExerciseTimeInMin,
      mission.userNumSessions,
      mission.sessionsPerWeek,
      mission.sessionDuration,
      mission.sessionDays,
      new Date(mission.startDate),
      new Date(mission.endDate),
      mission.exerciseGoal
    );
  }
}
