import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Organization } from '../models/Organization';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Accept": "*",
    'Access-Control-Allow-Origin': 'https://tboxfit.com',
  })
};

@Injectable({
  providedIn: 'root'
})
export class SchoolSearchService {

  constructor(private http: HttpClient,) { }

  schoolApiUrl = 'https://open.neis.go.kr/hub/schoolInfo';
  confmKey = '8a07b34d08424d35b259b33baad8df31';
  resultType = 'json';
  pIndex: number = 1;
  pSize: number = 100;

  /**
   * Function to query all public schools with matching names in ROK through the K-Government Open API
   * @param schoolName string; school name in Korean
   * @returns a list of matching schools (with all 25 output params) in the specified result type
   *          (XML / JSON)
   */
  getSchools(schoolName: string): Observable<any> {
    return this.http.get(this.schoolApiUrl + '?' +
      'KEY=' + this.confmKey + '&' +
      'Type=' + this.resultType + '&' +
      'pIndex=' + this.pIndex + '&' +
      'pSize=' + this.pSize + '&' +
      'SCHUL_NM=' + schoolName
    );
  }

  saveSelectedSchool(data: any): void {
    localStorage.setItem(`school${data.SD_SCHUL_CODE}`, JSON.stringify(data));
  }
}

