import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../signup/must-match.validator';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {


  userInfo = {
    id: -1,
    fullname: '',
    username: '',
    email: '',
    cnum1: '',
    cnum2: '',
    cnum3: '',
    photoUrl: '',
    acceptTerms: false,
  }
  checkUsernameText = "아이디 중복 확인";
  checkUsernameDup;

  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private userService: AdminService,
  ) { 
    var ui = this.userInfo;
    if ( localStorage.getItem('id') === null ){ ui.id = -1
    } else { ui.id = Number(localStorage.getItem('id')); }
    
    if ( localStorage.getItem('fullname') === null ){ ui.fullname = ''
    } else { ui.fullname = localStorage.getItem('fullname'); }
    
    if ( localStorage.getItem('username') === null ){ ui.username = ''
    } else { ui.username = localStorage.getItem('username'); }
    
    if ( localStorage.getItem('email') === null ){ ui.email = ''
    } else { ui.email = localStorage.getItem('email'); }
    
    if ( localStorage.getItem('cellphone') === null ){ 
      ui.cnum1 = ''
      ui.cnum2 = ''
      ui.cnum3 = ''
    } else { 
      ui.cnum1 = localStorage.getItem('cellphone').substr(0,3);
      ui.cnum2 = localStorage.getItem('cellphone').substr(3,4);
      ui.cnum3 = localStorage.getItem('cellphone').substr(7,4);
    }
    
    if ( localStorage.getItem('photoUrl') === null ){ ui.photoUrl = ''
    } else { ui.photoUrl = localStorage.getItem('photoUrl'); }
  }

  ngOnInit() {}


  changeCheckbox(e){
    this.userInfo.acceptTerms = e.checked;
  }

  updateUser() {
    // if( this.checkUsernameDup !== this.userInfo.username ){
    //   alert("아이디 중복 확인 해 주세요.");
    // } else if (!this.userInfo.acceptTerms) {
    //   alert("서비스 약관 동의 안했습니다");
    // } else if( this.userInfo.cnum1.length < 3 || this.userInfo.cnum2.length < 4 || this.userInfo.cnum3.length < 3 ){
    //   alert("Cellphone number too short");
    // } else {
    //   this.userService.updateUser(this.userInfo.id, this.userInfo).subscribe(
    //     data => {
    //       // console.log(data);
    //       if(data !== null && data.res_code !== 0 ){
    //         localStorage.setItem('role', '2');
    //         localStorage.setItem('loginStatus', 'true');
    //         localStorage.setItem('username', this.userInfo.username);
    //         localStorage.setItem('cellphone', this.userInfo.cnum1+this.userInfo.cnum2+this.userInfo.cnum3);
    //         this.router.navigate(['kr/home']);
    //       } else {
    //         alert("error saving data");
    //       }
    //     }, error => {
    //       console.log(error);
    //     }
    //   );
    // }
  }
  
  checkUsernameUnique(){
    // this.registerForm.value.username;
    // if( this.userInfo.username.length < 6  ){
    //   alert("아이디가 너무 짧습니다");
    // } else {
    //   this.checkUsernameText = "확인중..."
    //   this.userService.vaildUsername(this.userInfo.username).subscribe(
    //     data => {
    //       if( data.res_code === 1 ) { // UNIQUE
    //         // this.registerForm.get('usernameCheckedUnique').setValue(true);
    //         alert( "사용 가능한 아이디 입니다." );
    //         this.checkUsernameDup = this.userInfo.username
    //       } else if ( data.res_code === 2 ) { // NOT UNIQUE
    //         alert( "이미 사용중인 아이디 입니다. 다른 아이디를 입력해 주세요.")
    //       }
    //     }, error => {
    //       // console.log(error);
    //       // alert("확인중 에러 생겼습니다.")
    //     }, () => {
    //       this.checkUsernameText = "아이디 중복 확인";
    //     }
    //   )
    // }
  }


}
