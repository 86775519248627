import { User, OrgUserStatus } from "src/app/shared";

export class PendingUser extends User {
  selected?: boolean;
  requestDate?: Date;

  constructor(
    userId: number,
    fullname: string,
    username: string,
    email: string,
    cellphone: string,
    orgId?: number,
    pfpLink?: string,
    userStatus?: OrgUserStatus,
    selected?: boolean,
    requestDate?: Date
  ) {
    super(userId, fullname, username, email, cellphone, orgId, pfpLink, userStatus);
    this.selected = selected;
    this.requestDate = requestDate;
  }

  getSelected(): boolean {
    return this.selected;
  }

  getRequestDate(): Date {
    return this.requestDate;
  }

  setSelected(selected: boolean): void {
    this.selected = selected;
  }

  setRequestDate(requestDate: Date): void {
    this.requestDate = requestDate;
  }
}
