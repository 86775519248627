import { User, AdminUserType, OrgUserStatus } from "./User";


export class OrgAdmin extends User {
  admUserType: AdminUserType;

  constructor(
    userId: number,
    fullname: string,
    username: string,
    email: string,
    cellphone: string,
    orgId?: number,
    pfpLink?: string,
    userStatus?: OrgUserStatus,
    admUserType?: AdminUserType
  ) {
    super(userId, fullname, username, email, cellphone, orgId, pfpLink, userStatus);
    this.admUserType = admUserType ?? AdminUserType.SubAdmin;
  }

  public getAdmUserType(): AdminUserType | undefined {
    return this.admUserType;
  }

  public setAdmUserType(admUserType: AdminUserType): void {
    this.admUserType = admUserType;
  }
}
