import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { Organization } from "src/app/shared/models/Organization";
import { ClassCourse, ClassType } from "src/app/shared/models/ClassCourse";
import { ClassInfo, DayOfWeek } from "src/app/shared/models/ClassInfo";
import { ClassSelectionService } from "../../../../shared/services/class-selection-service";
import { FormGroup, Validators, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { DaysOfWeekPickerComponent } from "../../days-of-week-picker/days-of-week-picker.component";
import { CUSTOM_DATE_FORMATS } from "src/app/app.component";
import { CustomDateAdapter } from "src/app/shared/CustomDateAdapter";
import { MAT_DATE_FORMATS, DateAdapter } from "@angular/material/core";
import flatpickr from "flatpickr";
import _ from 'lodash';
import { DatePipe } from "@angular/common";
import { ClassCourseService } from "src/app/shared/services/class-course.service";
import { take } from "rxjs";

export enum EditClassMode {
  EditView,
  CloseClass,
  CancelEdits,
  SaveEdits
}

@Component({
  selector: "app-edit-class",
  templateUrl: "./edit-class.component.html",
  styleUrls: ["./edit-class.component.css",
    "../participant-list/participant-list.component.css",
    "../manage-classes.component.css"],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ]
})
export class EditClassComponent implements OnInit {
  // EditClassMode to render modal corresponding to pressed button
  EditClassMode = EditClassMode;
  editClassMode: EditClassMode = EditClassMode.EditView;
  // currently selected class and its class info
  org: Organization | null = null;
  selectedClass: ClassCourse | null = null;
  selectedClassInfo: ClassInfo | null = null;
  isMyClass: boolean = false;

  isFormInitialized: boolean = false;
  submissionValid: boolean = true;
  // emit toggle events to parent
  @Output() toggleCancelEditsEvent = new EventEmitter<void>();
  @Output() toggleCloseClassViewEvent = new EventEmitter<void>();
  @Output() toggleUpdateClassListEvent = new EventEmitter<void>();

  // ViewChild decorator for ClassType toggle in EditClass view
  private _classTypeToggle!: ElementRef;
  @ViewChild('classTypeToggle', { static: false }) set classTypeToggle(classTypeToggle: ElementRef) {
    this._classTypeToggle = classTypeToggle;
  }

  // ViewChild decorator for DayPicker
  @ViewChild(DaysOfWeekPickerComponent) dayPicker: DaysOfWeekPickerComponent; // used for 운영 요일 selection

  // ViewChild decorators for startTime and endTime Flatpickrs
  @ViewChild('startTimeInput') set startTimeInput(element: ElementRef) {
    if (element) {
      this.initTimePicker('startTime', element);
    }
  }
  @ViewChild('endTimeInput') set endTimeInput(element: ElementRef) {
    if (element) {
      this.initTimePicker('endTime', element);
    }
  }

  // for file upload functionality
  thumbnailFile: File = null;
  fileUploaded: boolean = false;
  uploadedFiles: File[] = [];
  maxFileSize: number = 10 * 1024 * 1024; // 10MB in bytes
  maxFiles: number = 1;

  // form controls
  registerForm: FormGroup;

  selectedClassDays: DayOfWeek[] = [];

  minClassSize: number = 1;
  maxClassSize: number = 1000;
  maxTextFieldLength: number = 150;

  constructor(
    private clsCourseService: ClassCourseService, // classInfo fetch API
    private clsSelectionService: ClassSelectionService, // selectedClass fetch API
    private datePipe: DatePipe,
  ) { }

  // Booleans for toggling modals
  modalStates = {
    [EditClassMode.CloseClass]: { modal: false, step2Modal: false },
    [EditClassMode.CancelEdits]: { modal: false, step2Modal: false },
    [EditClassMode.SaveEdits]: { modal: false, step2Modal: false },

  };

  // Getters and setters to activate different modals based on the clicked button
  get activeModal(): boolean {
    return this.modalStates[this.editClassMode]?.modal || false;
  }

  set activeModal(value: boolean) {
    if (this.modalStates[this.editClassMode]) {
      this.modalStates[this.editClassMode].modal = value;
    }
  }

  get activeStep2Modal(): boolean {
    return this.modalStates[this.editClassMode]?.step2Modal || false;
  }

  set activeStep2Modal(value: boolean) {
    if (this.modalStates[this.editClassMode]) {
      this.modalStates[this.editClassMode].step2Modal = value;
    }
  }

  ngOnInit(): void {
    // subscribe to selectedClass and selectedClassInfo changes
    this.clsSelectionService.org$.subscribe(
      (org) => (this.org = org)
    );

    this.clsSelectionService.selectedClass$.subscribe(
      (cls) => {
        this.selectedClass = cls
      }
    );

    this.clsSelectionService.isMyClass$.subscribe(
      (isMyClass) => (this.isMyClass = isMyClass)
    );

    this.clsCourseService.getClassInfoById(this.selectedClass.getClassId()).subscribe(
      (clsInfo) => {
        this.selectedClassInfo = clsInfo;
        this.initRegisterForm();
        this.isFormInitialized = true;
      }
    );

    if (this.selectedClassInfo) {
      this.initRegisterForm();
    }
  }

  /**
     * Method to set modal icon based on the clicked button.
     */
  getModalIcon(): string {
    if (!this.submissionValid) {
      return '../../../../assets/icons/Icon_64px_col-12.png'
    }

    const iconMapping = {
      [EditClassMode.CloseClass]: '../../../../assets/icons/Icon_64px_col-02.png',
      [EditClassMode.CancelEdits]: '../../../../assets/icons/Icon_64px_col-77.png',
      [EditClassMode.SaveEdits]: '../../../../assets/icons/Icon_64px_col-08.png',
    }

    return iconMapping[this.editClassMode] || '../../../../assets/icons/Icon_64px_col-77.png';
  }

  initRegisterForm(): void {
    this.minClassSize = Math.max(this.minClassSize, this.selectedClass.getParticipantList().length);
    const cls = this.selectedClass;
    const clsInfo = this.selectedClassInfo;

    this.registerForm = new FormGroup({
      className: new FormControl(cls.getClassName(), [Validators.required, Validators.maxLength(20)]),
      capacity: new FormControl(cls.getCapacity(), [Validators.required, Validators.min(this.minClassSize), Validators.max(this.maxClassSize)]),
      classType: new FormControl(cls.getClassType(), [Validators.required, this.enumValidator(ClassType)]),
      startDate: new FormControl(cls.getStartDate(), [Validators.required, this.dateValidator()]),
      endDate: new FormControl(cls.getEndDate(), [Validators.required, this.dateValidator()]),
      thumbnail: new FormControl(cls.getThumbnail(), [Validators.required, this.imageFileNameValidator()]),
      subInstructorName: new FormControl(clsInfo.getSubInstructorName(), [Validators.required, Validators.minLength(1), Validators.maxLength(20)]),
      classIntro: new FormControl(cls.getClassIntro(), [Validators.required, Validators.maxLength(100)]),
      classGoal: new FormControl(clsInfo.getClassGoal(), [Validators.required, Validators.maxLength(this.maxTextFieldLength)]),
      classTarget: new FormControl(clsInfo.getClassTarget(), [Validators.required, Validators.maxLength(this.maxTextFieldLength)]),
      classLocation: new FormControl(clsInfo.getClassLocation(), [Validators.required, Validators.maxLength(this.maxTextFieldLength)]),
      sessionsPerWeek: new FormControl({ value: '', disabled: true }),
      sessionDuration: new FormControl({ value: '', disabled: true }),
      totalWeeks: new FormControl({ value: '', disabled: true }),
      totalSessions: new FormControl({ value: '', disabled: true }),
      approxCostPerSession: new FormControl(clsInfo.getApproxCostPerSession(), [Validators.required, Validators.min(0), Validators.max(1000000)]),
      classDays: new FormControl(clsInfo.getClassDays(), [this.enumValidator(DayOfWeek)]),
      startTime: new FormControl(clsInfo.getClassTimes().start, [Validators.required, this.timeValidator()]),
      endTime: new FormControl(clsInfo.getClassTimes().end, [Validators.required, this.timeValidator()]),
      requiredMaterials: new FormControl(clsInfo.getRequiredMaterials(), [Validators.required, Validators.maxLength(this.maxTextFieldLength)]),
      classContact: new FormControl(clsInfo.getClassContact(), [Validators.required, Validators.maxLength(this.maxTextFieldLength)])
    }, {
      validators: [this.startBeforeEndDateValidator(), this.startBeforeEndTimeValidator()]
    });

    if (!this.isMyClass || !this.selectedClass.isOpen()) {
      this.registerForm.disable();
    } else {
      this.registerForm.enable();
    }

    // Subscribe to changes on startDate and endDate to trigger revalidation
    this.registerForm.get('startDate')?.valueChanges.subscribe(() => {
      this.registerForm.updateValueAndValidity();
    });

    this.registerForm.get('endDate')?.valueChanges.subscribe(() => {
      this.registerForm.updateValueAndValidity();
    });

    this.setClassTypeToggle(); // initialize / reset ClassType toggle while in EditClass view

    this.setupValueChangeHandlers();

    // initializes sessions-related values
    this.updateClassSessionData();
  }

  /**
   * Method to initialize Flatpickr for entering the startTime of a class.
   * @param element: ElementRef to Flatpickr for startTime
   */
  initTimePicker(controlName: string, element: ElementRef) {
    flatpickr(element.nativeElement, {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      time_24hr: true,
      minuteIncrement: 5,
      onChange: (selectedDates) => {
        const date = selectedDates[0];
        if (date) {
          const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
          this.registerForm.get(controlName)?.setValue(formattedTime);
          this.updateSessionDuration();
        }
      }
    });
  }
  /**
   * Method to initialize or update class session-related data in registerForm
   */
  updateClassSessionData(): void {
    this.updateSessionDuration();
    this.updateSessionsPerWeek();
    this.updateTotalSessions();
  }

  setupValueChangeHandlers(): void {
    // handle time picker changes
    this.registerForm.get('startTime').valueChanges.subscribe(() => {
      this.updateSessionDuration();
    });
    this.registerForm.get('endTime')?.valueChanges.subscribe(() => {
      this.updateSessionDuration();
    });

    // handle day picker changes
    this.registerForm.get('classDays')?.valueChanges.subscribe(() => {
      this.updateSessionsPerWeek();
      this.updateTotalSessions();
    });

    // handle startDate and endDate changes
    this.registerForm.get('startDate')?.valueChanges.subscribe(() => {
      this.updateTotalSessions();
    });
    this.registerForm.get('endDate').valueChanges.subscribe(() => {
      this.updateTotalSessions();
    })
  }

  setClassTypeToggle(): void {
    if (!this._classTypeToggle) {
      return; // in case _classTypeToggle is null and not initialized yet
    }

    if (this.selectedClass.getClassType() === ClassType.Public) {
      this._classTypeToggle.nativeElement.checked = true;
    } else {
      this._classTypeToggle.nativeElement.checked = false;
    }
  }

  toggleClassType(): void {
    if (!(this.selectedClass.isOpen() && this.isMyClass)) {
      return;
    }

    if (this._classTypeToggle.nativeElement.checked) {
      this.registerForm.get('classType')?.setValue(ClassType.Public);
    } else {
      this.registerForm.get('classType')?.setValue(ClassType.Private);
    }

    if (this.registerForm.get('classType').pristine) {
      this.registerForm.get('classType').markAsDirty();
    }
  }

  mapClassType(classType: ClassType): string {
    switch (classType) {
      case ClassType.Private:
        return '비공개';
      case ClassType.Public:
        return '공개';
      default:
        return '';
    }
  }

  async closeClass(): Promise<void> {
    this.selectedClass.setOpen(false);
    this.selectedClass.setEndDate(new Date()); // set class to end NOW

    try {
      await this.clsCourseService.setClassCourse(this.selectedClass);
      this.clsSelectionService.triggerBuildClass();
      this.submissionValid = true;
    } catch (error) {
      console.error('Error closing class:', error);
      this.submissionValid = false;
    }

    this.scrollTop(39);
  }

  cancelClassEdits() {
    if (this.editClassMode === EditClassMode.CancelEdits) {
      this.onToggleCancelEdits();
      this.registerForm = null;
    } else {
      this.initRegisterForm();
      this.scrollTop(1000);
    }
    this.closeModal();
  }

  async onSubmit(): Promise<boolean> {
    if (this.registerForm.pristine) {
      this.submissionValid = false;
      return false;
    }

    this.registerForm.markAllAsTouched();

    if (this.registerForm.invalid) {
      console.log('Form is invalid. Please fix the errors and try again');
      this.submissionValid = false;
      return false;
    }

    // Convert startDate to Date object and set hours
    const startDateValue = this.registerForm.get('startDate')?.value;
    const startDate = new Date(startDateValue);
    startDate.setHours(0, 0, 0, 0);
    this.registerForm.get('startDate')?.setValue(startDate.toISOString());

    // Convert endDate to Date object and set hours
    const endDateValue = this.registerForm.get('endDate')?.value;
    const endDate = new Date(endDateValue);
    endDate.setHours(23, 59, 59, 999);
    this.registerForm.get('endDate')?.setValue(endDate.toISOString());

    if (this.selectedClass && this.selectedClassInfo) {
      // Update selectedClass fields
      this.selectedClass.setClassName(this.registerForm.get('className')?.value);
      this.selectedClass.setCapacity(this.registerForm.get('capacity')?.value);
      this.selectedClass.setClassType(this.registerForm.get('classType')?.value);
      this.selectedClass.setStartDate(this.registerForm.get('startDate')?.value);
      this.selectedClass.setEndDate(this.registerForm.get('endDate')?.value);
      this.selectedClass.setClassIntro(this.registerForm.get('classIntro')?.value);
      this.selectedClass.setThumbnail(this.registerForm.get('thumbnail')?.value);

      // Update selectedClassInfo fields
      this.selectedClassInfo.setSubInstructorName(this.registerForm.get('subInstructorName')?.value);
      this.selectedClassInfo.setClassGoal(this.registerForm.get('classGoal')?.value);
      this.selectedClassInfo.setClassTarget(this.registerForm.get('classTarget')?.value);
      this.selectedClassInfo.setClassLocation(this.registerForm.get('classLocation')?.value);
      this.selectedClassInfo.setSessionsPerWeek(this.registerForm.get('sessionsPerWeek')?.value);
      this.selectedClassInfo.setSessionDuration(this.registerForm.get('sessionDuration')?.value);
      this.selectedClassInfo.setApproxCostPerSession(this.registerForm.get('approxCostPerSession')?.value);
      this.selectedClassInfo.setClassDays(this.registerForm.get('classDays')?.value);
      this.selectedClassInfo.setClassTimes({
        start: this.registerForm.get('startTime')?.value,
        end: this.registerForm.get('endTime')?.value,
      });
      this.selectedClassInfo.setRequiredMaterials(this.registerForm.get('requiredMaterials')?.value);
      this.selectedClassInfo.setClassContact(this.registerForm.get('classContact')?.value);

      try {
        // Submit updated data to the service
        await this.clsCourseService.setClassCourse(this.selectedClass, this.thumbnailFile);
        await this.clsCourseService.setClassInfo(this.selectedClass.getClassId(), this.selectedClassInfo);
        console.log('Form submitted and updated class data successfully.');
        this.registerForm.markAsPristine();
        this.submissionValid = true;
        return true;
      } catch (error) {
        console.error('Error submitting form:', error);
        this.submissionValid = false;
        return false;
      }
    } else {
      console.log('No class selected to update.');
      this.submissionValid = false;
      return false;
    }
  }

  /**
   * Method to map the string representation of class type back to the enum value.
   */
  mapClassTypeBack(classType: string): ClassType {
    return classType === '공개' ? ClassType.Public : ClassType.Private;
  }

  get rf() { return this.registerForm ? this.registerForm.controls : null };

  /**
   * Method to format a date in 'YYYY.MM.DD' string with local midnight time.
   * @param date Date object
   */
  dateToString(date: Date): string {
    if (!date) {
      return '--';
    }

    return this.datePipe.transform(date, 'yyyy.MM.dd') || '';
  }

  /**
   * Custom method to handle date change from DatePickers
   * @param controlName 
   * @param value 
   */
  onDateChange(controlName: string, value: Date): void {
    if (value instanceof Date && !isNaN(value.getTime())) {
      if (controlName === 'startDate') {
        this.registerForm.get('startDate')?.setValue(value);
        //console.log(this.registerForm.get('startDate')?.value.toISOString());
      } else if (controlName === 'endDate') {
        this.registerForm.get('endDate')?.setValue(value);
        //console.log(this.registerForm.get('endDate')?.value.toISOString());
      }

      this.updateClassSessionData();
    }
  }

  /**
   * Custom method to handle start time change from Flatpickr
   * @param value time string in 'HH:MM' format
   */
  onStartTimeChange(value: string): void {
    if (value) {
      this.registerForm.get('startTime')?.setValue(value);
      this.updateSessionDuration();
    }
  }

  /**
   * Custom method to handle end time change from Flatpickr
   * @param value time string in 'HH:MM' format
   */
  onEndTimeChange(value: string): void {
    if (value) {
      this.registerForm.get('endTime')?.setValue(value);
      this.updateSessionDuration();
    }
  }

  /**
   * Method to update the selected days of the week from DaysOfWeekPickerComponent
   * @param value selected days of the week
   */
  onClassDaysChange(value: DayOfWeek[]): void {
    this.selectedClassDays = value;

    this.updateClassSessionData();
  }

  /**
   * Custom validator for date format.
   * @returns ValidatorFn to check for valid date formats
   */
  dateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      // check if value is a valid Date object
      if (!value) {
        return { invalidDate: true };
      }

      // check if date is valid
      /**
       * if (value && isNaN(value.getTime())) {
        console.log('isNaN(value.getTime()');
        return { invalidDate: true };
      }
       */

      return null; // valid date
    };
  }

  /**
   * Custom validator to match valid image file names
   * @returns ValidatorFn to check for valid file names and extensions
   */
  imageFileNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null; // let Validators.required handle empty value
      }

      // regex to match valid image file names (e.g., 'filename.jpg', 'image_01.png')
      const imagePattern = /\.(jpg|jpeg|png)$/;
      // return imagePattern.test(control.value) ? null : { invalidImageName: true };
      return null;
    }
  }

  /**
 * Custom validator to check if start date is before end date
 * @returns ValidatorFn to check if endDate comes after startDate
 */
  startBeforeEndDateValidator(): ValidatorFn {
    return (group: AbstractControl): { [key: string]: any } | null => {
      const startDate = group.get('startDate')?.value;
      const endDate = group.get('endDate')?.value;

      if (startDate instanceof Date && endDate instanceof Date) {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        if (startDate > endDate) {
          return { startDateAfterEndDate: true };
        }
      }
      return null;
    };
  }

  /** 
   * Custom validator to check if start time is before end time
   * @returns ValidatorFn to check if endTime comes after startTime
   */
  startBeforeEndTimeValidator(): ValidatorFn {
    return (group: AbstractControl): { [key: string]: any } | null => {
      const startTime = group.get('startTime')?.value;
      const endTime = group.get('endTime')?.value;

      if (startTime && endTime) {
        const [startHr, startMin] = startTime.split(":").map(val => parseInt(val, 10));
        const [endHr, endMin] = endTime.split(":").map(val => parseInt(val, 10));

        const startTotalMinutes = startHr * 60 + startMin;
        const endTotalMinutes = endHr * 60 + endMin;

        if (startTotalMinutes >= endTotalMinutes) {
          return { startTimeAfterEndTime: true };
        }
      }
      return null;
    };
  }

  /**
   * Custom validator to check for enum values
   * @param enumType any enum value to be checked
   * @returns ValidatorFn to check for valid enum values
   */
  enumValidator(enumType: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null; // let Validators.required handle empty value
      }

      // get numeric values of the enum
      const enumValues = Object.keys(enumType)
        .filter(key => !isNaN(Number(key)))
        .map(key => Number(key));

      // if control value is an array, check if all elements are in the enum
      if (Array.isArray(control.value)) {
        const isValidArray = control.value.every(value => enumValues.includes(value));
        return isValidArray ? null : { invalidEnumValue: true };
      }

      // check single value
      return enumValues.includes(control.value) ? null : { invalidEnumValue: true };
    };
  }

  /**
   * Custom validator to match valid time strings (e.g., '00:00', '12:34')
   * @returns ValidatorFn to check for valid time strings
   */
  timeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null; // let Validators.required handle empty value
      }

      // regex to match valid time strings in 'HH-MM' format
      const timePattern = /^([0-1]\d|2[0-3]):[0-5]\d$/;
      return timePattern.test(control.value) ? null : { invalidTime: true };
    };
  }

  /**
   * Method to update session duration based on startTime and endTime values.
   */
  updateSessionDuration(): void {
    const startTime = this.registerForm.get('startTime').value;
    const endTime = this.registerForm.get('endTime').value;

    if (startTime && endTime) {
      const [startHour, startMinute] = startTime.split(":").map(Number);
      const [endHour, endMinute] = endTime.split(":").map(Number);

      const startDate = new Date(0, 0, 0, startHour, startMinute);
      const endDate = new Date(0, 0, 0, endHour, endMinute);

      const duration = (endDate.getTime() - startDate.getTime()) / (1000 * 60); // duration in minutes
      if (duration > 0) {
        this.registerForm.get('sessionDuration').setValue(duration);
      } else {
        this.registerForm.get('sessionDuration').setValue(''); // invalid duration
      }
    } else {
      this.registerForm.get('sessionDuration').setValue('');
    }
  }

  /**
   * Method to update sessions per week based on selected days.
   */
  updateSessionsPerWeek(): void {
    let selectedDays: DayOfWeek[] = this.registerForm.get('classDays')?.value || [];
    if (selectedDays.length === 0) {
      selectedDays = [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday, DayOfWeek.Saturday, DayOfWeek.Sunday];
    }
    this.registerForm.get('sessionsPerWeek')?.setValue(selectedDays.length);
  }

  /**
   * Method to calculate total number of sessions based on startDate, endDate, and classDays.
   */
  updateTotalSessions(): void {
    const startDateStr = this.registerForm.get('startDate')?.value;
    const endDateStr = this.registerForm.get('endDate')?.value;
    let selectedDays: DayOfWeek[] = this.registerForm.get('classDays')?.value || [];

    if (selectedDays.length === 0) {
      selectedDays = [
        DayOfWeek.Sunday,
        DayOfWeek.Monday,
        DayOfWeek.Tuesday,
        DayOfWeek.Wednesday,
        DayOfWeek.Thursday,
        DayOfWeek.Friday,
        DayOfWeek.Saturday
      ];
    }

    if (startDateStr && endDateStr) {
      const startDate = new Date(startDateStr);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(endDateStr);
      endDate.setHours(23, 59, 59, 59);

      if (startDate <= endDate) {
        let totalSessions = 0;
        let currentDate = new Date(startDate);

        // count each day between startDate and endDate
        while (currentDate <= endDate) {
          const dayOfWeek = currentDate.getDay();

          // check if current day is one of the selected class days
          if (selectedDays.includes(dayOfWeek)) {
            totalSessions++;
          }

          // move to next day
          currentDate.setDate(currentDate.getDate() + 1);
        }

        // calculate total number of weeks
        const totalWeeks = Math.ceil((endDate.getTime() - startDate.getTime() + 1) / (7 * 24 * 60 * 60 * 1000));

        this.registerForm.get('totalWeeks')?.setValue(totalWeeks);
        this.registerForm.get('totalSessions')?.setValue(totalSessions);
      } else {
        this.registerForm.get('totalWeeks')?.setValue('');
        this.registerForm.get('totalSessions')?.setValue('');
      }
    } else {
      this.registerForm.get('totalWeeks')?.setValue('');
      this.registerForm.get('totalSessions')?.setValue('');
    }
  }

  /**
   * Method to upload a new class thumbnail image, in .jpg/.jpeg or .png format. 
   */
  uploadThumbnail(): void {
    const target = event.target as HTMLInputElement;
    const file = target.files ? target.files[0] : null;
    const validTypes = ['image/jpeg', 'image/png'];

    if (file) {
      // create temporary file path to preview uploaded image
      const filePath = URL.createObjectURL(file);
      this.rf['thumbnail'].setValue(filePath);

      // check file size
      if (file.size > this.maxFileSize) {
        alert(`${file.name} 파일의 크기가 ${this.maxFileSize / (1024 * 1024)}MB를 초과합니다.`);
        return;
      }

      if (!validTypes.includes(file.type)) {
        alert(`${file.name}은 지원되지 않는 파일 형식입니다.`);
        this.rf['thumbnail'].setValue(this.selectedClass.getThumbnail());
        return;
      }

      // Store the actual file object separately for use during form submission
      this.thumbnailFile = file;

      this.registerForm.get('thumbnail').markAsDirty();
    } else {
      console.error('no file selected.');
    }
  }

  /**
   * Method to emit toggleCancelEdits event to parent.
   */
  onToggleCancelEdits(): void {
    this.closeModal();
    this.submissionValid = false;
    this.toggleCancelEditsEvent.emit();
    this.editClassMode = EditClassMode.EditView;
  }

  onToggleCloseClassView(): void {
    this.toggleCancelEditsEvent.emit();
    this.toggleUpdateClassListEvent.emit();
    this.toggleCloseClassViewEvent.emit();
  }

  /**
     * Method to change edit class mode according to selected modal
     * @param modal string value entered in openModal
     */
  changeClassManageMode(modal: 'closeClass' | 'cancelEdits' | 'saveEdits'): void {
    switch (modal) {
      case 'closeClass':
        this.editClassMode = EditClassMode.CloseClass;
        break;
      case 'cancelEdits':
        this.editClassMode = EditClassMode.CancelEdits;
        break;
      case 'saveEdits':
        this.editClassMode = EditClassMode.SaveEdits;
        break;
      default:
        this.editClassMode = EditClassMode.EditView;
        break;
    }
  }

  logInvalidControls() {
    const invalidControls = [];

    Object.keys(this.registerForm.controls).forEach(controlName => {
      const control = this.registerForm.get(controlName);
      if (control && control.invalid) {
        console.log(control.value);
        invalidControls.push({
          controlName,
          errors: control.errors
        });
      }
    });

    console.log("Invalid controls:", invalidControls);
  }

  /** Method to open class close / cancel edits / save edits modal.  */
  openModal(modal: 'closeClass' | 'cancelEdits' | 'saveEdits'): void {
    if (modal === 'saveEdits') {
      if (this.registerForm.invalid || this.registerForm.errors) {
        // this.logInvalidControls();
        return;
      }
    }

    this.changeClassManageMode(modal);
    this.activeModal = true;
  }

  async gotoStep2(): Promise<void> {
    if (this.editClassMode === EditClassMode.CancelEdits || this.registerForm.invalid || this.registerForm.errors) {
      return;
    }

    if (this.activeModal) {
      this.activeModal = false;
    }

    if (this.editClassMode === EditClassMode.CloseClass) {
      this.closeClass();
    } else if (this.editClassMode === EditClassMode.SaveEdits) {
      await this.onSubmit();
    }

    this.activeStep2Modal = true;
  }

  /** Method to close currently opened modal upon completion of desired action. */
  closeModal(): void {
    if (this.activeModal) {
      this.activeModal = false;
    }

    if (this.activeStep2Modal) {
      this.activeStep2Modal = false;

      if (this.editClassMode === EditClassMode.CloseClass) {
        this.onToggleCancelEdits();
        this.onToggleCloseClassView();
      }

      if (this.editClassMode === EditClassMode.SaveEdits) {
        this.scrollTop(1000);
      } else if (this.editClassMode === EditClassMode.CancelEdits) {
        this.scrollTop(635);
      } else if (this.editClassMode === EditClassMode.EditView) {
        this.scrollTop(39);
      }
    }

    this.editClassMode = EditClassMode.EditView;
  }

  getThumbnail(): string {
    if (!this.registerForm) {
      return '';
    }

    return this.registerForm.get('thumbnail').value;
  }

  /**
   * Method to scroll to the top with an offset (in pixels)
   * @param offset top offset in pixels
   */
  scrollTop(offset: number): void {
    window.scrollTo({ top: offset, behavior: 'smooth' }); // scroll to top smoothly
  }
}
