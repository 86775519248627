import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-membership-terms',
  templateUrl: './membership-terms.component.html',
  styleUrls: ['./membership-terms.component.css']
})
export class MembershipTermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
