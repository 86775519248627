import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { DatePipe } from "@angular/common";
import { MatPaginator } from "@angular/material/paginator";
import { Organization } from "src/app/shared/models/Organization";
import { OrganizationService } from "src/app/shared/services/organization.service";
import { RegularUserService } from "src/app/shared/services/regular-user.service";
import { AdminService } from "src/app/shared/services/admin.service";
import { AdminHeaderService } from "src/app/shared/services/admin-header.service";
import { ClassCourseService } from "src/app/shared/services/class-course.service";
import { ClassCourse, ClassType } from "src/app/shared/models/ClassCourse";
import { ClassInfo } from "src/app/shared/models/ClassInfo";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { ClassSelectionService } from "../../../shared/services/class-selection-service";
import { lastValueFrom } from "rxjs";

export enum ClassManageMode {
  ViewAll,
  AddUsers,
  RemoveUsers,
  AddMissions,
  EditMissions,
  ViewTracking,
  ViewMissions,
  EditClass,
  CloseClass
}

@Component({
  selector: "app-manage-classes",
  templateUrl: "./manage-classes.component.html",
  styleUrls: ["./manage-classes.component.css"]
})
export class ManageClassesComponent implements OnInit {
  ClassManageMode = ClassManageMode;

  //////
  /* Class Thumbnails */
  // ViewChild decorator for class thumbnail paginator (페이지네이션)
  @ViewChild(MatPaginator) thumbPaginator!: MatPaginator;

  // ViewChild decorator for thumbnail search bar (클래스 검색창)
  private thumbSearchEl: ElementRef;
  @ViewChild('thumbSearchInput', { static: false }) set thumbSearch(thumbSearch: ElementRef) {
    this.thumbSearchEl = thumbSearch;
  }

  // ViewChild decorator for thumbnail search reset button (클래스 검색 초기화 버튼)
  private thumbResetEl: ElementRef;
  @ViewChild('thumbResetBtn', { static: false }) set thumbReset(thumbReset: ElementRef) {
    this.thumbResetEl = thumbReset;
  }

  constructor(
    private orgService: OrganizationService, // school (organization) data fetch API
    private regUserService: RegularUserService, // 일반 사용자 data fetch API
    private admUserService: AdminService, // 관리자 data fetch API
    private admHeaderService: AdminHeaderService, // 상단 헤더 문구 설정 API
    private clService: ClassCourseService, // class data fetch API
    private datePipe: DatePipe, // used for date formatting
    private cdRef: ChangeDetectorRef, // used for ClassTypeToggle in EditClass
    private clsSelectionService: ClassSelectionService, // used to delegate selectedClass and selectedClassInfo to children
  ) { }

  // Class view
  classViewOpen: boolean = false;
  classCategoryList: string[] = ['전체', 'My 클래스', '마감 클래스']
  currentCategory: string = '전체';

  // trigger fetch for ClassProgressComponent
  triggerFetch: boolean = false;

  // ViewChild decorator for class thumbnails
  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;

  @Output() toggleClassViewEvent = new EventEmitter<void>();

  // Organization of currently signed in Admin
  org: Organization;

  // Arrays to hold class list fetched from RegularUserService
  totalClassList: ClassCourse[] = [];
  // Map to hold corresponding ClassInfo object for each ClassCourse
  myClassList: ClassCourse[] = [];
  closedClassList: ClassCourse[] = [];

  currentClassList: ClassCourse[] = [];
  filteredClassList: ClassCourse[] = [];
  paginatedClassList: ClassCourse[] = [];

  // currently selected class and its class info
  selectedClass: ClassCourse = null;
  selectedClassInfo: ClassInfo = null;
  isMyClass: boolean = false;

  thumbPage: number = 1; // current thumbnail list page
  thumbPageSize: number = 12; // number of thumbnails per page
  thumbPageSizeOptions: number[] = [4, 12]; // options for thumbnail page size

  // class management modes 
  classManageMode: ClassManageMode = ClassManageMode.ViewAll;

  page: number = 1; // current page
  pageSize: number = 5; // number of items per page
  pageSizeOptions: number[] = [5, 10, 20, 30, 50]; // options for page size dropdown

  totalClassLoadingError: boolean = false;
  totalClassLoading: boolean = true;

  myClassLoadingError: boolean = false;
  myClassLoading: boolean = true;

  closedClassLoadingError: boolean = false;
  closedClassLoading: boolean = true;

  

  async ngOnInit(): Promise<void> {
    this.fetchOrg();
    this.buildClassList();

    await this.clsSelectionService.buildClassListTrigger$.subscribe(
      () => {
        this.buildClassList();
        this.currentClassList = this.totalClassList;
        this.cdRef.detectChanges();
      }
    );

    this.currentClassList = this.totalClassList;

    // for aesthetics
    this.admHeaderService.setHeaderText(`현재 <span style="color: #fec40d;">${this.getTotalClassCount()}</span>개 클래스 운영 중입니다.`);
    this.scrollTop(39);
  }

  /**
   * Method to fetch the organization data of the currently signed in Admin user.
   */
  fetchOrg(): void {
    this.org = this.orgService.getOrganizationForAdminUser();
    this.clsSelectionService.setOrg(this.org);
  }

  async buildClassList(): Promise<void> {
    try {
      this.totalClassLoading = true;
      this.totalClassList = await lastValueFrom(this.clService.getClassCoursesForOrg());
      this.sortClassListByMostRecent(this.totalClassList);
      this.setCategory();
      this.filteredClassList = [...this.currentClassList];
      this.updatePaginatedThumbList();
      this.totalClassLoading = false;
      this.totalClassLoadingError = false;
    } catch (error) {
      console.log('error building total class list:', error);
      this.totalClassLoading = false;
      this.totalClassLoadingError = true;
    }

    try {
      this.myClassLoading = true;
      this.myClassList = await lastValueFrom(this.clService.getClassCoursesForAdmin());
      this.sortClassListByMostRecent(this.myClassList);
      this.myClassLoading = false;
      this.myClassLoadingError = false;
    } catch (error) {
      console.log('error building my class list:', error);
      this.myClassLoading = false;
      this.myClassLoadingError = true;
    }

    try {
      this.closedClassLoading = true;
      this.closedClassList = await lastValueFrom(this.clService.getClosedClassesForOrg());
      this.sortClassListByMostRecent(this.closedClassList);
      this.closedClassLoading = false;
      this.closedClassLoadingError = false;
    } catch (error) {
      console.log('error building closed class list:', error);
      this.closedClassLoading = false;
      this.closedClassLoadingError = true;
    }
  }

  get currentErrorMessage(): string | null {
    if (this.currentCategory === '전체' && this.totalClassLoadingError && !this.totalClassLoading) {
      return '클래스 로딩 중 오류가 발생했습니다.';
    } else if (this.currentCategory === 'My 클래스' && this.myClassLoadingError && !this.myClassLoading) {
      return '클래스 로딩 중 오류가 발생했습니다.';
    } else if (this.currentCategory === '마감 클래스' && this.closedClassLoadingError && !this.closedClassLoading) {
      return '클래스 로딩 중 오류가 발생했습니다.';
    }
    return null; // No error
  }  

  get currentEmptyMessage(): string | null {
    if (this.currentCategory === '전체' && this.totalClassList.length === 0 && !this.totalClassLoading && !this.totalClassLoadingError) {
      return '등록된 클래스가 없습니다.';
    } else if (this.currentCategory === 'My 클래스' && this.myClassList.length === 0 && !this.myClassLoading && !this.myClassLoadingError) {
      return '관리중인 클래스가 없습니다.';
    } else if (this.currentCategory === '마감 클래스' && this.closedClassList.length === 0 && !this.closedClassLoading && !this.closedClassLoadingError) {
      return '마감된 클래스가 없습니다.';
    }
    return null; // No empty state
  }  

  /** Functions to handle thumbnail list pagination */
  updatePaginatedThumbList(): void {
    if (this.paginatedClassList.length === 0) {
      this.resetThumbSearchInput;
    }

    const startIndex = (this.thumbPage - 1) * this.thumbPageSize;
    const endIndex = startIndex + this.thumbPageSize;

    this.sortThumbListByMostRecent();
    this.paginatedClassList = this.filteredClassList.slice(startIndex, endIndex);
  }

  onThumbPageChange(newPage: number) {
    this.thumbPage = newPage;
    this.updatePaginatedThumbList();
    this.scrollTop(157);
  }

  /**
   * Returns the number of open classes for the logged in organization.
   * @returns number of open classes
   */
  getTotalClassCount(): number {
    return this.totalClassList.length;
  }

  /**
   * Returns the number of open classes for the logged in organization.
   * @returns number of open classes
   */
  getActiveClassCount(): number {
    return this.totalClassList.filter((cl) => cl.isOpen()).length;
  }

  /**
   * Returns the number of classes managed by the current user.
   * @returns number of classes managed by current user
   */
  getMyClassCount(): number {
    return this.myClassList.length;
  }

  /**
   * Returns the number of closed classes for the logged in organization.
   * @returns number of closed classes
   */
  getClosedClassCount(): number {
    return this.closedClassList.length;
  }

  /**
   * Method to sort thumbnail list to show classes by start date, from most to least recent.
   */
  sortThumbListByMostRecent(): void {
    this.filteredClassList.sort((a, b) => {
      // check if either class is closed; send closed classes to the back
      if (!a.isOpen() && b.isOpen()) return 1;
      if (a.isOpen() && !b.isOpen()) return -1;

      let dateA = new Date(a.getStartDate()).getTime();
      let dateB = new Date(b.getStartDate()).getTime();

      return dateB - dateA;
    });
  }

  sortClassListByMostRecent(clsList: ClassCourse[]): void {
    clsList.sort((a, b) => {
      // check if either class is closed; send closed classes to the back
      if (!a.isOpen() && b.isOpen()) return 1;
      if (a.isOpen() && !b.isOpen()) return -1;

      let dateA = new Date(a.getStartDate()).getTime();
      let dateB = new Date(b.getStartDate()).getTime();

      return dateB - dateA;
    });
  }

  /* class list search functions */
  thumbSearchPerformed: boolean = false;
  thumbSearchResultsNotFound: boolean = false;

  /**
   * Method to search class thumbnail list by name or contents.
   * @param query input string
   */
  searchClassList(query: string) {
    if (this.thumbSearchEl.nativeElement.value === '') {
      this.resetClassList();
      return;
    }

    const lowerQuery = query.toLowerCase();

    this.filteredClassList = this.currentClassList.filter((cl) => {
      return (
        cl.getClassName().toLowerCase().includes(lowerQuery)
      );
    });

    this.sortThumbListByMostRecent();

    if (this.filteredClassList.length === 0) {
      this.thumbSearchResultsNotFound = true;
      this.thumbSearchPerformed = true;
      return;
    }

    this.thumbSearchPerformed = true;
    this.thumbSearchResultsNotFound = false;

    this.thumbPage = 1; // reset to first page
    this.updatePaginatedThumbList();
  }

  /**
   * Method to render / hide the 'X' reset button if input field is non-empty / empty.
   */
  onThumbInputChange(): void {
    this.thumbResetEl.nativeElement.style.visibility = this.thumbSearchEl.nativeElement.value.length > 0 ? 'visible' : 'hidden';
  }

  /**
   * Method to change the view if another class category is selected.
   * @param event MatTabChangeEvent
   */
  changeSelectedCategory(event: MatTabChangeEvent): void {
    this.currentCategory = this.classCategoryList[event.index];
    this.closeClassView();
    this.scrollTop(39);
    this.resetThumbSearchInput();
    this.setCategory();
    this.updatePaginatedThumbList();
    this.cdRef.detectChanges();
  }

  setCategory(): void {
    switch (this.currentCategory) {
      case '전체':
        this.admHeaderService.setHeaderText(`총 <span style="color: #fec40d;">${this.getActiveClassCount()}</span>개 클래스 운영 중입니다.`);
        this.currentClassList = this.totalClassList;
        break;
      case 'My 클래스':
        this.admHeaderService.setHeaderText(`현재 <span style="color: #fec40d;">${this.getMyClassCount()}</span>개 클래스 관리 중입니다.`);
        this.currentClassList = this.myClassList;
        break;
      case '마감 클래스':
        this.admHeaderService.setHeaderText(`마감된 클래스가 <span style="color: #fec40d;">${this.getClosedClassCount()}</span>개 있습니다.`);
        this.currentClassList = this.closedClassList;
        break;
      default:
        this.admHeaderService.setHeaderText(`총 <span style="color: #fec40d;">${this.getActiveClassCount()}</span>개 클래스 운영 중입니다.`);
        this.currentClassList = this.totalClassList;
        break;
    }

    this.filteredClassList = [...this.currentClassList];
  }

  private originalThumbPage: number = 1; // store original page for restoring on close

  /**
   * Method to toggle class view.
   * @param index class at thumbnail list index to be opened/closed
   */
  toggleClassView(index: number): void {
    // calculate index in full paginatedClassList based on current page and page size
    const globalIndex = (this.thumbPage - 1) * this.thumbPageSize + index;

    if (this.classManageMode === ClassManageMode.EditClass) {
      this.classManageMode = ClassManageMode.ViewAll;
    }

    if (this.selectedClass !== this.paginatedClassList[index] || !this.classViewOpen) {
      this.openClassView(globalIndex);
    } else {
      this.closeClassView();
      this.isMyClass = false;
      this.setCategory();
    }
  }

  /**
   * Method to select a class from the thumbnails list and open the class view.
   * @param globalIndex class at thumbnail list index to be opened
   */
  openClassView(globalIndex: number): void {
    // save original thumbPage before entering reduced view
    this.originalThumbPage = this.thumbPage;

    // calculate startIndex based on the selected thumbnail index
    this.selectedClass = this.paginatedClassList[globalIndex % this.thumbPageSize];
    // if selected class's instructor ID matches that of currently logged in user, set isMyClass to true;
    // this flag is used to conditionally render edit buttons for class
    this.isMyClass = this.selectedClass.getMainInstructorId() === this.admUserService.getCurrentAdminSession().getUserId() ? true : false;
    // retrieve class info after class is selected
    this.clService.getClassInfoById(this.selectedClass.getClassId()).subscribe(
      (clsInfo) => (this.selectedClassInfo = clsInfo)
    );

    // update thumbPage to align with the reduced view with a size of 4
    this.thumbPage = Math.floor(globalIndex / 4) + 1;
    this.thumbPageSize = 4;

    // calculate new startIndex and endIndex for reduced paginated list
    const startIndex = (this.thumbPage - 1) * this.thumbPageSize;
    const endIndex = startIndex + this.thumbPageSize;

    // update paginatedClassList with the sliced view containing 4 items, including the selected item
    this.paginatedClassList = this.filteredClassList.slice(startIndex, endIndex);

    this.classViewOpen = true;

    // delegate selectedClass and selectedClassInfo to child components
    this.clsSelectionService.setSelectedClass(this.selectedClass);
    this.clsSelectionService.setIsMyClass(this.isMyClass);
    this.toggleClassViewEvent.emit();

    // signal ClassProgressComponent to refresh its state
    this.triggerFetch = true;

    // reset trigger after a short delay to allow retriggering if needed
    setTimeout(() => {
      this.triggerFetch = false;
    }, 0);

    this.scrollTop(660);
  }

  /**
   * Method to close the class view.
   */
  closeClassView(): void {
    this.selectedClass = null;
    this.selectedClassInfo = null;
    this.classViewOpen = false;

    // restore original thumbPage and thumbPageSize
    this.thumbPage = this.originalThumbPage;
    this.thumbPageSize = 12;

    // reset class info form when closing class view
    /* this.registerForm = null; */

    // rebuild paginationClassList to match full view
    this.updatePaginatedThumbList();
    // this.setHeaderTextByCategory();
    this.scrollTop(157);
  }

  /**
   * Method to reset class list to its defaults when the reset button is pressed.
   */
  async resetClassList(): Promise<void> {
    this.resetThumbSearchInput();

    if (!this.thumbSearchPerformed) {
      return;
    }

    this.setCategory();
    await this.buildClassList();
    this.updatePaginatedThumbList();

    this.thumbSearchPerformed = false;
  }

  /** Method to reset the class search bar to an empty field when the reset button is pressed. */
  resetThumbSearchInput(): void {
    if (this.thumbSearchEl === undefined || this.thumbSearchEl.nativeElement === undefined) {
      return;
    }

    if (this.thumbSearchResultsNotFound) {
      this.thumbSearchResultsNotFound = false;
    }

    if (this.thumbSearchEl.nativeElement.value.length > 0) {
      this.thumbSearchEl.nativeElement.value = '';
    }

    if (this.thumbResetEl.nativeElement.style.visibility === 'visible') {
      this.thumbResetEl.nativeElement.style.visibility = 'hidden';
    }
  }

  getThumbnail(cls: any): string {
    return cls.getThumbnail() ?? '';
  }

  /**
   * Method to scroll to the top with an offset (in pixels)
   * @param offset top offset in pixels
   */
  scrollTop(offset?: number): void {
    window.scrollTo({ top: (offset ?? 0), behavior: 'smooth' }); // scroll to top smoothly
  }

  /**
   * Method to format a given date string by replacing dash separators with dots.
   * @param date 
   * @returns 
   */
  formatDate(date: string): string {
    return this.datePipe.transform(date, 'yyyy.MM.dd') || '';
  }

  /**
   * Method to toggle EditClass view.
   */
  toggleEditClass(): void {
    if (this.classManageMode === ClassManageMode.EditClass) {
      this.classManageMode = ClassManageMode.ViewAll;
      // this.cancelClassEdits();
      this.scrollTop(660);
    } else {
      this.classManageMode = ClassManageMode.EditClass;

      // trigger change detection to make sure the view is fully updated
      this.cdRef.detectChanges();
      this.scrollTop(1180);
    }
  }
}