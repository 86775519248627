import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClassSelectionService } from '../../../../../shared/services/class-selection-service';
import { ClassMission, MissionDurationStatus } from 'src/app/shared/models/ClassMission';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { ClassMissionService } from 'src/app/shared/services/class-mission.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-view-missions',
  templateUrl: './view-missions.component.html',
  styleUrls: ['./view-missions.component.css',
    '../view-tracking.component.css',
    '../../add-missions/add-missions.component.css',
    '../../edit-class/edit-class.component.css',
    '../../participant-list/participant-list.component.css',
    '../../manage-classes.component.css'
  ],
})
export class ViewMissionsComponent implements OnInit {
  MissionDurationStatus = MissionDurationStatus; // for access in template

  viewMode: 'tracking' | 'missions' = 'tracking';
  @Input() userIdInput: EventEmitter<number>;
  @Output() missionCountOutput: EventEmitter<number> = new EventEmitter<number>();
  classId!: number;
  userId: number;
  userFullname: string;
  className: string;

  userMissionsList: ClassMission[] = [];
  paginatedMissionsList: ClassMission[] = [];
  totalMissionDataCount: number = 0;

  // columns for Tracking list  
  modalMissionDisplayedColumns: string[] = ['index', 'goal', 'data', 'totalTime', 'createDate'];
  modalMissionDataSource = new MatTableDataSource<ClassMission>([]);

  activeModalMissionColumn: string = 'createDate';
  missionSortDirection: 'asc' | 'desc' = 'desc';

  modalMissionPage: number = 1; // current page in modal
  modalMissionPageSize: number = 10; // default number of items per page in modal
  modalMissionPageSizeOptions: number[] = [5, 10, 20, 50, 100];

  // 종목별 보기 드롭다운
  contentFilterOption: string = '체력측정';
  dropdownOptions: string[] = ['체력측정', '미션'];
  dropdownFilters: string[] = ['체력측정'];

  missionDataLoaded: boolean = false;
  missionFetchErrors: boolean = false;

  constructor(
    private clsSelectionService: ClassSelectionService,
    private missionService: ClassMissionService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private cdRef: ChangeDetectorRef
  ) { }

  // Booleans for toggling modals
  modalStates = {
    modal: false, step2Modal: false, step3Modal: false
  };

  // Getters and setters to activate different modals based on the clicked button
  get activeModal(): boolean {
    return this.modalStates?.modal || false;
  }

  set activeModal(value: boolean) {
    if (this.modalStates) {
      this.modalStates.modal = value;
    }
  }

  get activeStep2Modal(): boolean {
    return this.modalStates?.step2Modal || false;
  }

  set activeStep2Modal(value: boolean) {
    if (this.modalStates) {
      this.modalStates.step2Modal = value;
    }
  }

  get activeStep3Modal(): boolean {
    return this.modalStates?.step3Modal || false;
  }

  set activeStep3Modal(value: boolean) {
    if (this.modalStates) {
      this.modalStates.step3Modal = value;
    }
  }

  async ngOnInit(): Promise<void> {
    this.clsSelectionService.selectedClass$.subscribe((cls) => {
      this.classId = cls.getClassId();
      this.className = cls.getClassName();
    });

    this.clsSelectionService.userId$.subscribe((userId) => {
      this.userId = userId;
    });

    this.clsSelectionService.userFullname$.subscribe((userFullname) => {
      this.userFullname = userFullname;
    });

    try {
      await this.buildMissionList();
      //console.log(this.userMissionsList)
      this.totalMissionDataCount = this.userMissionsList.length;
      this.sortModalMissionData('createDate');
    } catch (error) {
      console.log('error fetching mission data on init:', error);
      this.missionFetchErrors = true;
      this.missionDataLoaded = true;
      this.cdRef.detectChanges();
    }
  }

  async buildMissionList(): Promise<void> {
    try {
      this.missionDataLoaded = false;
      this.missionFetchErrors = false; // reset errors at the start

      this.userMissionsList = (await this.missionService.getMissionsForUser(this.userId)).filter(mission => mission.getClassId() === this.classId);

      this.missionCountOutput.emit(this.userMissionsList.length);
    } catch (error) {
      console.log('error building raw missions list for user:', error);
      this.missionFetchErrors = true;
      this.missionDataLoaded = true;
      this.cdRef.detectChanges();
      return;
    } finally {
      this.missionDataLoaded = !this.missionFetchErrors; // set loaded to true only if no errors
      this.cdRef.detectChanges(); // ensure changes are reflected in UI
    }
  }

  updatePaginatedMissionList(): void {
    const startIndex = (this.modalMissionPage - 1) * this.modalMissionPageSize;
    const endIndex = startIndex + this.modalMissionPageSize;

    // use the deduplicated list for pagination
    this.paginatedMissionsList = this.userMissionsList.slice(startIndex, endIndex);

    // update the data source for the table
    this.modalMissionDataSource.data = [...this.paginatedMissionsList];
    this.missionDataLoaded = true;
  }

  /**
   * Method to reset the user list to its original form (전체보기) when the '전체' dropdown option is selected.
   */
  resetFilteredList(): void {
    //this.buildFilteredList(this.showAllFilter);
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  formatMissionData(element: ClassMission): SafeHtml {
    const weeklyExerciseTime = element.getWeeklyExerciseTime();

    // Return standalone '--' immediately if weeklyExerciseTime is empty
    if (weeklyExerciseTime.length === 0) {
      return this.sanitizer.bypassSecurityTrustHtml('--');
    }

    const sessionDuration = element.getSessionDuration();
    const startDate = new Date(element.getStartDate()); // Parse Date

    const getMonthLength = (month: number, year: number): number => {
      switch (month) {
        case 1: case 3: case 5: case 7: case 8: case 10: case 12:
          return 31;
        case 4: case 6: case 9: case 11:
          return 30;
        case 2:
          return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? 29 : 28;
        default:
          return 0;
      }
    };

    const getInitialWeekNumber = (date: Date): number => {
      const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const daysFromFirstSunday = 7 - firstDayOfMonth.getDay();
      const initialWeek = Math.ceil((date.getDate() - daysFromFirstSunday) / 7) + 1;
      return initialWeek > 0 ? initialWeek : 1;
    };

    const generateTableRow = (weeklyExerciseTime: number[]) => {
      let currentDate = new Date(startDate); // Start from the mission start date
      let currentMonth = currentDate.getMonth(); // Track the current month
      let currentYear = currentDate.getFullYear(); // Track the year
      let currentWeekIndex = getInitialWeekNumber(currentDate); // Determine the initial week number

      const rows = []; // Accumulate rows for each week

      for (const time of weeklyExerciseTime) {
        const month = currentDate.getMonth() + 1; // Months are zero-based in JS
        const day = currentDate.getDate();

        // Generate the row for the current week
        rows.push(`<tr class="table-row">
          <td class="table-cell left">
            <div class="table-cell-text">${month}월 ${currentWeekIndex}주차</div>
          </td>
          <td class="table-cell right width-45">
            <div class="table-cell-text">${time}분 / ${sessionDuration}분</div>
            <img class="table-cell-img" src="${time >= sessionDuration
            ? '../../../../assets/mission_icons/mission_ok.png'
            : '../../../../assets/mission_icons/mission_bad.png'
          }" />
          </td>
        </tr>`);

        // Increment the date by 7 days
        currentDate.setDate(currentDate.getDate() + 7);

        // Check if the current date has moved to a new month
        const monthLength = getMonthLength(currentMonth + 1, currentYear);
        if (day + 7 > monthLength) {
          currentDate.setDate(1); // Set date to the first day of the next month
          currentMonth = currentDate.getMonth(); // Update the month
          currentYear = currentDate.getFullYear(); // Update the year
          currentWeekIndex = 1; // Reset week index
        } else {
          currentWeekIndex++;
        }
      }

      return rows.join('');
    };

    // Generate rows for weekly exercise data
    const formattedData = generateTableRow(weeklyExerciseTime);
    return this.sanitizer.bypassSecurityTrustHtml(`
      <table class="tracking-table">
        ${formattedData}
      </table>
    `);
  }

  formatMissionTotalTime(element: ClassMission): string {
    return `${element.getUserExerciseTime() ?? 0}분`
  }

  getMissionStatusIcon(element: ClassMission): string {
    const iconPath = '../../../../assets/mission_icons/';
    const iconFileName = element.getUserExerciseTime() >= element.getSessionDuration() ? 'mission_ok.png' : 'mission_bad.png';
    return iconPath + iconFileName;
  }

  formatGoal(elementData: ClassMission): string {
    const sessionsPerWeek = elementData.getSessionsPerWeek();
    const sessionDuration = elementData.getSessionDuration();

    return `주 ${sessionsPerWeek}회 ${sessionDuration}분`;
  }

  /** Method to toggle sorting function for a certain column in the missionList
   * @param column string value that represents the column to be sorted
   */
  toggleModalMissionSort(column: string): void {
    if (this.activeModalMissionColumn === column) {
      this.missionSortDirection = this.missionSortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.activeModalMissionColumn = column;
      this.missionSortDirection = 'desc';
    }

    this.sortModalMissionData(column);
  }

  /**
* Method that sorts the missionList based on a selected column.
* @param column column to be sorted
*/
  sortModalMissionData(column: string): void {
    // check if column is createDate
    if (column === 'createDate') {
      this.sortByMissionCreateDate();
    } else if (column === 'goal') {
      this.sortByMissionGoal();
    } else if (column === 'totalTime') {
      this.sortByTotalTime();
    }

    // update paginated missionList after sorting
    this.modalMissionPage = 1;
    this.updatePaginatedMissionList();
  }

  sortByMissionCreateDate(): void {
    this.userMissionsList = this.userMissionsList.sort((a, b) => {
      let valueA = new Date(a.getStartDate());
      let valueB = new Date(b.getStartDate());

      // compare the dates
      if (this.missionSortDirection === 'asc') {
        return valueA.getTime() - valueB.getTime(); // ascending order
      } else {
        return valueB.getTime() - valueA.getTime();
      }
    });
  }

  sortByMissionGoal(): void {
    this.userMissionsList = this.userMissionsList.sort((a, b) => {
      // handle null or undefined missions explicitly
      if (!a && !b) {
        return 0; // both are null or undefined; considered equal
      } else if (!a) {
        return this.missionSortDirection === 'asc' ? -1 : 1; // missionA is null/undefined; goes bottom in asc
      } else if (!b) {
        return this.missionSortDirection === 'asc' ? 1 : -1; //missionB is null/undefined; goes bottom in asc
      }

      let valueA = a.getSessionsPerWeek();
      let valueB = b.getSessionsPerWeek();

      // compare by sessions per week first
      if (valueA !== valueB) {
        if (this.missionSortDirection === 'asc') {
          return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        } else {
          return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
        }
      }

      // if sessions per week are the same, compare by session duration
      valueA = a.getSessionDuration();
      valueB = b.getSessionDuration();

      if (this.missionSortDirection === 'asc') {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    });
  }

  sortByTotalTime(): void {
    this.userMissionsList = this.userMissionsList.sort((a, b) => {
      const valueA = a.getUserExerciseTime();
      const valueB = b.getUserExerciseTime();

      if (!valueA && !valueB) {
        return 0;
      } else if (!valueA) {
        return this.missionSortDirection === 'asc' ? -1 : 1;
      } else if (!valueB) {
        return this.missionSortDirection === 'asc' ? 1 : -1;
      }

      if (valueA !== valueB) {
        if (this.missionSortDirection === 'asc') {
          return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        } else {
          return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
        }
      } else {
        return 0;
      }
    });
  }

  onModalMissionPageChange(newPage: number): void {
    this.modalMissionPage = newPage;
    this.updatePaginatedMissionList();
  }

  /** 
    * Method to change pagination paging size for trackingList.
    * @param event dropdown option selection event
    */
  onModalMissionPageSizeChange(event: any): void {
    this.modalMissionPageSize = event.target.value; // update page size
    this.modalMissionPage = 1; // reset to first page
    this.updatePaginatedMissionList();
  }

  /**
   * Helper method to transform a Date object into a date string.
   * @param date a valid Date object
   * @returns a date string in 'yyyy.MM.dd' format
   */
  dateToString(date: Date): string {
    if (!date) {
      return '--';
    }

    return this.datePipe.transform(date, 'yyyy.MM.dd') || '';
  }

  /**
   * Utility method to add a status label if mission is currently active.
   * (mission.startDate <= today <= mission.endDate)
   */
  isActiveMission(rowData: any): boolean {
    if (!rowData) return false;

    console.log(rowData);

    const today = new Date();
    const startDate = rowData.startAndEndDates.start;
    const endDate = rowData.startAndEndDates.end;

    if (startDate instanceof Date && endDate instanceof Date) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      console.log(startDate <= today && today <= endDate)

      return startDate <= today && today <= endDate;
    }

    return false;
  }

  /**
   * Utility method to add a status label if mission is finished.
   * (today > mission.endDate)
   */
  isFinishedMission(rowData: any): boolean {
    if (!rowData) return false;

    console.log(rowData);

    const today = new Date();
    const endDate = rowData.startAndEndDates.end;

    if (endDate instanceof Date) {
      endDate.setHours(23, 59, 59, 999);

      return today > endDate;
    }

    return false;
  }

  getMissionStatus(rowData: any): MissionDurationStatus {
    if (!rowData) return MissionDurationStatus.Other;

    const today = new Date();
    const startDate = rowData.startAndEndDates.start;
    const endDate = rowData.startAndEndDates.end;

    if (startDate instanceof Date && endDate instanceof Date) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      if (startDate <= today && today <= endDate) {
        return MissionDurationStatus.Active;
      } else if (today > endDate) {
        return MissionDurationStatus.Finished;
      } else if (startDate > today) {
        return MissionDurationStatus.Future;
      }
    }

    return MissionDurationStatus.Other;
  }

  getMissionStatusLabel(rowData: any): string {
    if (!rowData) return '';

    const status = this.getMissionStatus(rowData);

    switch (status) {
      case MissionDurationStatus.Active:
        return '진행 중';
      case MissionDurationStatus.Finished:
        return '종료';
      case MissionDurationStatus.Future:
        return '시작 예정';
      default:
        return '';
    }
  }

  getMissionStatusClass(rowData: any): string {
    if (!rowData) return '';

    const status = this.getMissionStatus(rowData);

    switch (status) {
      case MissionDurationStatus.Active:
        return 'active';
      case MissionDurationStatus.Finished:
        return 'finished';
      case MissionDurationStatus.Future:
        return 'future';
      default:
        return '';
    }
  }

  /**
   * Helper method to generate a FormControl for input into DaysOfWeekPickerComponent.
   * @param days mission.getSessionDays() for some ClassMission mission
   * @returns a new FormControl encapsulating the value of days
   */
  createFormControl(days: number): FormControl {
    return new FormControl(days);
  }

  /**
   * Method to scroll to the top with an offset (in pixels)
   * @param offset top offset in pixels
   */
  scrollTop(offset: number): void {
    window.scrollTo({ top: offset, behavior: 'smooth' }); // scroll to top smoothly
  }
}
