import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';



const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Accept": "*",
    'Access-Control-Allow-Origin': 'https://tboxfit.com',
  })
};


@Injectable({
  providedIn: 'root'
})
export class JusoService {

  constructor( private http: HttpClient, ) { }

  jusoApiUrl = 'https://business.juso.go.kr/addrlink/addrLinkApi.do';
  confmKey = 'U01TX0FVVEgyMDIzMDMxNjEyNDQyNzExMzU5OTc=';
  resultType = 'json';

  getJusos(jusoKeyword): Observable <any> {
    return this.http.get(this.jusoApiUrl + '?' +
    'confmKey=' + this.confmKey + '&' +
    'resultType=' + this.resultType + '&' +
    'keyword=' + jusoKeyword
    );
  }

}
