import { DayOfWeek, DateRange } from "./ClassInfo";

export enum MissionDurationStatus {
  Finished,
  Active,
  Future,
  Other
}

export class ClassMission {
  private missionId: number;
  private userId: number;
  private classId: number;
  private orgId: number;
  private sessionsPerWeek: number; // 주당 운동 횟수
  private userExerciseTime: number; // 미션 기간 중 실제 운동 수행 시간
  private weeklyExerciseTime: number[]; // 미션 주차별 실제 운동 수행 시간
  private userNumSessions: number; // 실제 운동 수행 횟수 (시간 및 횟수 충족 시 미션 완수)
  private sessionDuration: number; // 운동 세션 당 목표 시간
  private sessionDays: DayOfWeek[]; // 세션 요일
  private startAndEndDates: DateRange; // 미션 기간
  private exerciseGoal: string; // 운동 목표 (텍스트)

  constructor(
    missionId: number,
    userId: number,
    classId: number,
    orgId: number,
    userExerciseTime: number,
    weeklyExerciseTime: number[],
    userNumSessions: number,
    sessionsPerWeek: number,
    sessionDuration: number,
    sessionDays: DayOfWeek[],
    startDate: Date,
    endDate: Date,
    exerciseGoal: string
  ) {
    this.missionId = missionId;
    this.userId = userId;
    this.classId = classId;
    this.orgId = orgId;
    this.userExerciseTime = userExerciseTime;
    this.weeklyExerciseTime = weeklyExerciseTime;
    this.userNumSessions = userNumSessions;
    this.sessionsPerWeek = sessionsPerWeek;
    this.sessionDuration = sessionDuration;
    this.sessionDays = sessionDays.sort();
    this.startAndEndDates = { start: startDate, end: endDate };
    this.exerciseGoal = exerciseGoal;
  }

  public getMissionId(): number {
    return this.missionId;
  }

  public getUserId(): number {
    return this.userId;
  }

  public getClassId(): number {
    return this.classId;
  }

  public getOrgId(): number {
    return this.orgId;
  }

  public getSessionsPerWeek(): number {
    return this.sessionsPerWeek;
  }

  public getUserExerciseTime(): number {
    return this.userExerciseTime;
  }

  public getWeeklyExerciseTime(): number[] {
    return this.weeklyExerciseTime;
  }

  public getUserNumSessions(): number {
    return this.userNumSessions;
  }

  public getSessionDays(): DayOfWeek[] {
    return this.sessionDays;
  }

  public getSessionDuration(): number {
    return this.sessionDuration;
  }

  public getExerciseGoal(): string {
    return this.exerciseGoal;
  }

  public getStartDate(): Date {
    return this.startAndEndDates.start;
  }

  public getEndDate(): Date {
    return this.startAndEndDates.end;
  }

  public setMissionId(missionId: number): void {
    this.missionId = missionId;
  }

  public setUserId(userId: number): void {
    this.userId = userId;
  }

  public setClassId(classId: number): void {
    this.classId = classId;
  }

  public setOrgId(orgId: number): void {
    this.orgId = orgId;
  }

  public setSessionsPerWeek(sessionsPerWeek: number): void {
    this.sessionsPerWeek = sessionsPerWeek;
  }

  public setUserExerciseTime(totalExerciseTime: number): void {
    this.userExerciseTime = totalExerciseTime;
  }

  public setWeeklyExerciseTime(weeklyExerciseTime: number[]): void {
    this.weeklyExerciseTime = weeklyExerciseTime;
  }

  public setUserNumSessions(userNumSessions: number): void {
    this.userNumSessions = userNumSessions;
  }

  public setSessionDays(sessionDays: DayOfWeek[]) {
    this.sessionDays = sessionDays.sort();
  }

  public setSessionDuration(sessionDuration: number): void {
    this.sessionDuration = sessionDuration;
  }

  public setExerciseGoal(exerciseGoal: string): void {
    this.exerciseGoal = exerciseGoal;
  }

  public setStartDate(startDate: Date): void {
    this.startAndEndDates.start = startDate;
  }

  public setEndDate(endDate: Date): void {
    this.startAndEndDates.end = endDate;
  }

  public equals(other: ClassMission): boolean {
    if (!other) return false;

    // compare start and end dates after normalizing times
    const normalizeDate = (date: Date, isStart: boolean): Date => {
      const normalized = new Date(date);
      if (isStart) {
        normalized.setHours(0, 0, 0, 0);
      } else {
        normalized.setHours(23, 59, 59, 999);
      }
      return normalized;
    };

    const thisStartDate = normalizeDate(this.startAndEndDates.start, true);
    const thisEndDate = normalizeDate(this.startAndEndDates.end, false);
    const otherStartDate = normalizeDate(other.startAndEndDates.start, true);
    const otherEndDate = normalizeDate(other.startAndEndDates.end, false);

    // compare arrays after sorting
    const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
      if (arr1.length !== arr2.length) return false;
      const sorted1 = [...arr1].sort();
      const sorted2 = [...arr2].sort();
      return sorted1.every((value, index) => value === sorted2[index]);
    };

    return (
      this.missionId === other.missionId &&
      this.userId === other.userId &&
      this.classId === other.classId &&
      this.orgId === other.orgId &&
      this.sessionsPerWeek === other.sessionsPerWeek &&
      this.userExerciseTime === other.userExerciseTime &&
      areArraysEqual(this.weeklyExerciseTime, other.weeklyExerciseTime) &&
      this.userNumSessions === other.userNumSessions &&
      this.sessionDuration === other.sessionDuration &&
      areArraysEqual(this.sessionDays, other.sessionDays) &&
      thisStartDate.getTime() === otherStartDate.getTime() &&
      thisEndDate.getTime() === otherEndDate.getTime() &&
      this.exerciseGoal === other.exerciseGoal
    );
  }

}
