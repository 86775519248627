import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef, Input } from "@angular/core";
import { ClassManageMode } from "../manage-classes.component";
import { MatTableDataSource } from "@angular/material/table"
import { MatPaginator } from "@angular/material/paginator";
import { OrgUserStatus } from "src/app/shared/models/User";
import { Organization } from "src/app/shared/models/Organization";
import { ClassUser, UserMissionStatus } from "src/app/shared/models/ClassUser";
import { ClassCourse, } from "src/app/shared/models/ClassCourse";
import { ClassInfo, DayOfWeek } from "src/app/shared/models/ClassInfo";
import { ClassSelectionService } from "../../../../shared/services/class-selection-service";
import { ClassMission } from "src/app/shared/models/ClassMission";
import { lastValueFrom, Subject, Subscription, take, takeUntil } from "rxjs";
import { DatePipe } from "@angular/common";
import { ClassCourseService } from "src/app/shared/services/class-course.service";
import { RegularUserService } from "src/app/shared/services/regular-user.service";
import { ClassMissionService } from "src/app/shared/services/class-mission.service";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-participant-list",
  templateUrl: "./participant-list.component.html",
  styleUrls: ["./participant-list.component.css", "../manage-classes.component.css"]
})
export class ParticipantListComponent implements OnInit {
  // currently selected class and its class info
  ClassManageMode = ClassManageMode;
  org: Organization | null = null;
  selectedClass: ClassCourse | null = null;
  selectedClassInfo: ClassInfo | null = null;
  isMyClass: boolean = false;

  //////
  /* Class Participant List */

  // ViewChild decorator for user list paginator (사용자 리스트 페이지네이션)
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  // ViewChild decorator for user search bar (사용자 검색창)
  private searchInputEl: ElementRef;
  @ViewChild('searchInput', { static: false }) set searchInput(searchInput: ElementRef) {
    this.searchInputEl = searchInput;
  }

  // ViewChild decorator for user search reset button (사용자 검색 초기화 버튼)
  private resetBtnEl: ElementRef;
  @ViewChild('resetBtn', { static: false }) set resetBtn(resetBtn: ElementRef) {
    this.resetBtnEl = resetBtn;
  }

  // Arrays to hold participant list for currently selected class
  userList: ClassUser[] = [];
  filteredUserList: ClassUser[] = [];
  paginatedUserList: ClassUser[] = [];

  activeColumn: string = 'name';
  userSortDirection: 'asc' | 'desc' = 'asc';

  // class management modes 
  classManageMode: ClassManageMode = ClassManageMode.ViewAll;

  // columns for user list
  displayedColumns: string[] = ['select', 'name', 'id', 'missions', 'paps', 'freqWorkout', 'lastWorkoutDate', 'missionStatus'];
  dataSource = new MatTableDataSource<ClassUser>([]);

  page: number = 1; // current page
  pageSize: number = 5; // number of items per page
  pageSizeOptions: number[] = [5, 10, 20, 30, 50]; // options for page size dropdown

  // Booleans for toggling modals
  modalStates = {
    [ClassManageMode.ViewAll]: { modal: false },
    [ClassManageMode.AddUsers]: { modal: false },
    [ClassManageMode.RemoveUsers]: { modal: false },
    [ClassManageMode.AddMissions]: { modal: false },
    [ClassManageMode.EditMissions]: { modal: false },
    [ClassManageMode.ViewTracking]: { modal: false },
    [ClassManageMode.ViewMissions]: { modal: false },
    [ClassManageMode.EditClass]: { modal: false },
    [ClassManageMode.CloseClass]: { modal: false }
  };

  // Array to hold items selected by checkbox(es)
  // 체크박스로 선택된 항목 저장
  candidates: ClassUser[] = [];

  // receive open class view event from parent (ManageClassesComponent)
  @Input() toggleClassViewEvent = new EventEmitter<void>();
  // emit toggle events to parent
  @Output() toggleRemoveUsersEvent = new EventEmitter<void>();
  @Output() toggleAddUsersEvent = new EventEmitter<void>();
  @Output() toggleAddMissionsEvent = new EventEmitter<void>();
  @Output() toggleEditClassEvent = new EventEmitter<void>();

  //////
  /* Mission Map (key: userId, value: ClassMission[]) */
  classMissionMap: Map<number, ClassMission>;
  MissionStatus: UserMissionStatus;
  newMission: ClassMission = null;

  submissionValid: boolean = true;
  loading: boolean = false;
  loadingError: boolean = false;

  constructor(
    private regUserService: RegularUserService, // 일반 사용자 data fetch API
    private clService: ClassCourseService,
    private clsSelectionService: ClassSelectionService, // selectedClass fetch API
    private missionService: ClassMissionService, // mission data fetch API,
    private cdRef: ChangeDetectorRef,
    private datePipe: DatePipe
  ) { }

  // Getters and setters to activate different modals based on the clicked button
  get activeModal(): boolean {
    return this.modalStates[this.classManageMode]?.modal || false;
  }

  set activeModal(value: boolean) {
    if (this.modalStates[this.classManageMode]) {
      this.modalStates[this.classManageMode].modal = value;
    }
  }

  private destroy$ = new Subject<void>();

  async ngOnInit(): Promise<void> {
    // subscribe to selectedClass and selectedClassInfo changes
    this.clsSelectionService.selectedClass$.pipe(takeUntil(this.destroy$)).subscribe(
      (cls) => (this.selectedClass = cls)
    );

    this.clsSelectionService.selectedClassInfo$.pipe(takeUntil(this.destroy$)).subscribe(
      (clsInfo) => (this.selectedClassInfo = clsInfo)
    );

    this.clsSelectionService.isMyClass$.pipe(takeUntil(this.destroy$)).subscribe(
      (isMyClass) => (this.isMyClass = isMyClass)
    );

    this.clsSelectionService.org$.pipe(takeUntil(this.destroy$)).subscribe(
      (org) => (this.org = org)
    );

    this.clsSelectionService.newMission$.pipe(takeUntil(this.destroy$)).subscribe(
      (newMission) => (this.newMission = newMission)
    );

    this.toggleClassViewEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.candidates = [];
      this.buildUserList();
      this.buildMissionMap();
    });

    this.candidates = [];
    this.buildUserList();

    this.userSortDirection = 'asc';
    this.sortData('fullname');
    this.activeColumn = 'fullname';

    this.classMissionMap = new Map<number, ClassMission>();

    try {
      await this.buildMissionMap();
    } catch (error) {
      console.log('error fetching missions for class users:', error);
    }
  }

  /**
   * Method to set modal icon based on the clicked button.
   */
  getModalIcon(): string {
    if (!this.submissionValid) {
      return '../../../../assets/icons/Icon_64px_col-12.png';
    }

    const iconMapping = {
      [ClassManageMode.AddUsers]: '../../../../assets/icons/Icon_64px_col-72.png',
      [ClassManageMode.RemoveUsers]: '../../../../assets/icons/Icon_64px_col-77.png',
      [ClassManageMode.AddMissions]: '../../../../assets/icons/Icon_64px_col-96.png',
    }

    return iconMapping[this.classManageMode] || '../../../../assets/icons/Icon_64px_col-77.png';
  }

  async buildUserList(): Promise<void> {
    if (!this.selectedClass) {
      return; // Do nothing if selectedClass is null or undefined
    }

    try {
      this.loading = true;

      this.userList = await lastValueFrom(this.regUserService.getClassUsers(this.selectedClass.getClassId()));

      this.filteredUserList = [...this.userList];

      this.clsSelectionService.setUserList(this.userList);

      // call updatePaginatedList to show the first page
      this.updatePaginatedList();

      this.loading = false;
      this.loadingError = false;
    } catch (error) {
      console.log('error fetching participant list for class:', error);
      this.loading = false;
      this.loadingError = true;
      return;
    }
  }

  /**
 * Builds a map of the most recent mission for each user in the selected class.
 * Each userId is mapped to their most recent ClassMission based on startDate,
 * ensuring that today is within the mission's startDate and endDate range.
 */
  async buildMissionMap(): Promise<void> {
    try {
      // Reset the map before populating it
      this.classMissionMap.clear();

      const missionList = await this.missionService.getMissionsForClass(this.selectedClass.getClassId());
      const today = new Date();

      // Iterate over the mission list
      missionList.forEach((mission) => {
        const userId = mission.getUserId();
        const startDate = new Date(mission.getStartDate());
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(mission.getEndDate());
        endDate.setHours(23, 59, 59, 999);

        // Ensure the mission is within the valid date range
        if (today >= startDate && today <= endDate) {
          // Check if the user already has a mission in the map
          const existingMission = this.classMissionMap.get(userId);

          // Compare the startDate and update if the new mission is more recent
          // or if the new mission has more sessions per week
          if (
            !existingMission ||
            startDate.getTime() > new Date(existingMission.getStartDate()).getTime() ||
            mission.getSessionDays().length > existingMission.getSessionDays().length
          ) {
            this.classMissionMap.set(userId, mission);
          }
        }
      });

      //console.log('Class Mission Map:', this.classMissionMap);
    } catch (error) {
      console.error('Error building mission map:', error);
    }
  }

  /** Methods to handle user list pagination */
  updatePaginatedList() {
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedUserList = this.filteredUserList.slice(startIndex, endIndex);

    this.dataSource.data = [...this.paginatedUserList];
  }

  onPageChange(newPage: number) {
    this.page = newPage;
    this.updatePaginatedList();
    this.scrollTop(635);
  }

  /**
   * Returns the number of participants for the selected class.
   * @returns number of participants for the selected class
   */
  getUserCountForClass(): number {
    return this.selectedClass.getParticipantList().length;
  }

  /**
   * Method to return the number of users that are NOT of a given status.
   * @param filter OrgUserStatus predicate
   * @returns the number of items of filteredUserList that do NOT match the given filter
   */
  getFilteredUserCount(filter?: OrgUserStatus): number {
    if (!filter) {
      return this.filteredUserList.length;
    }

    return this.filteredUserList.filter(user => user.getUserStatus() !== filter).length;
  }

  /**
   * Method to toggle the selection of a candidate.
   * @param user 
   */
  toggleCandidate(user: ClassUser): void {
    if (!user.selected) {
      user.setSelected(false);
    }

    if (user.selected) {
      this.candidates.push(user);
    } else {
      this.candidates = this.candidates.filter(u => u.getUserId() !== user.getUserId());
    }
    this.cdRef.detectChanges(); // trigger change detection manually
  }

  /**
   * Method to change class management mode according to selected modal
   * @param modal string value entered in openModal
   */
  changeClassManageMode(modal: 'addUsers' | 'removeUsers' | 'addMissions' | 'editMissions' | 'viewTracking' | 'editClass'): void {
    switch (modal) {
      case 'addUsers':
        this.classManageMode = ClassManageMode.AddUsers;
        break;
      case 'removeUsers':
        this.classManageMode = ClassManageMode.RemoveUsers;
        break;
      case 'addMissions':
        this.classManageMode = ClassManageMode.AddMissions;
        break;
      case 'editMissions':
        this.classManageMode = ClassManageMode.EditMissions;
        break;
      case 'viewTracking':
        this.classManageMode = ClassManageMode.ViewTracking;
        break;
      case 'editClass':
        this.classManageMode = ClassManageMode.EditClass;
        break;
      default:
        this.classManageMode = ClassManageMode.ViewAll;
        break;
    }
  }

  buildCandidatesMissionMap(): Map<number, ClassMission> | null {
    if (!this.candidates || !this.classMissionMap) {
      return null;
    }

    let candidatesMissionMap = new Map<number, ClassMission>();

    this.candidates.forEach(candidate => {
      const userId = candidate.getUserId();
      const mission = this.classMissionMap.get(userId);
      candidatesMissionMap.set(userId, mission);
    });

    return candidatesMissionMap;
  }

  /** method to open candidates accept / reject modal. */
  openModal(modal: 'addUsers' | 'removeUsers' | 'addMissions' | 'editMissions' |'viewTracking' | 'editClass'): void {
    this.changeClassManageMode(modal);

    switch (this.classManageMode) {
      case ClassManageMode.EditClass:
        this.deselectCandidates();
        break;

      case ClassManageMode.RemoveUsers:
        this.clsSelectionService.setCandidates(this.candidates);
        break;

      case ClassManageMode.AddMissions:
      case ClassManageMode.EditMissions:
        this.clsSelectionService.setSelectedClass(this.selectedClass);
        this.clsSelectionService.setCandidates([...this.candidates]);
        this.clsSelectionService.setClassMissionMap(this.buildCandidatesMissionMap());
        break;

      default:
        console.log('this.deselectCandidates()');
        this.deselectCandidates();
    }

    this.activeModal = true;
  }

  /**
   * Function to deselect all checkboxes and reset the candidates list.
   */
  deselectCandidates(): void {
    this.candidates = [];
    this.dataSource.data.forEach(user => {
      user.selected = false;
    });
  }

  /** Method to close candidates accept / reject modal.
   *  @param 'remove', 'suspend', or 'restore'
   */
  closeModal(): void {
    if (!this.activeModal) {
      console.log('Modal is already closed.');
      return;
    }

    if (this.activeModal) {
      this.activeModal = false;
    }

    this.deselectCandidates();

    if (this.isAllSelected()) {
      // this.toggleSelectAll({ checked: false });
    }

    this.classManageMode = ClassManageMode.ViewAll;

    this.scrollTop(635);
  }

  closeModalWithoutDeselect(): void {
    this.activeModal = false;
    this.classManageMode = ClassManageMode.ViewAll;
    this.scrollTop(635);
  }

  /** Method to add / remove selected candidates.
   */
  processUser(updatedCandidates?: ClassUser[]): void {
    if (this.classManageMode === ClassManageMode.RemoveUsers) {

    }

    if (this.classManageMode === ClassManageMode.AddUsers) {
      this.candidates = updatedCandidates;
      this.candidates = [];
    }

    this.buildUserList();
    this.buildMissionMap();
    this.activeColumn = 'fullname';
    this.sortData('fullname');

    // trigger change detection manually
    this.cdRef.detectChanges();
  }

  /* ClassUserList select/sort functions */
  /** Method to toggle the 'select all' checkbox
   *  @param event: checkbox button click event
   */
  toggleSelectAll(event: any): void {
    const checked = event.checked;

    this.dataSource.data.forEach(user => {
      user.selected = checked;

      // add user to candidates if checked and not already in the list
      if (checked && !this.candidates.find(u => u.getUserId() === user.getUserId())) {
        this.candidates.push(user);
      }

      // remove user from candidates if unchecked
      if (!checked) {
        this.candidates = this.candidates.filter(u => u.getUserId() !== user.getUserId());
      }
    });
  }

  /**
   * Method to check if all rows in the ClassUserList are selected.
   * @returns true if all items selected, false otherwise
   */
  isAllSelected(): boolean {
    return this.paginatedUserList.every(user => user.selected);
  }

  /**
   * Method to check if only some rows in the ClassUserList are selected.
   * @returns true if only some items selected, false if all selected
   */
  isIndeterminate(): boolean {
    return this.paginatedUserList.some(user => user.selected) && !this.isAllSelected();
  }

  /** Method to toggle sorting function for a certain column
   * @param column string value that represents the column to be sorted
   */
  toggleSort(column: string) {
    if (this.activeColumn === column) {
      this.userSortDirection = this.userSortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.activeColumn = column;
      (this.activeColumn === 'lastWorkoutDate' || this.activeColumn === 'mission' || this.activeColumn === 'fitnessLevel')
        ? this.userSortDirection = 'desc' : this.userSortDirection = 'asc';
    }

    this.sortData(column);
  }

  /**
   * Method that sorts the user list based on a selected column.
   * @param column column to be sorted
   */
  sortData(column: string) {
    // check if column is requestDate
    if (column === 'lastWorkoutDate') {
      this.sortByLastWorkoutDate();
    } else if (column === 'mission') {
      this.sortByMission();
    } else if (column === 'missionStatus') {
      this.sortByMissionStatus();
    } else {
      this.sortAlNumStrings(column);
    }

    // update paginated list after sorting
    this.page = 1;
    this.updatePaginatedList();
  }

  sortAlNumStrings(column: string): void {
    this.filteredUserList.sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      // handle other string columns
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        // split Korean/English part and number part
        const textPartA = valueA.match(/[^\d]+/g)?.[0] || ''; // text part (Korean / English)
        const numberPartA = parseInt(valueA.match(/\d+/g)?.[0] || '0', 10) // number part

        const textPartB = valueB.match(/[^\d]+/g)?.[0] || ''; // text part (Korean / English)
        const numberPartB = parseInt(valueB.match(/\d+/g)?.[0] || '0', 10) // number part

        // first, compare text part using localeCompare with support for both Korean and English 
        const textCompare = textPartA.localeCompare(textPartB, 'ko-KR'); // handles both Korean and English
        if (textCompare != 0) {
          return this.userSortDirection === 'asc' ? textCompare : -textCompare;
        }

        // if text parts are equal, compare the number part numerically
        return this.userSortDirection === 'asc' ? numberPartA - numberPartB : numberPartB - numberPartA;
      }

      // fallback to standard comparison for non-string values 
      if (this.userSortDirection === 'asc') {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    });
  }

  missionExists(userId: number): boolean {
    return this.classMissionMap.has(userId);
  }

  formatMissionPeriod(userId: number): string {
    const mission = this.classMissionMap.get(userId);

    if (mission?.getStartDate() && mission?.getEndDate()) {
      const startDateString = this.dateToString(new Date(mission.getStartDate()));
      const endDateString = this.dateToString(new Date(mission.getEndDate()));

      if (startDateString !== '' && startDateString !== 'Invalid Date' && endDateString !== '' && endDateString !== 'Invalid Date') {
        return `${startDateString} ~ ${endDateString}`;
      }
    }

    return '';
  }

  formatMissionText(userId: number): string {
    const mission = this.classMissionMap.get(userId);

    return mission
      ? `주 ${mission.getSessionsPerWeek()}회 ${mission.getSessionDuration()}분`
      : '--';
  }

  sortByMission(): void {
    this.filteredUserList.sort((a, b) => {
      const missionA = this.classMissionMap.get(a.getUserId());
      const missionB = this.classMissionMap.get(b.getUserId());

      // handle null or undefined missions explicitly
      if (!missionA && !missionB) {
        return 0; // both are null or undefined; considered equal
      } else if (!missionA) {
        return this.userSortDirection === 'asc' ? -1 : 1; // missionA is null/undefined; goes bottom in asc
      } else if (!missionB) {
        return this.userSortDirection === 'asc' ? 1 : -1; //missionB is null/undefined; goes bottom in asc
      }

      let valueA = missionA.getSessionsPerWeek();
      let valueB = missionB.getSessionsPerWeek();

      // compare by sessions per week first
      if (valueA !== valueB) {
        if (this.userSortDirection === 'asc') {
          return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        } else {
          return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
        }
      }

      // if sessions per week are the same, compare by session duration
      valueA = missionA.getSessionDuration();
      valueB = missionB.getSessionDuration();

      if (this.userSortDirection === 'asc') {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    });
  }

  sortByMissionStatus(): void {
    this.filteredUserList.sort((a, b) => {
      const missionStatusA = a.getMissionStatus();
      const levelUpA = a.isLevelUp();
      const missionStatusB = b.getMissionStatus();
      const levelUpB = b.isLevelUp();

      const sortDirection = this.userSortDirection === 'asc' ? 1 : -1;

      if (missionStatusA !== missionStatusB) {
        return (missionStatusA === UserMissionStatus.OK ? -1 : 1) * sortDirection;
      }

      if (levelUpA !== levelUpB) {
        return (levelUpA === true ? -1 : 1) * sortDirection;
      }

      return 0;
    });
  }

  sortByLastWorkoutDate(): void {
    this.filteredUserList.sort((a, b) => {
      let valueA = a['lastWorkoutDate'];
      let valueB = b['lastWorkoutDate'];

      // handle valid dates
      const dateA = valueA;
      const dateB = valueB;

      // compare the dates
      if (this.userSortDirection === 'asc') {
        return dateA.getTime() - dateB.getTime(); // ascending order
      } else {
        return dateB.getTime() - dateA.getTime(); // descending order
      }
    });
  }

  /* pending user list search functions */
  searchPerformed: boolean = false;
  searchResultsNotFound: boolean = false;

  /**
   * Method to search ClassUserList by name, username, email, or mission details
   * @param query input string
   */
  searchPendingUserList(query: string): void {
    if (!query.trim()) {
      this.resetUserList();
      return;
    }

    const lowerQuery = query.toLowerCase();

    this.filteredUserList = this.userList.filter(user => {
      const userMission = this.classMissionMap.get(user.getUserId());

      // Extract numerical parts for '회' and '분' in the query
      const sessionsPerWeekMatch = lowerQuery.match(/(\d+)\s*회/);
      const sessionDurationMatch = lowerQuery.match(/(\d+)\s*분/);

      const sessionsPerWeek = sessionsPerWeekMatch ? parseInt(sessionsPerWeekMatch[1], 10) : null;
      const sessionDuration = sessionDurationMatch ? parseInt(sessionDurationMatch[1], 10) : null;

      const isSessionsPerWeekMatch = userMission && sessionsPerWeek !== null && userMission.getSessionsPerWeek() === sessionsPerWeek;
      const isSessionDurationMatch = userMission && sessionDuration !== null && userMission.getSessionDuration() === sessionDuration;

      const isBothMatch = sessionsPerWeek !== null && sessionDuration !== null
        ? isSessionsPerWeekMatch && isSessionDurationMatch
        : isSessionsPerWeekMatch || isSessionDurationMatch;

      const nameMatch = user.getFullname()?.toLowerCase().includes(lowerQuery);
      const usernameMatch = user.getUsername()?.toLowerCase().includes(lowerQuery);
      const emailMatch = user.getEmail()?.toLowerCase().includes(lowerQuery);

      const primaryGroupMatch = user.getPrimaryGroup()?.name?.includes('학년') && lowerQuery.includes(user.getPrimaryGroup().name);
      const secondaryGroupMatch = user.getSecondaryGroup()?.name?.includes('반') && lowerQuery.includes(user.getSecondaryGroup().name);

      const compoundGroupMatch = lowerQuery.includes('학년') && lowerQuery.includes('반') &&
        lowerQuery.includes(user.getPrimaryGroup()?.name ?? '') &&
        lowerQuery.includes(user.getSecondaryGroup()?.name ?? '');

      const dateMatch = this.dateToString(user.getLastWorkoutDate()).includes(lowerQuery);

      const fitnessLevelMatch = lowerQuery.includes(user.getFitnessLevel()?.toString()) && lowerQuery.includes('등급');
      const workoutMatch = user.getMostFrequentWorkout()?.includes(lowerQuery);

      const missionStatusMatch = (lowerQuery === 'ok' && user.getMissionStatus() === UserMissionStatus.OK) ||
        (lowerQuery === 'bad' && user.getMissionStatus() === UserMissionStatus.Bad);

      return nameMatch ||
        usernameMatch ||
        emailMatch ||
        primaryGroupMatch ||
        secondaryGroupMatch ||
        compoundGroupMatch ||
        dateMatch ||
        fitnessLevelMatch ||
        workoutMatch ||
        missionStatusMatch ||
        isBothMatch;
    });

    if (!this.filteredUserList.length) {
      this.searchResultsNotFound = true;
      this.searchPerformed = true;
      return;
    }

    this.dataSource.data = this.filteredUserList;
    this.searchPerformed = true;
    this.searchResultsNotFound = false;

    this.page = 1; // reset to first page
    this.updatePaginatedList();
  }

  dateToString(date: Date): string {
    // check if the date is null, undefined, or invalid
    if (!date || isNaN(date.getTime())) {
      return '--';
    }

    return this.datePipe.transform(date, 'yyyy.MM.dd') || '';
  }

  /**
   * Method to render / hide the 'X' reset button if input field is non-empty / empty.
   */
  onInputChange(): void {
    this.resetBtnEl.nativeElement.style.visibility = this.searchInputEl.nativeElement.value.length > 0 ? 'visible' : 'hidden';
  }

  /** 
   * Method to change pagination paging size for ClassUserList.
   * @param event dropdown option selection event
   */
  onPageSizeChange(event: any): void {
    this.pageSize = event.target.value; // update page size
    this.page = 1; // reset to first page
    this.updatePaginatedList(); // update paginated data
  }

  /**
   * Method to reset pending user list to its defaults when the reset button is pressed.
   */
  resetUserList(): void {
    this.resetSearchInput();

    if (!this.searchPerformed) {
      return;
    }

    this.buildUserList(); // rebuild ClassUserList after searching
    this.dataSource.data = this.filteredUserList;

    this.sortData('fullname');
    this.userSortDirection === 'desc' ? this.userSortDirection = 'asc' : this.userSortDirection = 'asc';

    this.searchPerformed = false;

    this.cdRef.detectChanges();
  }

  /** Method to reset the search bar to an empty field when the reset button is pressed. */
  resetSearchInput(): void {
    if (this.searchResultsNotFound) {
      this.searchResultsNotFound = false;
    }

    if (this.searchInputEl.nativeElement.value.length > 0) {
      this.searchInputEl.nativeElement.value = '';
    }

    if (this.resetBtnEl.nativeElement.style.visibility === 'visible') {
      this.resetBtnEl.nativeElement.style.visibility = 'hidden';
    }
  }

  /**
   * Method to handle changes to missions via children (AddMissions/EditMissions)
   */
  handleMissionChanges(): void {
    this.deselectCandidates();
    this.processUser();
  }

  isActiveMission(element: any): boolean {
    const userId = element.userId;
    const currentMissionForUser = this.classMissionMap.get(userId);

    if (!currentMissionForUser) {
      return false;
    }

    const today = new Date();
    const startDate = new Date(currentMissionForUser.getStartDate());
    const endDate = new Date(currentMissionForUser.getEndDate());
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    // check if today's date is within the start and end dates
    return startDate <= today && today <= endDate;
  }

  /**
   * Method to emit the toggleRemoveUsers event.
   */
  onToggleRemoveUsers(): void {
    this.toggleRemoveUsersEvent.emit();
  }

  /**
   * Method to emit the toggleAddUsers event.
   */
  onToggleAddUsers(): void {
    this.toggleAddUsersEvent.emit();
  }

  /**
   * Method to emit the toggleEditClass event.
   */
  onToggleEditClass(): void {
    this.deselectCandidates();
    this.toggleEditClassEvent.emit();
  }

  /**
   * Method to emit the toggleAdMissions event.
   */
  onToggleAddMissions(): void {
    this.toggleAddMissionsEvent.emit();
  }

  toggleEditClass(): void {
    if (this.classManageMode === ClassManageMode.ViewAll) {
      this.classManageMode = ClassManageMode.EditClass;
      this.toggleEditClassEvent.emit();
      this.scrollTop(635);
    } else {
      this.classManageMode = ClassManageMode.ViewAll;
      this.scrollTop(635);
    }
  }

  /**
   * Method to emit the toggleViewUserData event.
   */
  onToggleViewTracking(rowData: any): void {
    const userId = rowData.userId;
    const userFullname = rowData.fullname;
    this.clsSelectionService.setUserId(userId);
    this.clsSelectionService.setUserFullname(userFullname);
    this.classManageMode = ClassManageMode.ViewTracking;
    this.activeModal = true;
  }

  /**
   * Method to emit the toggleViewUserMissions event.
   */
  onToggleViewMissions(rowData: any): void {
    const userId = rowData.userId;
    const userFullname = rowData.fullname;

    this.clsSelectionService.setSelectedClass(this.selectedClass);
    this.clsSelectionService.setUserId(userId);
    this.clsSelectionService.setUserFullname(userFullname);

    this.classManageMode = ClassManageMode.ViewMissions;
    this.activeModal = true;
  }

  /**
   * Helper method to generate a FormControl for input into DaysOfWeekPickerComponent.
   * @param days mission.getSessionDays() for some ClassMission mission
   * @returns a new FormControl encapsulating the value of days
   */
  createFormControl(userId: number): FormControl {
    const mission = this.classMissionMap.get(userId);
    return new FormControl(mission.getSessionDays());
  }

  /**
   * Method to scroll to the top with an offset (in pixels)
   * @param offset top offset in pixels
   */
  scrollTop(offset: number): void {
    window.scrollTo({ top: offset, behavior: 'smooth' }); // scroll to top smoothly
  }
}
