import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { DayOfWeek } from 'src/app/shared/models/ClassInfo';
import { ClassSelectionService } from '../../../../../shared/services/class-selection-service';
import { FormGroup, Validators, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { DaysOfWeekPickerComponent } from '../../../days-of-week-picker/days-of-week-picker.component';
import { CUSTOM_DATE_FORMATS } from 'src/app/app.component';
import { CustomDateAdapter } from 'src/app/shared/CustomDateAdapter';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { ClassUser, UserMissionStatus } from 'src/app/shared/models/ClassUser';
import _ from 'lodash';
import { ClassMission } from 'src/app/shared/models/ClassMission';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { ClassCourse } from 'src/app/shared/models/ClassCourse';
import { ClassMissionService } from 'src/app/shared/services/class-mission.service';
import { Organization } from 'src/app/shared/models/Organization';
import { MissionManageMode } from '../add-missions.component';

@Component({
  selector: 'app-edit-missions',
  templateUrl: './edit-missions.component.html',
  styleUrls: ['./edit-missions.component.css',
    '../add-missions.component.css',
    '../../edit-class/edit-class.component.css',
    '../../participant-list/participant-list.component.css',
    '../../manage-classes.component.css',
    '../../view-tracking/view-tracking.component.css'
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ]
})
export class EditMissionsComponent implements OnInit {
  MissionManageMode = MissionManageMode; // for access in template
  missionManageMode: MissionManageMode = MissionManageMode.AddMissions; // 미션 추가 as the default mode
  dropdownOptions: string[] = ['미션추가', '미션수정'];
  currentDropdownOption: '미션추가' | '미션수정' = '미션수정';

  candidates: ClassUser[] = null;
  sortedCandidateName: string = '';
  // a map that contains the most recent and ongoing mission for a particular user.
  classMissionMap: Map<number, ClassMission> | null = null;

  // a map that contains conflicting (overlapping days and dates as newly created mission) missions for each user.
  conflictingMissionMap: Map<number, ClassMission[]> | null = null;
  // variable to track number of users with conflicting missions
  conflictingUserCount: number = 0;


  // an array that contains ALL missions for a particular class.
  missionsForClass: ClassMission[] = [];
  newMission: ClassMission = null;

  org: Organization = null;
  selectedClass: ClassCourse = null;

  /* AddMissionsList */
  // columns for user list
  modalDisplayedColumns: string[] = ['index', 'name', 'id', 'missions', 'paps', 'freqWorkout', 'lastWorkoutDate', 'missionStatus'];
  modalDataSource = new MatTableDataSource<ClassUser>([]);

  modalPage: number = 1; // current page in modal
  modalPageSize: number = 5; // number of items per page in modal
  modalPageSizeOptions: number[] = [5, 10, 20, 30];

  // Arrays to hold AddMissionsList for currently selected class
  addMissionsList: ClassUser[] = [];
  paginatedAddMissionsList: ClassUser[] = [];

  activeModalColumn: string = 'name';
  addUserSortDirection: 'asc' | 'desc' = 'asc';

  // ViewChild decorator for DayPicker
  @ViewChild(DaysOfWeekPickerComponent) dayPicker: DaysOfWeekPickerComponent; // used for 요일 지정 in AddMissions view

  // form controls
  registerForm: FormGroup;

  selectedClassDays: DayOfWeek[] = [];
  selectedStartDate: string;
  selectedEndDate: string;

  loading: boolean = false;
  loadingError: boolean = false;

  submissionValid: boolean = true;
  cannotProceedDueToConflicts: boolean = false;
  maxTextFieldLength: number = 150;
  someSessionDaysOverlap: boolean = false;
  someSessionDatesOverlap: boolean = false;

  @Output() toggleAddMissionsEvent = new EventEmitter<void>();
  @Output() toggleCloseModalEvent = new EventEmitter<void>();
  @Output() closeModalWithoutDeselectEvent = new EventEmitter<void>();
  @Output() formSubmitEvent = new EventEmitter<ClassMission>();

  constructor(
    private clsSelectionService: ClassSelectionService,
    private datePipe: DatePipe,
    private missionService: ClassMissionService
  ) { }

  // Booleans for toggling modals
  modalStates = {
    modal: false, step2Modal: false, step3Modal: false
  };

  // Getters and setters to activate different modals based on the clicked button
  get activeModal(): boolean {
    return this.modalStates?.modal || false;
  }

  set activeModal(value: boolean) {
    if (this.modalStates) {
      this.modalStates.modal = value;
    }
  }

  get activeStep2Modal(): boolean {
    return this.modalStates?.step2Modal || false;
  }

  set activeStep2Modal(value: boolean) {
    if (this.modalStates) {
      this.modalStates.step2Modal = value;
    }
  }

  get activeStep3Modal(): boolean {
    return this.modalStates?.step3Modal || false;
  }

  set activeStep3Modal(value: boolean) {
    if (this.modalStates) {
      this.modalStates.step3Modal = value;
    }
  }

  async ngOnInit(): Promise<void> {
    this.clsSelectionService.org$.subscribe(
      (org) => {
        //console.log(org);
        this.org = org;
      }
    );

    this.clsSelectionService.selectedClass$.subscribe(
      (cls) => {
        //console.log(cls);
        this.selectedClass = cls;
      }
    );

    this.clsSelectionService.candidates$.subscribe(
      (candidates) => {
        console.log(candidates);
        this.candidates = candidates;
      }
    );

    this.clsSelectionService.classMissionMap$.subscribe(
      (missions) => {
        //console.log(missions);
        this.classMissionMap = missions
      }
    );

    try {
      this.loading = true;
      this.missionsForClass = await this.missionService.getMissionsForClass(this.selectedClass.getClassId());
      this.loading = false;
      this.loadingError = false;
    } catch (error) {
      console.log('error fetching missions for class:', error);
      this.loading = false;
      this.loadingError = true;
    }

    this.buildAddMissionsList();
    this.sortModalData('fullname');

    this.activeModal = true;

    this.conflictingMissionMap = new Map<number, ClassMission[]>();

    this.initRegisterForm();
  }

  /**
   * Method to paginate the candidates list and mark the default column as active.
   */
  buildAddMissionsList(): void {
    // filter candidates to users with at least 1 mission assigned.
    // if user doesn't have any missions, there are no missions to edit.
    //console.log(this.missionsForClass);
    this.candidates = this.candidates.filter(user => this.missionsForClass.some(mission => mission.getUserId() === user.getUserId()));
    console.log(this.candidates);

    this.addMissionsList = [...this.candidates];
    this.paginatedAddMissionsList = this.addMissionsList;
    this.activeModalColumn = 'fullname';
    this.updatePaginatedAddList();
  }

  /** Functions to handle AddMissionsList pagination */
  updatePaginatedAddList(): void {
    const startIndex = (this.modalPage - 1) * this.modalPageSize;
    const endIndex = startIndex + this.modalPageSize;
    this.paginatedAddMissionsList = this.addMissionsList.slice(startIndex, endIndex);

    this.modalDataSource.data = [...this.paginatedAddMissionsList];
  }

  /** Method to toggle sorting function for a certain column in the AddUserList
   * @param column string value that represents the column to be sorted
   */
  toggleModalSort(column: string): void {
    if (this.activeModalColumn === column) {
      this.addUserSortDirection = this.addUserSortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.activeModalColumn = column;
      (this.activeModalColumn === 'lastWorkoutDate' || this.activeModalColumn === 'mission')
        ? this.addUserSortDirection = 'desc' : this.addUserSortDirection = 'asc';
    }

    this.sortModalData(column);
  }

  /**
   * Method that sorts the AddUserList based on a selected column.
   * @param column column to be sorted
   */
  sortModalData(column: string): void {
    // check if column is lastWorkoutDate
    if (column === 'lastWorkoutDate') {
      this.sortByLastWorkoutDate();
    } else if (column === 'mission') {
      this.sortByMission();
    } else if (column === 'missionStatus') {
      this.sortByMissionStatus();
    } else {
      this.sortAlNumStrings(column);
    }

    // update paginated AddUserList after sorting
    this.modalPage = 1;
    this.updatePaginatedAddList();
  }

  sortAlNumStrings(column: string): void {
    this.addMissionsList.sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      // handle other string columns
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        // split Korean/English part and number part
        const textPartA = valueA.match(/[^\d]+/g)?.[0] || ''; // text part (Korean / English)
        const numberPartA = parseInt(valueA.match(/\d+/g)?.[0] || '0', 10); // number part

        const textPartB = valueB.match(/[^\d]+/g)?.[0] || ''; // text part (Korean / English)
        const numberPartB = parseInt(valueB.match(/\d+/g)?.[0] || '0', 10); // number part

        // first, compare text part using localeCompare with support for both Korean and English
        const textCompare = textPartA.localeCompare(textPartB, 'ko-KR');
        if (textCompare != 0) {
          return this.addUserSortDirection === 'asc' ? textCompare : -textCompare;
        }

        // if text parts are equal, compare the number part numerically
        return this.addUserSortDirection === 'asc' ? numberPartA - numberPartB : numberPartB - numberPartA;
      }

      // fallback to standard comparison for non-string values
      if (this.addUserSortDirection === 'asc') {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    });
  }

  formatMissionPeriod(userId: number): string {
    const mission = this.classMissionMap.get(userId);

    if (!mission) {
      return '';
    }

    if (mission.getStartDate() && mission.getEndDate()) {
      const startDateString = this.dateToString(new Date(mission.getStartDate()));
      const endDateString = this.dateToString(new Date(mission.getEndDate()));

      if (startDateString !== '' && startDateString !== 'Invalid Date' && endDateString !== '' && endDateString !== 'Invalid Date') {
        return `${startDateString} ~ ${endDateString}`;
      }
    }

    return '';
  }

  formatMissionText(userId: number): string {
    const mission = this.classMissionMap.get(userId);

    if (!mission) {
      return '--';
    }

    return `주 ${mission.getSessionsPerWeek()}회 ${mission.getSessionDuration()}분`;
  }

  sortByMission(): void {
    this.addMissionsList.sort((a, b) => {
      const missionA = this.classMissionMap.get(a.getUserId());
      const missionB = this.classMissionMap.get(b.getUserId());

      // handle null or undefined missions explicitly
      if (!missionA && !missionB) {
        return 0; // both are null or undefined; considered equal
      } else if (!missionA) {
        return this.addUserSortDirection === 'asc' ? -1 : 1; // missionA is null/undefined; goes bottom in ascending order
      } else if (!missionB) {
        return this.addUserSortDirection === 'asc' ? -1 : 1 // missionB is null/undefined; goes bottom in ascending order
      }

      let valueA = missionA.getSessionsPerWeek();
      let valueB = missionB.getSessionsPerWeek();

      // compare by sessions per week first
      if (valueA !== valueB) {
        if (this.addUserSortDirection === 'asc') {
          return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        } else {
          return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
        }
      }

      // if sessions per week are the same, compare by session duration
      valueA = missionA.getSessionDuration();
      valueB = missionB.getSessionDuration();

      if (this.addUserSortDirection === 'asc') {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    });
  }

  sortByMissionStatus(): void {
    this.addMissionsList.sort((a, b) => {
      const missionStatusA = a.getMissionStatus();
      const levelUpA = a.isLevelUp();
      const missionStatusB = b.getMissionStatus();
      const levelUpB = b.isLevelUp();

      const sortDirection = this.addUserSortDirection === 'asc' ? 1 : -1;

      if (missionStatusA !== missionStatusB) {
        return (missionStatusA === UserMissionStatus.OK ? -1 : 1) * sortDirection;
      }

      if (levelUpA !== levelUpB) {
        return (levelUpA === true ? -1 : 1) * sortDirection;
      }

      return 0;
    });
  }

  sortByLastWorkoutDate(): void {
    this.addMissionsList.sort((a, b) => {
      let valueA = a['lastWorkoutDate'];
      let valueB = b['lastWorkoutDate'];

      // handle valid dates
      const dateA = valueA;
      const dateB = valueB;

      // compare the dates
      if (this.addUserSortDirection === 'asc') {
        return dateA.getTime() - dateB.getTime(); // ascending order
      } else {
        return dateB.getTime() - dateA.getTime(); // descending order
      }
    });
  }

  /**
 * Method to change content type filter option.
 * @param selectedOption the selected option from the button bar
 */
  onDropdownOptionChange(selectedOption: '미션추가' | '미션수정'): void {
    this.currentDropdownOption = selectedOption; // update content value
    switch (this.currentDropdownOption) {
      case '미션추가':
        this.missionManageMode = MissionManageMode.AddMissions;
        this.closeModalWithoutDeselectEvent.emit(); // IMPORTANT: close currently open modal first
        this.toggleAddMissionsEvent.emit();
        break;
      case '미션수정':
        this.missionManageMode = MissionManageMode.EditMissions;
        break;
      default:
        this.missionManageMode = MissionManageMode.AddMissions;
        break;
    }
  }

  onModalPageChange(newPage: number): void {
    this.modalPage = newPage;
    this.updatePaginatedAddList();
  }

  /** 
   * Method to change pagination paging size for AddUserList.
   * @param event dropdown option selection event
   */
  onModalPageSizeChange(event: any): void {
    this.modalPageSize = event.target.value; // update page size
    this.modalPage = 1; // reset to first page
    this.updatePaginatedAddList();
  }

  /** Method to retrieve the first fullname of candidates sorted in ascending order
   */
  updateSortedCandidates(): void {
    this.candidates.sort((a, b) =>
      a.getFullname().localeCompare(b.getFullname(), 'ko-KR'));
    this.sortedCandidateName = this.candidates[0]?.getFullname() || '';
  }

  gotoStep2(): void {
    this.activeStep2Modal = true;
    this.activeModal = false;

    this.updateSortedCandidates(); // sort candidate list by name in ascending order and retrieve the first element
  }

  gotoConfirm(): void {
    this.activeStep2Modal = false;
    this.activeStep3Modal = true;
  }

  /**
   * sessionsPerWeek, sessionDuration, sessionDays, missionGoal, startDate, endDate
   */
  initRegisterForm(): void {
    const today = new Date();
    const oneWeekAfterToday = new Date(today);
    oneWeekAfterToday.setDate(today.getDate() + 7);
    const weekdays = [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday];

    this.registerForm = new FormGroup({
      startDate: new FormControl(this.formatDateWithLocalMidnight(today), [Validators.required, this.dateValidator()]),
      endDate: new FormControl(this.formatDateWithLocalMidnight(oneWeekAfterToday), [Validators.required, this.dateValidator]),
      sessionDuration: new FormControl(60, [Validators.required, Validators.min(5), Validators.max(60 * 24)]),
      sessionDays: new FormControl(weekdays, [this.enumValidator(DayOfWeek)]),
      sessionsPerWeek: new FormControl({ value: '', disabled: true }),
      missionGoal: new FormControl('', [Validators.required, Validators.maxLength(this.maxTextFieldLength)])
    }, {
      validators: [this.startBeforeEndDateValidator()]
    });

    this.updateSessionsPerWeek();
    this.checkMissionConflicts();

    // subscribe to valueChanges and invoke checkMissionConflicts
    this.registerForm.get('sessionDays')?.valueChanges.subscribe(() => {
      this.updateSessionsPerWeek();
      this.checkMissionConflicts();
    });


    this.registerForm.get('startDate')?.valueChanges.subscribe(() => {
      this.updateSessionsPerWeek();
      this.checkMissionConflicts();
    });

    this.registerForm.get('endDate')?.valueChanges.subscribe(() => {
      this.updateSessionsPerWeek();
      this.checkMissionConflicts();
    });
  }

  get rf() { return this.registerForm.controls; }

  /**
   * Method to format a date in 'YYYY-MM-DD' string with local midnight time.
   * @param date Date object
   */
  formatDateWithLocalMidnight(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  /**
   * Custom method to handle date change from DatePickers
   * @param controlName 
   * @param value 
   */
  onDateChange(controlName: string, value: Date): void {
    if (value instanceof Date && !isNaN(value.getTime())) {
      if (controlName === 'startDate') {
        this.registerForm.get('startDate')?.setValue(this.formatDateWithLocalMidnight(value));
        //console.log(this.registerForm.get('startDate')?.value);
      } else if (controlName === 'endDate') {
        this.registerForm.get('endDate')?.setValue(this.formatDateWithLocalMidnight(value));
        //console.log(this.registerForm.get('endDate')?.value);
      }

      this.updateSessionsPerWeek();
    }
  }

  /**
   * Method to update the selected days of the week from DaysOfWeekPickerComponent
   * @param value selected days of the week
   */
  onSessionDaysChange(value: DayOfWeek[]): void {
    this.selectedClassDays = value;

    this.updateSessionsPerWeek();
  }

  /**
 * Checks for conflicts between users' existing missions and the new mission's session dates and days.
 * Populates both conflictingMissionMap and equalMissionMap.
 * Displays an error message with the conflicting dates and days for affected rows.
 */
  checkMissionConflicts(): void {
    const newMission = {
      startDate: this.registerForm.get('startDate')?.value,
      endDate: this.registerForm.get('endDate')?.value,
      sessionDays: this.registerForm.get('sessionDays')?.value,
    };

    // Reset counts and maps
    this.conflictingUserCount = 0;
    this.conflictingMissionMap.clear();

    // Iterate over existing missions
    this.missionsForClass.forEach(mission => {
      const existingStartDate = new Date(mission.getStartDate());
      existingStartDate.setHours(0, 0, 0, 0);
      const newStartDate = new Date(newMission.startDate);
      newStartDate.setHours(0, 0, 0, 0);

      const existingEndDate = new Date(mission.getEndDate());
      existingEndDate.setHours(23, 59, 59, 999);
      const newEndDate = new Date(newMission.endDate);
      newEndDate.setHours(23, 59, 59, 999);

      const identicalDates =
        existingStartDate.getTime() === newStartDate.getTime() &&
        existingEndDate.getTime() === newEndDate.getTime();

      const identicalDays = JSON.stringify(mission.getSessionDays().sort()) === JSON.stringify(newMission.sessionDays.sort());

      const datesOverlap =
        new Date(mission.getStartDate()) <= new Date(newMission.endDate) &&
        new Date(mission.getEndDate()) >= new Date(newMission.startDate);

      const daysOverlap = mission.getSessionDays().some((day: DayOfWeek) =>
        newMission.sessionDays.includes(day)
      );

      const userId = mission.getUserId();

      if (datesOverlap && daysOverlap) {
        // Overlapping dates and days, treat as conflict
        if (!this.conflictingMissionMap.has(userId)) {
          this.conflictingMissionMap.set(userId, []);
        }
        this.conflictingMissionMap.get(userId)!.push(mission);
      }
    });

    // Update conflicting user count
    this.conflictingUserCount = this.candidates.filter(candidate =>
      this.conflictingMissionMap.has(candidate.getUserId())
    ).length;

    // Check if conflicts prevent proceeding
    const allCandidatesHaveConflicts = this.candidates.every(candidate =>
      this.conflictingMissionMap.has(candidate.getUserId())
    );
    const singleCandidateWithConflict =
      this.candidates.length === 1 && allCandidatesHaveConflicts;

    this.cannotProceedDueToConflicts = singleCandidateWithConflict || allCandidatesHaveConflicts;
  }

  /**
 * Method to get the start and end dates of the first overlapping mission for a candidate.
 * @param candidate The candidate whose mission period is being retrieved.
 * @returns An object containing the start and end dates.
 */
  getMissionPeriod(candidate: any): { startDate: string; endDate: string } {
    const mission = this.missionsForClass.find(m =>
      new Date(m.getStartDate()) <= new Date(candidate.getLastWorkoutDate()) &&
      new Date(m.getEndDate()) >= new Date(candidate.getLastWorkoutDate())
    );
    return mission
      ? { startDate: this.dateToString(new Date(mission.getStartDate())), endDate: this.dateToString(new Date(mission.getEndDate())) }
      : { startDate: '', endDate: '' };
  }

  /**
  * Method to get formatted conflicting missions for a user.
  * @param userId The ID of the user whose conflicting missions are to be retrieved.
  * @returns An array of formatted strings representing each conflicting mission.
  */
  getFormattedConflictingMissions(userId: number): string[] {
    const missions = this.conflictingMissionMap.get(userId);
    if (!missions) {
      return [];
    }

    return missions.map(mission => {
      const startDate = this.dateToString(new Date(mission.getStartDate()));
      const endDate = this.dateToString(new Date(mission.getEndDate()));
      const days = mission
        .getSessionDays()
        .map(day => this.dayToKorean(day))
        .join(', ');
      return `${startDate} ~ ${endDate}: ${days}`;
    });
  }

  /**
   * Utility to convert a day of the week to its Korean label.
   * @param day Day of the week as a number.
   * @returns The corresponding Korean label for the day.
   */
  dayToKorean(day: DayOfWeek): string {
    switch (day) {
      case 0: return '일';
      case 1: return '월';
      case 2: return '화';
      case 3: return '수';
      case 4: return '목';
      case 5: return '금';
      case 6: return '토';
      default: return '';
    }
  }

  /**
   * Custom validator for date format.
   * @returns ValidatorFn to check for valid date formats
   */
  dateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (!value) {
        return { invalidDate: true };
      }

      return null; // valid date
    };
  }

  /**
   * Custom validator to check if start date is before end date.
   * @returns ValidatorFn to check if endDate comes after startDate
   */
  startBeforeEndDateValidator(): ValidatorFn {
    return (group: AbstractControl): { [key: string]: any } | null => {
      const startDate = group.get('startDate')?.value;
      const endDate = group.get('endDate')?.value;

      if (startDate && endDate) {
        const start = new Date(startDate);
        start.setHours(0, 0, 0, 0);
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 59);

        if (start > end) {
          return { startDateAfterEndDate: true };
        }
      }
      return null;
    };
  }

  /**
   * Custom validator to check for enum values
   * @param enumType any enum value to be checked
   * @returns ValidatorFn to check for valid enum values
   */
  enumValidator(enumType: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null; // let Validators.required handle empty value
      }

      // get numeric values of the enum
      const enumValues = Object.keys(enumType)
        .filter(key => !isNaN(Number(key)))
        .map(key => Number(key));

      // if control value is an array, check if all elements are in the enum
      if (Array.isArray(control.value)) {
        const isValidArray = control.value.every(value => enumValues.includes(value));
        return isValidArray ? null : { invalidEnumValue: true };
      }

      // check single value
      return enumValues.includes(control.value) ? null : { invalidEnumValue: true };
    };
  }

  /**
   * Method to update sessions per week based on selected days.
   */
  updateSessionsPerWeek(): void {
    let selectedDays: DayOfWeek[] = this.registerForm.get('sessionDays')?.value || [];
    if (selectedDays.length === 0) {
      selectedDays = [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday, DayOfWeek.Saturday, DayOfWeek.Sunday];
    }
    this.registerForm.get('sessionsPerWeek')?.setValue(selectedDays.length);
  }

  /**
  * Handles the submission of new missions for candidates.
  * Validates the form, checks for conflicts, and creates or updates missions as necessary.
  */
  async onSubmit(): Promise<boolean> {
    if (this.registerForm.pristine) {
      return false;
    }

    this.registerForm.markAllAsTouched();

    if (this.registerForm.invalid) {
      console.log('Form is invalid. Please fix the errors and try again.');
      return false;
    }

    // Initialize the new mission object
    this.newMission = new ClassMission(
      null,
      null,
      this.selectedClass.getClassId(),
      this.org.getId(),
      0,
      [],
      0,
      this.registerForm.get('sessionsPerWeek')?.value,
      this.registerForm.get('sessionDuration')?.value,
      this.registerForm.get('sessionDays')?.value,
      new Date(this.registerForm.get('startDate')?.value),
      new Date(this.registerForm.get('endDate')?.value),
      this.registerForm.get('missionGoal')?.value
    );

    try {
      for (const user of this.candidates) {
        const userId = user.getUserId();
        this.newMission.setUserId(userId);

        /**
         * // Find all missions with identical date ranges
        const exactMatches = this.missionsForClass.filter(mission =>
          mission.getStartDate().getTime() === this.newMission.getStartDate().getTime() &&
          mission.getEndDate().getTime() === this.newMission.getEndDate().getTime()
        );
    
        if (exactMatches.length > 0) {
          console.log(`Exact matches found for user ${userId}. Updating all matching missions.`);
          for (const exactMatch of exactMatches) {
            this.newMission.setMissionId(exactMatch.getMissionId());
            await this.missionService.saveMission(this.newMission);
          }
          continue;
        }

         */

        // Handle conflicts using `conflictingMissionMap`
        const conflicts = this.conflictingMissionMap.get(userId) || [];
        if (conflicts.length > 0) {
          console.log(`Conflicts exist for user ${userId}. Skipping.`);
          continue;
        }

        // No conflicts and no exact match, create a new mission
        console.log(`Modifying existing mission for user ${userId}.`);
        await this.missionService.createMission(this.newMission);
      }

      this.submissionValid = true;
      this.formSubmitEvent.emit(); // notify parent (ParticipantListComponent) of mission creation to update view
      console.log('Missions successfully submitted.');
    } catch (error) {
      this.submissionValid = false;
      console.error('Error submitting missions:', error);
    }

    this.gotoConfirm();
    this.toggleAddMissionsEvent.emit();

    return this.submissionValid;
  }

  isActiveMission(element: any): boolean {
    const userId = element.userId;
    const currentMissionForUser = this.classMissionMap.get(userId);

    if (!currentMissionForUser) {
      return false;
    }

    const today = new Date();
    const startDate = new Date(currentMissionForUser.getStartDate());
    const endDate = new Date(currentMissionForUser.getEndDate());
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    // check if today's date is within the start and end dates
    return startDate <= today && today <= endDate;
  }

  addMissions(): void {
    this.classMissionMap.forEach(
      (mission, userId) => {
        this.missionService.saveMission(mission);
      }
    )
  }

  onToggleAddMissions(): void {
    this.toggleAddMissionsEvent.emit();
  }

  onToggleCloseModal(): void {
    this.toggleCloseModalEvent.emit();
  }

  dateToString(date: Date): string {
    // check if the date is null, undefined, or invalid
    if (!date || isNaN(date.getTime())) {
      return '--';
    }

    return this.datePipe.transform(date, 'yyyy.MM.dd') || '';
  }

  /**
   * Helper method to generate a FormControl for input into DaysOfWeekPickerComponent.
   * @param days mission.getSessionDays() for some ClassMission mission
   * @returns a new FormControl encapsulating the value of days
   */
  createFormControl(userId: number): FormControl {
    const mission = this.classMissionMap.get(userId);
    return new FormControl(mission.getSessionDays());
  }

  /**
   * Method to scroll to the top with an offset (in pixels)
   * @param offset top offset in pixels
   */
  scrollTop(offset: number): void {
    window.scrollTo({ top: offset, behavior: 'smooth' }); // scroll to top smoothly
  }
}
