import { ClassCourse } from "./ClassCourse";
import { OrgAdmin } from "./OrgAdmin";

export enum DayOfWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday
}

export type TimeRange = {
    start: string;
    end: string;
}

export type DateRange = {
    start: Date;
    end: Date;
};

export class ClassInfo {
    public classId: number;
    private subInstructorName: string;
    private classGoal: string;
    private classTarget: string;
    private classLocation: string;
    private sessionsPerWeek: number;
    private sessionDuration: number;
    private approxCostPerSession: number;
    private classDays: DayOfWeek[]
    private classTimes: TimeRange
    private requiredMaterials: string;
    private classContact: string;

    constructor(
        classId: number,
        subInstructorName: string,
        classGoal: string, 
        classTarget: string, 
        classLocation: string,
        sessionsPerWeek: number, 
        sessionDuration: number, 
        approxCostPerSession: number,
        classDays: DayOfWeek[],
        classTimes: TimeRange,
        requiredMaterials: string,
        classContact: string) {
            this.classId = classId;
            this.subInstructorName = subInstructorName;
            this.classGoal = classGoal;
            this.classTarget = classTarget;
            this.classLocation = classLocation;
            this.sessionsPerWeek = sessionsPerWeek;
            this.sessionDuration = sessionDuration;
            this.approxCostPerSession = approxCostPerSession;
            this.classDays = classDays;
            this.classTimes = classTimes;
            this.requiredMaterials = requiredMaterials;
            this.classContact = classContact;
    }

    public getClassId(): number {
        return this.classId;
    }

    public getSubInstructorName(): string {
        return this.subInstructorName;
    }

    public getClassGoal(): string {
        return this.classGoal;
    }

    public getClassTarget(): string {
        return this.classTarget;
    }

    public getClassLocation(): string {
        return this.classLocation;
    }

    public getSessionsPerWeek(): number {
        return this.sessionsPerWeek;
    }

    public getSessionDuration(): number {
        return this.sessionDuration;
    }

    public getApproxCostPerSession(): number {
        return this.approxCostPerSession;
    }

    public getClassDays(): DayOfWeek[] {
        return this.classDays;
    }

    public getClassTimes(): TimeRange {
        return this.classTimes;
    }

    public getRequiredMaterials(): string {
        return this.requiredMaterials;
    }

    public getClassContact(): string {
        return this.classContact;
    }

    public setClassId(classId: number): void {
        this.classId = classId;
    }

    public setSubInstructorName(subInstructorName: string): void {
        this.subInstructorName = subInstructorName;
    }

    public setClassGoal(classGoal: string) {
        this.classGoal = classGoal;
    }

    public setClassTarget(classTarget: string) {
        this.classTarget = classTarget;
    }

    public setClassLocation(classLocation: string) {
        this.classLocation = classLocation;
    }

    public setSessionsPerWeek(sessionsPerWeek: number) {
        this.sessionsPerWeek = sessionsPerWeek;
    }

    public setSessionDuration(sessionDuration: number) {
        this.sessionDuration = sessionDuration;
    }

    public setApproxCostPerSession(approxCostPerSession: number) {
        this.approxCostPerSession = approxCostPerSession;
    }
    
    public setClassDays(classDays: DayOfWeek[]) {
        this.classDays = classDays.sort();
    }

    public setClassTimes(classTimes: TimeRange) {
        this.classTimes = classTimes;
    }

    public setRequiredMaterials(requiredMaterials: string) {
        this.requiredMaterials = requiredMaterials;
    }

    public setClassContact(classContact: string) {
        this.classContact = classContact;
    }
}
