import { Component, Input, OnInit } from "@angular/core";
import { RegularUserService } from "src/app/shared/services/regular-user.service";
import { TrackingService } from "src/app/shared/services/tracking.service";
import { OrganizationService } from "src/app/shared/services/organization.service";
import { ClassCourseService } from "src/app/shared/services/class-course.service";
import { ClassSelectionService } from "src/app/shared/services/class-selection-service";
import _ from 'lodash-es';
import { ClassCourse } from "src/app/shared/models/ClassCourse";

@Component({
    selector: "app-class-progress",
    templateUrl:"./class-progress.component.html",
    styleUrls: ["./class-progress.component.css", "../manage-classes.component.css"]
})
export class ClassProgressComponent implements OnInit {
  currentClass: ClassCourse = null;
  classId: number = 0;
  classUserCount: number = 0;
  totalWorkoutTime: number = 0;
  totalWeightLoss: number = 0;
  totalWorkoutDays: number = 0;
  
  @Input() set triggerFetch(value: boolean) {
    if (value) {
      this.fetchClassProgress();
    }
  }

  constructor(
    private trackingService: TrackingService,
    private clsSelectionService: ClassSelectionService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchClassProgress();
  }
  
  async fetchClassProgress(): Promise<void> {
    this.clsSelectionService.selectedClass$.subscribe((cls) => (this.currentClass = cls));
    this.classId = this.currentClass.getClassId();
    this.classUserCount = this.currentClass.getParticipantList().length;
  
    try {
      const totalWorkoutTimeRaw = await this.trackingService.getClassTotalWorkoutTime(this.classId) / (60 * 60);
      this.totalWorkoutTime = _.round(totalWorkoutTimeRaw, 1);
    } catch (error) {
      console.log('error fetching total workout time for class:', error);
      this.totalWorkoutTime = 0;
    }
  
    try {
      this.totalWeightLoss = (await this.trackingService.getClassTotalWeightLoss(this.classId)) / 100;
    } catch (error) {
      console.log('error fetching total weight loss amount for class:', error);
      this.totalWeightLoss = 0;
    }
  
    try {
      this.totalWorkoutDays = await this.trackingService.getClassTotalWorkoutDays(this.classId);
    } catch (error) {
      console.log('error fetching total workout days for class:', error);
    }
  }  
}
