import { Component, OnInit, ViewChild, Inject, Input, QueryList, ViewChildren, Output, EventEmitter, ChangeDetectionStrategy, TemplateRef, ChangeDetectorRef, Injectable, Pipe, PipeTransform, LOCALE_ID } from '@angular/core';
import {Location} from '@angular/common'; 
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import * as TBM_ROLE from 'src/app/shared';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarView, CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarDateFormatter, DateFormatterParams, CalendarEventTitleFormatter } from 'angular-calendar';
import { Subject, Subscription } from 'rxjs';
import { addHours, isSameMonth, isSameDay } from 'date-fns';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BoardService } from 'src/app/shared/services/board.service';

export interface NewsFeeds {
  id: number;
  title: string;
  body: string;
  cc: number;
  createDate: string;
  pin: boolean;
}
export interface Resources {
  id: number;
  title: string;
  body: string;
  cc: number;
  createDate: string;
  type: number;
}

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.css']
})
export class CommunityComponent implements OnInit {

  config: any = {
    height: '200px',
    fontsize: '20',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
      // ['customButtons', ['testBtn']]
    ],
    // buttons: {
    //   'testBtn': this.customButton()
    // }
  };

  addNewsFeedInfo;
  newsfeeds : NewsFeeds[];
  newsfeedDisplayedColumns: string[] = ['title', 'cc', 'createDate' ]; // No body
  newsfeedDataSource;
  newsfeedForm: FormGroup;

  addResInfo;
  resources : NewsFeeds[];
  resDisplayedColumns: string[] = ['title', 'cc', 'createDate' ]; // no 'answer',
  resDataSource;
  resForm: FormGroup;

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  // @ViewChild(MatPaginator, {static: true}) nfpaginator: MatPaginator;
  // @ViewChild(MatPaginator, {static: true}) respaginator: MatPaginator;
  // @ViewChild('nfPaginator') nfPaginator: MatPaginator;
  // @ViewChild(MatSort, {static: true}) sort: MatSort;

  routeType = null;
  routeId = null;
  userrole;

  bodyLoading = false;
  subscription: Subscription; 

  constructor(
    private boardService: BoardService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    public tr: TranslateService,
    public route: ActivatedRoute,
    public router: Router,
    private location: Location
  ) {
  }

  ngOnInit() {
    // this.routeType = this.route.snapshot.paramMap.get('type');
    this.route.params.subscribe(
      params => {
        this.routeType = params['type'];
        // const id = +params['id'];
        this.loadData();
      }
    );

    this.scrollTop(0);
  }

  loadData() {
    // let routeId = this.route.snapshot.paramMap.get('id');
    // console.log('routeType', this.routeType);
    if( this.routeType === null || this.routeType === undefined ){
      this.location.go('/community;type=nf');
      this.routeType = "nf";
    }
    if( this.routeType === "nf" ){
      this.loadNf();
    } else if ( this.routeType === "re" ) {
      this.loadRes();
    } else {
      alert("Invalid community page");
      this.router.navigate(['/home']);
    }

    this.scrollTop(0);
  }

  loadNf() {
    this.addNewsFeedInfo = {
      title: '',
      body: ''
    }
    this.newsfeeds = [];
    this.subscription = this.boardService.getNoticeList().subscribe(
      data => {
        // console.log(data);
        data.forEach(element => {
          this.newsfeeds.push({
            id: element.id,
            title: element.title,
            // body: element.body,
            body: '',
            cc: element.click_count,
            createDate: this.datePipe.transform(element.create_date, 'YY년 MM월 dd일'),
            pin: element.pin
          });
        });
        this.newsfeedDataSource = new MatTableDataSource<NewsFeeds>(this.newsfeeds);
        this.newsfeedDataSource.paginator = this.paginator.toArray()[0];
      }, error => { }, () => {
        // this.selected.content_id = +this.route.snapshot.paramMap.get('id');
        // console.log(this.route.snapshot.paramMap.get('type'));
        if( this.route.snapshot.paramMap.get('type') === "nf" ){
          let checkId = +this.route.snapshot.paramMap.get('id')
          for( let i = 0; i < this.newsfeeds.length; i ++ ){
            if ( this.newsfeeds[i].id === checkId ){
              this.openViewer('nf', this.newsfeeds[i]);
              break;
            }
          }
        }
      }
    );
    this.newsfeedForm = new FormGroup({
      nfFormData: new FormControl()
    });
  }

  loadRes() {

    this.addResInfo = {
      title: '',
      body: ''
    }
    this.resources = [];
    this.subscription = this.boardService.getResourcesList().subscribe(
      data => {
        data.forEach(element => {
          this.resources.push({
            id: element.id,
            title: element.title,
            // body: element.body,
            body: '',
            cc: element.click_count,
            createDate: this.datePipe.transform(element.create_date, 'YY년 MM월 dd일'),
            pin: element.pin
          });
        });
        this.resDataSource = new MatTableDataSource<NewsFeeds>(this.resources);
        this.resDataSource.paginator = this.paginator.toArray()[0];
      }, error => { }, () => {
        // this.selected.content_id = +this.route.snapshot.paramMap.get('id');
        // console.log(this.route.snapshot.paramMap.get('type'));
        if( this.route.snapshot.paramMap.get('type') === "re" ){
          let checkId = +this.route.snapshot.paramMap.get('id')
          for( let i = 0; i < this.resources.length; i ++ ){
            if ( this.resources[i].id === checkId ){
              this.openViewer('re', this.resources[i]);
              break;
            }
          }
        }
      }
    );
    this.resForm = new FormGroup({
      resFormData: new FormControl()
    });
  }

  nav(to: string) {
    this.close();
    this.location.go('community;type='+to);
    this.router.navigate(['community/',{type: to}]);
    this.scrollTop(0);
  }

  applyNewsfeedFilter(filterValue: string) {
    this.newsfeedDataSource.filter = filterValue.trim().toLowerCase();
    if (this.newsfeedDataSource.paginator) {
      this.newsfeedDataSource.paginator.firstPage();
    }
  }

  applyResFilter(filterValue: string) {
    this.resDataSource.filter = filterValue.trim().toLowerCase();
    if (this.resDataSource.paginator) {
      this.resDataSource.paginator.firstPage();
    }
  }

  sendinfo = {
    send: '',
    title: '',
    body: '',
  }

  openViewer(type, info) {
    // type is nf or faq
    this.location.go('community;type='+type+';id='+info.id);
    if ( type === 'nf' ){
      this.sendinfo.send = type;
      this.sendinfo.title = info.title;
      this.sendinfo.body = info.body;
      if ( info.body === "" ) {
        this.bodyLoading = true;
        this.subscription.unsubscribe();
        this.subscription = this.boardService.getNoticeRetrieve(info.id).subscribe(
          data => {
            this.sendinfo.body = data.content;
          }, error => { }
        );
      } else {
        this.bodyLoading = false;
      }
      this.boardService.addNoticeClick(info.id).subscribe(
        data => {}, error => {}
      );
    } else if ( type ==='re' ){
      this.sendinfo.send = type;
      this.sendinfo.title = info.title;
      this.sendinfo.body = info.body;
      if ( info.body === "" ) {
        this.bodyLoading = true;
        this.subscription.unsubscribe();
        this.subscription = this.boardService.getResourcesRetrieve(info.id).subscribe(
          data => {
            this.sendinfo.body = data.content;
          }, error => { }
        );
      } else { 
        this.bodyLoading = false;
      }
      this.boardService.addResourcesClick(info.id).subscribe(
        data => {}, error => {}
      );
    } else {
      // error
    }
    
    this.scrollTop(0);
  }

  close(){
    this.sendinfo = {
      send: '',
      title: '',
      body: '',
    }
  }

  /**
   * function to scroll to the top with an offset (in pixels)
   * @param offset top offset in pixels
   */
  scrollTop(offset: number): void {
    window.scrollTo({ top: offset, behavior: 'smooth' }); // scroll to top smoothly
  }
}

@Component({
  selector: 'app-community-viewer',
  templateUrl: './community-viewer.component.html',
  styleUrls: ['./community.component.css']
})
export class CommunityViewerComponent {
  @Input() title: string;
  @Input() body: string;
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  constructor(){}

  closeViewer() {this.close.emit(true);window.scroll(0,0);}
}



const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',

  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-community-notice',
  templateUrl: './community-notice.component.html',
  styleUrls: ['./community.component.css']
})
export class CommunityNoticeComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  newsfeedDisplayedColumns: string[] = ['title', 'cc', 'createDate' ]; // No body
  newsfeedDataSource;
  resDataSource;
  memResourceDataSource;
  selectedReDataSource;

  seletedCate;
  routeType;

  newsfeeds : NewsFeeds[] = [];
  memResources = [];
  constructor(
    private boardService: BoardService,
    private datePipe: DatePipe,
    public router: Router,
  ){
    this.boardService.getNoticeList().subscribe(
      data => {
        data.forEach(element => {
          this.newsfeeds.push({
            id: element.id,
            title: element.title,
            body: '',
            cc: element.click_count,
            createDate: this.datePipe.transform(element.create_date, 'YY년 MM월 dd일'),
            pin: element.pin
          });
        });
        this.newsfeedDataSource = new MatTableDataSource<NewsFeeds>(this.newsfeeds);
        this.newsfeedDataSource.paginator = this.paginator;

        this.seletedCate = this.newsfeedDataSource;
        this.routeType = 'nf';

      }, error => { }
    );

    this.boardService.getResourcesList().subscribe(
      data => {
        data.forEach(element => {
          this.memResources.push({
            id: element.id,
            title: element.title,
            body: '',
            cc: element.click_count,
            createDate: this.datePipe.transform(element.create_date, 'YY년 MM월 dd일'),
            pin: element.pin
          });
        });
        this.memResourceDataSource = new MatTableDataSource<Resources>(this.memResources);
        this.memResourceDataSource.paginator = this.paginator;
      }, error => { }
    );
  }

  ngOnInit() {
    this.scrollTop(0);
  }

  openNf(nfid) {
    this.router.navigate(['community/',{type: this.routeType , id: nfid }]);
    this.scrollTop(0);
  }
  changeCate(to) {
    switch(to){
      case 0 : this.seletedCate = this.newsfeedDataSource; this.routeType = 'nf'; break;
      case 1 : this.seletedCate = this.memResourceDataSource; this.routeType = 're'; break;
      default :this.seletedCate = this.newsfeedDataSource; this.routeType = 'nf'; break;
    }

    this.scrollTop(0);
  }

  nav() {
    this.router.navigate(['community/',{type: this.routeType }]);
    this.scrollTop(0);
  }

  /**
   * function to scroll to the top with an offset (in pixels)
   * @param offset top offset in pixels
   */
  scrollTop(offset: number): void {
    window.scrollTo({ top: offset, behavior: 'smooth' }); // scroll to top smoothly
  }
}

// 스크롤 이벤트
$(document).ready(function() {
  $(document).on('click', '.scrTop', function() {
    $(window).scrollTop(0);
  });
});
