import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { ClassUser } from "src/app/shared/models/ClassUser";
import { ClassSelectionService } from "../../../../shared/services/class-selection-service";
import { take } from "rxjs";
import { ClassCourseService } from "src/app/shared/services/class-course.service";
import { ClassCourse } from "src/app/shared/models/ClassCourse";

@Component({
  selector: "app-remove-users",
  templateUrl: "./remove-users.component.html",
  styleUrls: ["./remove-users.component.css",
    "../add-users/add-users.component.css",
    "../participant-list/participant-list.component.css",
    "../manage-classes.component.css"]
})
export class RemoveUsersComponent implements OnInit {
  candidates: ClassUser[] = [];
  sortedCandidateName: string = '';
  submissionValid: boolean = false;

  selectedClass: ClassCourse = null;

  // Booleans for toggling modals
  modalStates = {
    modal: false, step2Modal: false
  };

  // Getters and setters to activate different modals based on the clicked button
  get activeModal(): boolean {
    return this.modalStates?.modal || false;
  }

  set activeModal(value: boolean) {
    if (this.modalStates) {
      this.modalStates.modal = value;
    }
  }

  get activeStep2Modal(): boolean {
    return this.modalStates?.step2Modal || false;
  }

  set activeStep2Modal(value: boolean) {
    if (this.modalStates) {
      this.modalStates.step2Modal = value;
    }
  }

  constructor(
    private clsSelectionService: ClassSelectionService,
    private clService: ClassCourseService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // subscribe to selectedClass changes
    this.clsSelectionService.selectedClass$.subscribe(
      (cls) => (this.selectedClass = cls)
    );

    // subscribe to candidates changes
    this.clsSelectionService.candidates$.subscribe(
      (candidates) => (this.candidates = candidates)
    );

    this.updateSortedCandidates(); // sort candidate list by name in ascending order and retrieve the first element

    this.activeModal = true;
  }

  // emit toggle events to parent
  @Output() toggleCloseModalEvent = new EventEmitter<void>();
  @Output() toggleProcessUserEvent = new EventEmitter<void>();

  async removeUsers(): Promise<void> {
    if (this.candidates.length === 0) {
      return;
    }

    try {
      let participantList = this.selectedClass.getParticipantList();

      const candidateIds = this.candidates.map(user => user.getUserId());
      participantList = participantList.filter(userId => !candidateIds.includes(userId));
      await this.clService.removeParticipants(this.selectedClass.getClassId(), candidateIds);
      this.selectedClass.setParticipantList(participantList);
      this.clsSelectionService.setSelectedClass(this.selectedClass);
      this.submissionValid = true;
    } catch (error) {
      this.submissionValid = false;
      console.log('Error removing class participants:', error);
    }

    this.toggleProcessUserEvent.emit();
    this.gotoStep2();
  }

  /** Method to retrieve the first fullname of candidates sorted in ascending order
   */
  updateSortedCandidates(): void {
    this.candidates.sort((a, b) =>
      a.getFullname().localeCompare(b.getFullname(), 'ko-KR'));
    this.sortedCandidateName = this.candidates[0]?.getFullname() || '';
  }

  gotoStep2(): void {
    this.activeStep2Modal = true;
    this.activeModal = false;
    this.onToggleProcessUser();
  }


  /**
   * Method to emit the toggleProcessUser event.
   */
  onToggleProcessUser(): void {
    this.toggleProcessUserEvent.emit();
  }

  /**
   * Method to emit the toggleCancelEdits event.
   */
  onToggleCloseModal(): void {
    this.toggleCloseModalEvent.emit();
  }

  /**
   * Method to scroll to the top with an offset (in pixels)
   * @param offset top offset in pixels
   */
  scrollTop(offset: number): void {
    window.scrollTo({ top: offset, behavior: 'smooth' }); // scroll to top smoothly
  }
}
