export enum ContentType {
  Mission,
  Measurement,
  TouchGame,
  VOD,
  None,
};

export enum TrackingDataType {
  Weight,
  Time,
  Calories,
  Touch,
  Distance,
  Strength,
  StrengthGrade,
  Flexibility,
  FlexibilityGrade,
  Agility,
  AgilityGrade,
  Endurance,
  EnduranceGrade,
  Height
};

export type TrackingData = {
  data_type: any,
  data_value: number
}


export class Tracking {
  private trackingId: number;
  private userId: number;
  private classId: number;
  private contentType: string;
  private trackingData: TrackingData[];
  private createDate: Date;

  constructor(
    trackingId: number,
    userId: number,
    classId: number,
    contentType: string,
    trackingData: TrackingData[],
    createDate: Date 
  ) {
    this.trackingId = trackingId;
    this.userId = userId;
    this.classId = classId;
    this.contentType = contentType;
    this.trackingData = trackingData;
    // this.trackingData.forEach(data => data.dataType = this.mapTrackingDataType(data.dataType));
    this.createDate instanceof Date ? 
      this.createDate = createDate : 
      this.createDate = new Date(createDate);
  }

  public getTrackingId(): number {
    return this.trackingId;
  }

  private setTrackingId(trackingId: number): void {
    this.trackingId = trackingId;
  }

  public getUserId(): number {
    return this.userId;
  }

  public setUserId(userId: number): void {
    this.userId = userId;
  }

  public getClassId(): number {
    return this.classId;
  }

  public setClassId(classId: number): void {
    this.classId = classId;
  }

  public getContentType(): string {
    return this.contentType;
  }

  private setContentType(contentType: string): void {
    this.contentType = contentType;
  }

  public getTrackingData(): TrackingData[] {
    return this.trackingData;
  }

  private setTrackingData(trackingData: TrackingData[]): void {
    this.trackingData = trackingData;
  }

  private addTrackingData(data: TrackingData): number {
    if (
      data &&
      typeof data === "object" &&
      "dataType" in data &&
      "dataValue" in data &&
      typeof data.data_type === "string" &&
      typeof data.data_value === "number"
    ) {
      this.trackingData.push(data);
      return 0;
    }

    return 1;
  }

  public getCreateDate(): Date {
    return this.createDate;
  }

  public setCreateDate(createDate: Date): void {
    this.createDate = createDate;
  }
}
