import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { 
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
 } from '@angular/router';
import { Member } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if ( localStorage.getItem('token') ) {
      return true;
    }
    
    // redirect to login page
    return this.router.createUrlTree(['/login']);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    // delegate to canActivate
    return this.canActivate(childRoute, state);
  }
}
