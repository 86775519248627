import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { MustMatch } from "./must-match.validator";
import { DatePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { JusoService } from "src/app/shared/services/juso.service";

import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { Bootpay } from "@bootpay/client-js";
import { s3Url } from "src/app/shared";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { environment } from "src/environments/environment";

import { AdminService } from "src/app/shared/services/admin.service";
import { VodService } from "src/app/shared/services/vod.service";
import { SubscriptionService } from "src/app/shared/services/subscription.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"]
})
export class ProfileComponent implements OnInit {
  editInfoMode = false;
  updatePWMode = false;
  editAddrMode = false;
  userData;
  editUserInfoForm: FormGroup;
  editPasswordForm: FormGroup;
  editAddr;
  photoChanged = "";
  subscriptionData = false;

  loadingYoutube = false;
  constructor(private formBuilder: FormBuilder, private userService: AdminService, public tr: TranslateService, private jusoService: JusoService, private dialog: MatDialog, public router: Router) {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    this.userData = {
      id: userInfo.id,
      fullname: userInfo.fullname,
      email: userInfo.email,
      username: userInfo.username,
      cellphone: userInfo.cellphone,
      address: JSON.parse(localStorage.getItem("address")),
      role: userInfo.role
    };

    this.editUserInfoForm = this.formBuilder.group({
      cnum1: [this.userData.cellphone.substr(0, 3), [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(3), Validators.maxLength(3)]],
      cnum2: [this.userData.cellphone.substr(3, 4), [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(4), Validators.maxLength(4)]],
      cnum3: [this.userData.cellphone.substr(7, 4), [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(4), Validators.maxLength(4)]]
    });

    this.editPasswordForm = this.formBuilder.group(
      {
        currentPassword: ["", [Validators.required]],
        newPassword: ["", [Validators.required, Validators.minLength(6)]],
        checkNewPassword: ["", [Validators.required]]
      },
      { validator: MustMatch("newPassword", "checkNewPassword") }
    );

    this.editAddr = this.userData.address;

    if (localStorage.getItem("subscription") === "null") {
      this.subscriptionData = false;
    } else {
      this.subscriptionData = true;
    }

    // this.userService.checkYoutubeImageCanApply(Number(localStorage.getItem('id'))).subscribe(
    //   data => {
    //     // console.log(data);
    //     this.youtubeStatus = data.res_code;
    //     // res_code:
    //     // 0 - Error occured
    //     // 1 - Can apply
    //     // 2 - Application in progress
    //     // 3 - Application already approved
    //   }, error => {
    //     console.log(error);
    //   }
    // )
  }

  ngOnInit() {}

  fileUpload($event) {
    // this.userData.photoUrl = $event.target.files[0];
    // // this.photoChanged = true;
    // var myReader:FileReader = new FileReader();
    // myReader.onloadend = (e) => {
    //   this.photoChanged = myReader.result as string;
    //   // console.log(myReader.result);
    // }
    // myReader.readAsDataURL(this.userData.photoUrl);
  }

  updatePhoto() {
    // this.userService.updateUserPhoto(this.member.id, this.userData.photoUrl).subscribe(
    // this.userService.updateUserPhoto(localStorage.getItem('id'), this.userData.photoUrl).subscribe(
    //   data => {
    //     this.userData.photoUrl = data.photoUrl;
    //     localStorage.setItem('photoUrl', data.photoUrl );
    //     alert("프로필 사진이 업데이트 되었습니다");
    //     location.reload();
    //   }, error => {
    //     console.log(error);
    //   }
    // );
  }

  // youtubeImageChange(e){
  //   if (e.target.files.length > 0) {
  //     this.youtubeImgFile = e.target.files[0];
  //   }
  // }

  setMode(mode) {
    if (mode === "cell") {
      this.editInfoMode = true;
    } else if (mode === "pw") {
      this.updatePWMode = true;
    } else if (mode === "addr") {
      this.editAddrMode = true;
      this.editAddr = this.userData.address;
    } else {
      // === 'cancel'
      this.editInfoMode = false;
      this.updatePWMode = false;
      this.editAddrMode = false;
    }
  }

  openJusoDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(ProfileFindJusoComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.length > 0) {
        let _address = JSON.parse(result);
        this.editAddr.zipNo = _address.zipNo;
        this.editAddr.jibunAddr = _address.jibunAddr;
        this.editAddr.roadAddr = _address.roadAddr;
        this.editAddr.detail_juso = "";
      }
    });
  }

  updateCellphone() {
    if (this.editUserInfoForm.status === "VALID") {
      var cnum = this.editUserInfoForm.value.cnum1 + this.editUserInfoForm.value.cnum2 + this.editUserInfoForm.value.cnum3;
      this.userService.updateCellphone(cnum).subscribe({
        complete() {
          const userInfo = JSON.parse(localStorage.getItem("user"));
          userInfo.cellphone = String(cnum);
          localStorage.setItem("user", JSON.stringify(userInfo));
          location.reload();
          alert("휴대폰 번호가 업데이트 되었습니다");
        }
      });
    } else {
      alert("입력하신 내용을 확인해주세요");
    }
  }

  updateAddress() {
    let _address = this.editAddr;
    this.userService.updateAddress(_address).subscribe({
      complete() {
        var toSave = {
          zipNo: _address.zipNo,
          jibunAddr: _address.jibunAddr,
          roadAddr: _address.roadAddr,
          detail_juso: _address.detail_juso
        };
        localStorage.setItem("address", JSON.stringify(toSave));
        location.reload();
        alert("주소가 업데이트 되었습니다");
      }
    });
  }

  updatePassword() {
    if (this.editPasswordForm.status === "VALID") {
      this.userService.updatePassword(this.editPasswordForm.value).subscribe({
        error(data) {
          if (data.status == 404) {
            alert("현재 비밀번호가 일치하지 않습니다");
          }
        },
        complete() {
          location.reload();
          alert("비밀번호가 업데이트 되었습니다");
        }
      });
    } else {
      alert("입력 필드가 비어있거나, 새 비밀번호가 일치하지 않습니다");
    }
  }

  // applyForYoutubeImage(){
  //   this.userService.applyYoutubeImage(+localStorage.getItem('id'),this.youtubeImgFile).subscribe(
  //     data => {
  //       if(data.res_code === 1) {
  //         alert( "성공적으로 적용되었습니다" );
  //         this.youtubeStatus = 2;
  //       } else {
  //         alert( "error" );
  //       }
  //       // console.log(data);
  //     }, error => {
  //       console.log(error);
  //     }
  //   )
  // }

  deleteAccountOpen() {
    document.getElementById("Delete_account").style.display = "block";
  }
  deleteAccountClose() {
    document.getElementById("Delete_account").style.display = "none";
  }

  deactivate() {
    // console.log("here");
    if (confirm("계정을 비활성화 하시겠습니까?")) {
      this.userService.activateUser().subscribe(
        data => {
          localStorage.clear();
          this.router.navigate(["home"]).then(() => {
            location.reload();
          });
        },
        error => {
          // console.log(error);
        }
      );
    }
  }
}

// export interface UserVouchers {
//   id: number;
//   type: string;
//   s_date: string;
//   e_date: string;
// }

// @Component({
//   selector: 'app-profile-my-vouchers',
//   templateUrl: './profile-my-vouchers.component.html',
//   styleUrls: ['./profile.component.css']
// })
// export class ProfileMyVouchers {
//   @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

//   userroles = [];

//   dataSource;
//   displayedColumns: string[] = ['type', 's_date', 'e_date'];
//   pmv: UserVouchers[] = [];
//   constructor(
//     private userService: KrUserService,
//     private datePipe: DatePipe,
//   ){
//     this.userService.getPurchasedMVByUser(localStorage.getItem('id')).subscribe(
//       data => {
//         // console.log(data);
//         data.userroles.forEach(element => {
//           if( element.role_name !== 'None' && element.role_name !== 'SU' ){
//             this.userroles.push({id: element.id, label: element.role_name});
//           }
//         });
//         data.results.forEach(e => {
//           this.pmv.push({
//             id: e.id,
//             type: this.getRoleLabel(e.type_id),
//             s_date: this.datePipe.transform(e.start_day),
//             e_date: this.datePipe.transform(e.end_day)
//           })
//         });
//         this.dataSource = new MatTableDataSource<UserVouchers>(this.pmv);
//         this.dataSource.paginator = this.paginator;
//       }, error => {
//         console.log(error);
//       }
//     );
//   }

//   getRoleLabel(id) {
//     for( var i = 0; i < this.userroles.length; i++ ){
//       if ( id === this.userroles[i].id ){
//         return this.userroles[i].label;
//       }
//     }
//     return 'ERROR';
//   }
// }

export interface UserVodViewLog {
  vName: string;
  datetime: string;
}

@Component({
  selector: "app-vod-view-log",
  templateUrl: "./vod-view-log.component.html",
  styleUrls: ["./profile.component.css"]
})
export class VodViewLogComponent {
  dataSource;
  displayedColumns: string[] = ["vName", "datetime"];
  vodViewLog: UserVodViewLog[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    // private member: Member,
    private userService: AdminService,
    private vodService: VodService,
    private datePipe: DatePipe,
    public router: Router
  ) {
    if (!localStorage.getItem("profile")) {
      alert("먼저 프로필을 선택하세요.");
      this.router.navigate(["multiprofile"]);
    } else {
      let profile = JSON.parse(localStorage.getItem("profile"));
      this.vodService.getVodViewLog(profile.id).subscribe(
        data => {
          if (data) {
            data.forEach(element => {
              this.vodViewLog.push({
                vName: element.vod__title,
                datetime: this.datePipe.transform(element.create_date, "short")
              });
            });
          }
          this.dataSource = new MatTableDataSource<UserVodViewLog>(this.vodViewLog);
          this.dataSource.paginator = this.paginator;
        },
        error => {
          // console.log(error);
        }
      );
    }
    // else if(localStorage.getItem('subscription')=='2'){
    //   let mp=JSON.parse(localStorage.getItem('profile'));
    //   this.v3KrService.mpTrackWatchVodGet(Number(mp.id)).subscribe(
    //     data => {
    //       if(data.results!=[]){
    //         data.results.forEach(element => {
    //           this.watchedVods.push({
    //             vName: element.title,
    //             datetime: this.datePipe.transform( element.watched_date, 'short')
    //           });
    //         });
    //       }
    //       this.dataSource = new MatTableDataSource<UserWatchedVods>(this.watchedVods);
    //       this.dataSource.paginator = this.paginator;
    //     }, error => {
    //       console.log(error);
    //     }
    //   );
    // } else {
    //   this.userService.getWatchedVodsByUser(Number(localStorage.getItem('id'))).subscribe(
    //     // this.userService.getWatchedVodsByUser(this.member.id).subscribe(
    //     data => {
    //       data.results.forEach(element => {
    //         // console.log(element.vod_data[0]);
    //         if( element.vod_data.length !== 0 ){
    //           this.watchedVods.push({
    //             vName: element.vod_data[0].title,
    //             datetime: this.datePipe.transform( element.watched_date, 'short')
    //           });
    //         }
    //       });
    //       this.dataSource = new MatTableDataSource<UserWatchedVods>(this.watchedVods);
    //       this.dataSource.paginator = this.paginator;
    //     }, error => {
    //       console.log(error);
    //     }
    //   );
    // }
  }
}

@Component({
  selector: "app-profile-find-juso",
  templateUrl: "./profile-find-juso.component.html",
  styleUrls: ["./profile.component.css"],
  providers: [JusoService, AdminService]
})
export class ProfileFindJusoComponent {
  /*
   * By using the id of the selectedJuso find the same juso in fullJusos to get all of the details.
   * Add the details into the db.
   *
   */
  fullDetailJusos = [];
  lessDetailJusos = [];
  selectedJuso;
  selectedJusoStringed;

  jusoKeyword;

  constructor(private juso: JusoService, private user: AdminService, private dialog: MatDialog) {
    this.selectedJuso = { id: -1, jibunAddr: "" };
    this.selectedJusoStringed = "";
    this.jusoKeyword = "";
  }

  searchJusos = () => {
    if (this.jusoKeyword) {
      this.juso.getJusos(this.jusoKeyword).subscribe({
        next: data => {
          if (data.results.juso) {
            this.fullDetailJusos = data.results.juso;
            this.lessDetailJusos = [];
            let i = 0;
            for (const j of data.results.juso) {
              this.lessDetailJusos.push({ id: i, jibunAddr: j.jibunAddr });
              i++;
            }
          } else {
            alert("검색 결과가 없습니다");
          }
        }
      });
    } else {
      alert("검색어를 입력해주세요");
    }
  };

  jusoClicked = juso => {
    this.selectedJuso = juso;
    this.selectedJusoStringed = JSON.stringify(this.fullDetailJusos[juso.id]);
  };
}

export interface UserSubscriptionReceipt {
  price: number;
  status: number;
  cardNo: string;
  cardCompany: string;
  purchasedAt: string;
}
@Component({
  selector: "app-profile-subscription-receipt",
  templateUrl: "./profile-subscription-receipt.component.html",
  styleUrls: ["./profile.component.css"]
})
export class ProfileSubHistory {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  userroles = [];

  dataSource;
  displayedColumns: string[] = ["price", "status", "cardNo", "cardCompany", "purchasedAt"];
  userSubscriptionReceipt: UserSubscriptionReceipt[] = [];
  status;

  constructor(private subscriptionService: SubscriptionService, private datePipe: DatePipe) {
    this.subscriptionService.getSubscriptionReceiptList().subscribe(
      data => {
        if (data) {
          data.forEach(e => {
            if (e.status == 1) {
              this.status = "완료";
            } else if (e.status == 20) {
              this.status = "취소";
            }
            this.userSubscriptionReceipt.push({
              price: e.price.toLocaleString(),
              status: this.status,
              cardNo: e.card_no,
              cardCompany: e.card_company,
              purchasedAt: this.datePipe.transform(e.purchased_at)
            });
          });
          this.dataSource = new MatTableDataSource<UserSubscriptionReceipt>(this.userSubscriptionReceipt);
          this.dataSource.paginator = this.paginator;
        }
      },
      error => {
        // console.log(error);
      }
    );
  }

  getRoleLabel(id) {
    for (var i = 0; i < this.userroles.length; i++) {
      if (id === this.userroles[i].id) {
        return this.userroles[i].label;
      }
    }
    return "ERROR";
  }
}

export interface UserSubscription {
  subscription: string;
  status: string;
  startDate: String;
  endDate: String;
  reserveExecuteAt: String;
}
export interface userSubscriptionBillingKey {}
export interface DialogData {
  endDate: String;
}
@Component({
  selector: "app-profile-subscription-user.",
  templateUrl: "./profile-subscription-user.component.html",
  styleUrls: ["./profile.component.css"]
})
export class ProfileMySubscription {
  userroles = [];

  displayedColumns: string[] = ["subscription", "status", "startDate", "endDate", "reserveExecuteAt"];
  userSubscription: UserSubscription[] = [];
  userSubscriptionBillingKey: userSubscriptionBillingKey[] = [];
  endDate;
  constructor(private subscriptionService: SubscriptionService, private datePipe: DatePipe, public dialog: MatDialog) {
    this.subscriptionService.getSubscriptionUserDetail().subscribe({
      next: data => {
        if (data) {
          let status = "대기";
          if (data.status == 1) {
            status = "정상";
          } else if (data.status == 20) {
            status = "취소";
          }
          let temp = {
            subscription: data.subscription,
            status: status,
            startDate: this.datePipe.transform(data.start_date, "Y년 MM월 dd일 HH시 mm분"),
            endDate: this.datePipe.transform(data.end_date, "Y년 MM월 dd일 HH시 mm분"),
            reserveExecuteAt: this.datePipe.transform(data.reserve_execute_at, "Y년 MM월 dd일 HH시 mm분") ?? "다음 결제일이 없습니다"
          };
          this.userSubscription.push(temp);
          this.endDate = this.datePipe.transform(data.end_date, "Y년 MM월 dd일 HH시 mm분");
        }
      }
    });
    this.subscriptionService.getSubscriptionBillingKey().subscribe(
      data => {
        if (data) {
          let temp = {
            cardNo: data.cardNo,
            cardCompanyCode: data.cardCompanyCode,
            cardCompany: data.cardCompany,
            billingExpireAt: this.datePipe.transform(data.billingExpireAt, "MM/YY")
          };
          this.userSubscriptionBillingKey.push(temp);
        }
      },
      error => {
        // console.log(error);
      }
    );
  }

  getRoleLabel(id) {
    for (var i = 0; i < this.userroles.length; i++) {
      if (id === this.userroles[i].id) {
        return this.userroles[i].label;
      }
    }
    return "ERROR";
  }

  openDialog(): void {
    this.dialog.open(SubTerminationPopup, {
      width: "300px",
      height: "auto",
      data: {
        endDate: this.endDate
      }
    });
  }

  reRequestBillingKey(sub) {
    let order_id = String(new Date().getTime()) + String(sub.id) + String(JSON.parse(localStorage.getItem("user"))?.id);

    Bootpay.requestSubscription({
      application_id: "626a5f2e2701800020f696d5",
      pg: "나이스페이",
      price: 0,
      tax_free: 0,
      order_name: sub.name,
      subscription_id: order_id,
      user: {
        username: JSON.parse(localStorage.getItem("user"))?.fullname,
        email: JSON.parse(localStorage.getItem("user"))?.email
      },
      extra: {
        subscription_comment: `매월 ${sub.price}원이 결제됩니다`,
        subscribe_test_payment: true
      },
      method: "카드자동"
    }).then(
      function (response) {
        if (response.event === "done") {
          $.ajax({
            url: environment.apiUrl + "/subscription/billing_key/change",
            method: "post",
            headers: {
              Authorization: JSON.parse(localStorage.getItem("token"))?.access
            },
            data: {
              receipt_id: response.data["receipt_id"],
              subscription_id: sub.id
            },
            success: function (data) {
              alert("결제 카드 정보가 변경되었습니다.");
              location.reload();
            },
            error: function () {
              alert("결제 카드 정보 변경을 실패했습니다.");
            }
          });
        }
      },
      function (error) {
        alert("결제 카드 정보 변경을 실패했습니다.");
      }
    );
  }
}

@Component({
  selector: "app-sub-termination-popup",
  templateUrl: "./sub-termination-popup.component.html",
  styleUrls: ["./profile.component.css"]
})
export class SubTerminationPopup {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private subscriptionService: SubscriptionService, public dialogRef: MatDialogRef<SubTerminationPopup>) {}
  ngOnInit(): void {}
  onCancelClick(): void {
    this.dialogRef.close();
  }
  onConfirmClick() {
    this.subscriptionService.unregistSubscription().subscribe(
      data => {
        location.reload();
        alert("정상적으로 이용권 해지가 완료되었습니다.");
        // console.log(data);
      },
      error => {
        // alert('이용권 해지를 실패했습니다. 관리자에게 문의하세요.');
      }
    );
  }
}
export interface FavoriteList {
  id: number;
  title: string;
  content: string;
  content_vod: string;
  preview: string;
  thumbnail: string;
  category: number;
  gangsa: string;
  level: number;
  vod_min: number;
  favorite: boolean;
  save_time: number;
}
@Component({
  selector: "app-my-favorite",
  templateUrl: "./my-favorite.component.html",
  styleUrls: ["./my-favorite.component.css"]
})
export class Favorite {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  enablePlay = false;
  enablePreview = false;
  first = true;
  playing = false;
  playSource: any = "";
  isString = false;
  loading = true;
  open = false;
  pointer = true;
  continue = false;
  canPlayEvent = false;

  index = 0;
  selected = {
    index: 0,
    id: 0,
    title: "",
    content: "",
    content_vod: "",
    preview: "",
    thumbnail: "../../../../assets/images/thumnail_sample.jpg",
    category: 0,
    gangsa: "",
    level: 0,
    vod_min: 0,
    favorite: true,
    save_time: 0.0
  };
  page = 1;
  pageSize = 5;

  totalPlayTime = 0;
  startTime;

  navLinks: FavoriteList[] = [];
  displayedColumns: string[] = ["checkbox", "img", "title", "cate", "playability", "drop"];
  dataSource;

  selectField = {};
  selectedPlay = false;
  checkedList = [];
  sort_type = 0;

  constructor(private vodService: VodService, public route: ActivatedRoute, public tr: TranslateService, private sanitizer: DomSanitizer, private location: Location, public router: Router) {
    this.vodService.getVodFavoriteWithVod(JSON.parse(localStorage.getItem("profile")).id).subscribe({
      next: data => {
        if (data) {
          data.forEach(element => {
            this.navLinks.push({
              id: element.id,
              title: element.title,
              content: element.content,
              content_vod: element.content_vod,
              preview: element.preview,
              thumbnail: s3Url + element.thumbnail,
              category: element.category,
              gangsa: element.gangsa,
              level: element.level,
              vod_min: element.vod_min,
              favorite: element.favorite,
              save_time: element.save_time
            });
          });
        }

        this.dataSource = new MatTableDataSource<FavoriteList>(this.navLinks);
        this.dataSource.paginator = this.paginator;
        this.loading = false;
      }
    });
  }

  ngOnInit() {
    this.selected.id = +this.route.snapshot.paramMap.get("id");
    window.scroll(0, 0);
  }
  ngOnDestroy() {
    this.addVodView();
  }

  matchNavCate(searchId) {
    for (let i = 0; i < this.navLinks.length; i++) {
      if (this.navLinks[i].id === searchId) {
        return i;
      }
    }
    return -1;
  }

  public changeSelectedContent(e) {
    this.pointer = true;
    if (this.open === false) {
      this.open = true;
    }
    this.addVodView();
    var _selected = this.selected;
    var _selectedCategory = e;
    _selected.id = _selectedCategory.id;
    _selected.title = _selectedCategory.title;
    _selected.content = _selectedCategory.content;
    _selected.content_vod = _selectedCategory.content_vod;
    _selected.preview = _selectedCategory.preview;
    _selected.thumbnail = _selectedCategory.thumbnail;
    _selected.gangsa = _selectedCategory.gangsa;
    _selected.level = _selectedCategory.level;
    _selected.vod_min = _selectedCategory.vod_min;
    _selected.favorite = _selectedCategory.favorite;
    _selected.save_time = _selectedCategory.save_time;
    this.playSource = "";
    this.playing = false;
    this.location.go("profile/vod/" + e.id);
    if (_selected.content_vod?.indexOf("youtube") > -1) {
      this.isString = false;
    } else {
      this.isString = true;
    }
  }

  playVod() {
    let video = <HTMLVideoElement>document.getElementById("myVideo");
    if (this.loading === false && this.pointer && !(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)) {
      this.pointer = false;
      if (!localStorage.getItem("token")) {
        alert("영상을 보려면 로그인이 필요합니다.");
        this.playing = true;
        this.playSource = this.selected.preview;
      } else if (!localStorage.getItem("profile")) {
        alert("프로필을 선택해주세요.");
        this.router.navigate(["multiprofile"]);
      } else {
        this.playing = true;
        if (this.selected.content_vod?.length) {
          if (this.selected.content_vod.indexOf("youtube") > -1) {
            this.playSource = this.sanitizer.bypassSecurityTrustHtml(
              `<iframe width="100%" height="388px" src="` + this.selected.content_vod + `" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            );
          } else {
            this.canPlayEvent = true;
            this.playSource = this.selected.content_vod;
          }
        } else {
          if (this.selected.preview?.indexOf("youtube") > -1) {
            this.playSource = this.sanitizer.bypassSecurityTrustHtml(
              `<iframe width="100%" height="388px" src="` + this.selected.preview + `" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            );
          } else {
            this.playSource = this.selected.preview;
          }
        }
      }
    }
  }

  setCurrentTime() {
    let video = <HTMLVideoElement>document.getElementById("myVideo");
    if (this.loading === false && video.currentTime == 0 && this.canPlayEvent === true) {
      this.canPlayEvent = false;
      video.currentTime = this.selected.save_time;
    }
  }

  pageEvents(e: any) {
    if (e.previousPageIndex != e.pageIndex) {
      $("html, body").animate({
        scrollTop: 0
      });
    }
  }
  cbAll(cbAll) {
    let cbs = document.querySelectorAll('input[type="checkbox"]');
    Object(cbs).forEach(cb => {
      cb.checked = cbAll.srcElement.checked;
    });
  }
  checkItem(ch) {
    let box_len = document.querySelectorAll('input[type="checkbox"]').length;
    let checked_len = document.querySelectorAll('input[type="checkbox"]:checked').length;
    if (box_len == checked_len) {
      (<HTMLInputElement>document.getElementById("cbAll")).checked = true;
    } else {
      if (box_len - checked_len == 1 && (<HTMLInputElement>document.getElementById("cbAll")).checked == false) {
        (<HTMLInputElement>document.getElementById("cbAll")).checked = true;
      } else {
        (<HTMLInputElement>document.getElementById("cbAll")).checked = false;
      }
    }
  }

  delItem(vod_ids) {
    for (const _id in vod_ids) {
      for (const c in this.navLinks) {
        if (this.navLinks[c].id === vod_ids[_id]) {
          this.navLinks.splice(+c, 1);
          break;
        }
      }
    }
  }
  cbDelete(vod_id) {
    let arr = [];
    if (vod_id) {
      arr = [vod_id];
    } else {
      this.selectField = document.querySelectorAll('input[type="checkbox"]:checked');
      Object(this.selectField).forEach(e => {
        arr.push(Number(e.value));
      });
    }

    this.vodService.removeVodFavorite(+JSON.parse(localStorage.getItem("profile"))?.id, +vod_id).subscribe({
      complete: () => {
        this.delItem(arr);
        if (this.selected.id == vod_id) {
          this.selected.favorite = false;
        }
        this.dataSource = new MatTableDataSource<FavoriteList>(this.navLinks);
        this.dataSource.paginator = this.paginator;
      }
    });
  }
  selectVod() {
    let cbs = document.querySelectorAll('input[type="checkbox"]:checked');
    if (cbs.length == 0) {
      this.selectedPlay = false;
    } else {
      this.selectedPlay = !this.selectedPlay;
    }
    if (this.selectedPlay && cbs.length != 0) {
      this.checkedList = [];
      Object(cbs).forEach(e => {
        if (e.id != "cbAll") {
          this.checkedList.push(Number(e.value));
        }
      });
      this.ended();
    }
  }
  ended() {
    if (this.selectedPlay && this.checkedList.length != 0) {
      this.changeSelectedContent(this.checkedList[0]);
      this.checkedList.shift();
    }
  }
  myFavorite(vod_id, mark) {
    if (mark) {
      this.vodService.removeVodFavorite(+JSON.parse(localStorage.getItem("profile"))?.id, +vod_id).subscribe({
        complete: () => {
          this.delItem([vod_id]);
          this.selected.favorite = false;
          this.dataSource = new MatTableDataSource<FavoriteList>(this.navLinks);
          this.dataSource.paginator = this.paginator;
        }
      });
    } else {
      this.vodService.addVodFavorite(+JSON.parse(localStorage.getItem("profile"))?.id, +vod_id).subscribe({
        complete: () => {
          this.selected.favorite = true;
          this.navLinks.unshift({
            id: this.selected.id,
            title: this.selected.title,
            content: this.selected.content,
            content_vod: this.selected.content_vod,
            preview: this.selected.preview,
            thumbnail: s3Url + this.selected.thumbnail,
            category: this.selected.category,
            gangsa: this.selected.gangsa,
            level: this.selected.level,
            vod_min: this.selected.vod_min,
            favorite: this.selected.favorite,
            save_time: this.selected.save_time
          });
          this.dataSource = new MatTableDataSource<FavoriteList>(this.navLinks);
          this.dataSource.paginator = this.paginator;
        }
      });
    }
  }
  sorting(flag) {
    if (flag == 1) {
      this.sort_type = 0;
      this.navLinks = this.navLinks.sort((a, b) => {
        if (a.id < b.id) return 1;
        if (a.id > b.id) return -1;
        return 0;
      });
    } else if (flag == 2) {
      this.sort_type = 0;
      this.navLinks = this.navLinks.sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      });
    } else if (flag == 3) {
      this.sort_type = 0;
      this.navLinks.sort(() => Math.random() - 0.5);
    }
    this.dataSource = new MatTableDataSource<FavoriteList>(this.navLinks);
    this.dataSource.paginator = this.paginator;
  }
  move_front_vod(v) {
    this.delItem([v.id]);
    this.navLinks.unshift(v);
    this.dataSource = new MatTableDataSource<FavoriteList>(this.navLinks);
    this.dataSource.paginator = this.paginator;
  }
  move_rear_vod(v) {
    this.delItem([v.id]);
    this.navLinks.push(v);
    this.dataSource = new MatTableDataSource<FavoriteList>(this.navLinks);
    this.dataSource.paginator = this.paginator;
  }
  pauseEvent() {
    let finishTime = new Date();
    this.totalPlayTime += Number(finishTime) - Number(this.startTime);
  }
  playEvent() {
    this.startTime = new Date();
  }
  addVodView() {
    if (this.selected.content_vod) {
      if (this.startTime) {
        let video = <HTMLVideoElement>document.getElementById("myVideo");
        video?.pause();
        this.pauseEvent();
        this.vodService.addVodView(+JSON.parse(localStorage.getItem("profile"))?.id, this.selected.id, video.currentTime).subscribe({
          complete: () => {}
        });
        this.vodService.addVodViewLog(+JSON.parse(localStorage.getItem("profile"))?.id, this.selected.id, this.totalPlayTime).subscribe({
          complete: () => {}
        });
        this.navLinks.find(data => data.id == this.selected.id).save_time = video.currentTime;
      }
    }
    this.totalPlayTime = 0;
  }
}

// 썸네일 눌렀을 때 스크롤 탑
$(document).ready(function () {
  $(document).on("click", ".vod_img", function () {
    $("html, body").animate({
      scrollTop: 0
    });
  });
});
