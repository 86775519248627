import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Input, Output, EventEmitter } from '@angular/core';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-multiprofile',
  templateUrl: './multiprofile.component.html',
  styleUrls: ['./multiprofile.component.css']
})
export class MultiprofileComponent implements OnInit {
  m_state;
  editMode;
  selectedProfile;
  constructor(
    public router: Router
  ) { 
  }
  ngOnInit(): void {
    this.m_state = 1;
    this.editMode = false;
    // if(localStorage.getItem('subscription')!='2'){
    //   this.router.navigate(['kr/subscription']).then(()=>{alert('이용권을 구매하세요.')});
    // }
    // $("#headerId").load("body>app-root>div>app-header");
    $("#headerId").css("display","none");
    // $("#footerId").load("body>app-root>div>app-footer");
    $("#footerId").css("display","none");

  }

  ngOnDestroy(): void {
    location.reload();
  }

  changeStateHandler(toState) {
    this.m_state = toState;
  }
  changeEditModeHandler(editMode) {
    this.editMode = editMode;
  }
  changeSelectedProfile(profileInfo){
    this.selectedProfile = profileInfo;
  }
}


@Component({
  selector: 'app-multiprofile-regular',
  templateUrl: './multiprofile-regular.component.html',
  styleUrls: ['./multiprofile.component.css']
})
export class MultiprofileRegularComponent implements OnInit {
  profiles=[];
  maxProfile=1;
  user_mp={};
  @Input() editMode: boolean;
  @Output() m_state_changed: EventEmitter<number> =   new EventEmitter();
  @Output() editMode_changed: EventEmitter<boolean> =   new EventEmitter();
  @Output() selectedProfile_changed: EventEmitter<{}> = new EventEmitter();


  constructor(
    public router: Router,
    private userService: AdminService
  ) {
    
  }
  ngOnInit(): void {
    this.userService.getProfile().subscribe({
      next: (data) => {
        data.forEach(e => {
          if(e.is_default){
            this.profiles.unshift(e);
          }
          else{
            this.profiles.push(e);
          }
        });
      }
    });
    this.maxProfile = JSON.parse(localStorage.getItem('profile'))?.maxCount;
  }
  selectedProfile(profileInfo){
    if(this.editMode){  
      this.selectedProfile_changed.emit(profileInfo);
      this.changeState(3);      
    }
    else{
      //mpSelect API
      // this.v3KrService.mpSelect(profileInfo.id).subscribe(
      //   data => {
      //     localStorage.setItem('mpId',String(profileInfo.id));
      //     localStorage.setItem('mpName',profileInfo.name);
      //     localStorage.setItem('mpPhoto',profileInfo.photo);
      //     // this.router.navigate(['kr/home']);
      //   },
      //   error => {
      //     alert('해당 프로필을 선택할 수 없습니다.');
      //     console.log(error);
      //   }
      // );
      const selectedProfileInfo = JSON.parse(localStorage.getItem('profile'));
      selectedProfileInfo.id = profileInfo.id;
      selectedProfileInfo.name = profileInfo.name;
      selectedProfileInfo.photo = profileInfo.photo;
      localStorage.setItem('profile', JSON.stringify(selectedProfileInfo))
      $("#headerId").css("display","block");
      $("#footerId").css("display","block");
      this.router.navigate(['home']).then(()=>{ });
    }
  }
  complete(){
    this.changeEditModeHandler(false);
    this.changeState(1);
  }
  changeEditModeHandler(editMode) {
    this.editMode_changed.emit(editMode);
  }
  changeState(toState) {
    this.m_state_changed.emit(toState);
  }
}


@Component({
  selector: 'app-multiprofile-newprofile',
  templateUrl: './multiprofile-newprofile.component.html',
  styleUrls: ['./multiprofile.component.css']
})
export class MultiprofileNewprofileComponent implements OnInit {
  photoChanged;
  photo;
  newProfileName="";
  @Output() m_state_changed: EventEmitter<number> = new EventEmitter();
  constructor(
    public router: Router,
    private userService: AdminService
  ) { 
  }
  ngOnInit(): void {
    this.photoChanged = '../../../assets/images/defpf.svg';
  }
  createProfile(toState) {
    // mpCreate API
    if(this.newProfileName.replace(/^\s+|\s+$/g, '' ) != "" ){  // 공백 체크
      this.userService.createProfile(this.newProfileName, this.photo).subscribe(
        data => {
          this.changeState(toState);
        },
        error => {
          alert('프로필을 생성할 수 없습니다.')
        }
      );
    }
    else{
      alert('프로필 이름을 입력해주세요')
    }
    
  }
  changeState(toState) {
    this.m_state_changed.emit(toState);
  }
  fileUpload($event){
    this.photo = $event.target.files[0];
    var myReader:FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.photoChanged = myReader.result as string;
    }
    myReader.readAsDataURL(this.photo);
  }
}

@Component({
  selector: 'app-multiprofile-edit',
  templateUrl: './multiprofile-edit.component.html',
  styleUrls: ['./multiprofile.component.css']
})
export class MultiprofileEditComponent implements OnInit {
  photoChanged;
  userId;
  photo;
  tempName;
  @Input() selectedProfile;
  @Output() m_state_changed: EventEmitter<number> =   new EventEmitter();
  constructor(
    public router: Router,
    private userService: AdminService
  ) {
   }
  ngOnInit(): void {
    this.tempName = this.selectedProfile.name;
    this.photoChanged = this.selectedProfile.photo;
    this.userId=JSON.parse(localStorage.getItem('user'))?.id;
  }
  changeState(toState) {
    this.m_state_changed.emit(toState);
  }
  saveUserProfile(){
    if(this.tempName.replace(/^\s+|\s+$/g, '' ) != "" ){
      this.userService.updateProfile(this.selectedProfile.id, this.tempName, this.photo).subscribe({
        complete: () => {
          this.changeState(1);
        },
      });
    } else {
      alert('프로필 이름을 입력해주세요')
    }
  }
  fileUpload($event){
    this.photo = $event.target.files[0];
    var myReader:FileReader = new FileReader();
    myReader.onloadend = (e) => {
      this.photoChanged = myReader.result as string;
    }
    myReader.readAsDataURL(this.photo);
  }
}

@Component({
  selector: 'app-multiprofile-delete',
  templateUrl: './multiprofile-delete.component.html',
  styleUrls: ['./multiprofile.component.css']
})
export class MultiprofileDeleteComponent implements OnInit {
  @Input() selectedProfile;
  @Output() m_state_changed: EventEmitter<number> =   new EventEmitter();
  constructor(
    public router: Router,
    private userService: AdminService
  ) {
   }
  ngOnInit(): void {
  }
  changeState(toState) {
    this.m_state_changed.emit(toState);
  }
  deleteThisProfile(){
    // mpDelete Api
    this.userService.deleteProfile(this.selectedProfile.id).subscribe(
      data => {
        this.changeState(1);
      },
      error => {
        alert('프로필을 삭제 할 수 없습니다.')
        // console.log(error);
      }
    );
  }
}