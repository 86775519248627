import { Component, OnInit } from "@angular/core";
import { RegularUserService } from "src/app/shared/services/regular-user.service";
import { TrackingService } from "src/app/shared/services/tracking.service";
import { OrganizationService } from "src/app/shared/services/organization.service";
import _ from 'lodash-es';

@Component({
  selector: 'app-org-progress',
  templateUrl: './org-progress.component.html',
  styleUrl: './org-progress.component.css'
})
export class OrgProgressComponent implements OnInit {
  orgId: number = 0;
  totalParticipants: number = 0;
  totalWorkoutTime: number = 0;
  totalWeightLoss: number = 0;
  totalWorkoutDays: number = 0;

  constructor(
    private orgService: OrganizationService,
    private userService: RegularUserService,
    private trackingService: TrackingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.orgId = this.orgService.getOrganizationForAdminUser().getId();

    try {
      this.totalParticipants = await this.userService.getOrgUserCount(this.orgId);
    } catch (error) {
      console.log('error fetching total user count for organization.', error);
      this.totalParticipants = 0;
    }

    try {
      const totalWorkoutTimeRaw = await this.trackingService.getOrgTotalWorkoutTime(this.orgId) / (60 * 60); // output is in seconds
      this.totalWorkoutTime = _.round(totalWorkoutTimeRaw, 1); // round to one decimal place
    } catch (error) {
      console.log('error fetching total workout time for organization:', error);
      this.totalWorkoutTime = 0;
    }

    try {
      this.totalWeightLoss = await this.trackingService.getOrgTotalWeightLoss(this.orgId);
      this.totalWeightLoss /= 100;
    } catch (error) {
      console.log('error fetching total weight loss amount for organization:', error);
      this.totalWeightLoss = 0;
    }

    try {
      this.totalWorkoutDays = await this.trackingService.getOrgTotalWorkoutDays(this.orgId);
    } catch (error) {
      console.log('error fetching total workout days for organization:', error);
      this.totalWorkoutDays = 0;
    }
  }
}
