import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptionsNoToken = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class BoardService {

  apiBoardUrl = environment.apiUrl + '/board';

  constructor(
    private http: HttpClient,
  ) { }
  returnUrl() {
    return this.apiBoardUrl;
  }

  getNoticeList(): Observable<any> {
    return this.http.get(this.apiBoardUrl + '/organization/notice', httpOptionsNoToken);
  }

  getNoticeRetrieve(id): Observable<any> {
    return this.http.get(this.apiBoardUrl + '/organization/notice/' + id, httpOptionsNoToken);
  }

  addNoticeClick(id): Observable<any> {
    const body = {}
    return this.http.post(this.apiBoardUrl + '/organization/notice/' + id + '/add', body, httpOptionsNoToken);
  }

  getResourcesList(): Observable<any> {
    return this.http.get(this.apiBoardUrl + '/organization/resource', httpOptionsNoToken);
  }

  getResourcesRetrieve(id): Observable<any> {
    return this.http.get(this.apiBoardUrl + '/organization/resource/' + id, httpOptionsNoToken);
  }

  addResourcesClick(id): Observable<any> {
    const body = {}
    return this.http.post(this.apiBoardUrl + '/organization/resource/' + id + '/add', body, httpOptionsNoToken);
  }
}
