import { Injectable } from '@angular/core';
import { ContentType, Tracking, TrackingDataType } from '../models/Tracking';
import { first, firstValueFrom, map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import _ from 'lodash-es';

const httpOptionsNoToken = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  storageKeyPrefix: string = 'tracking';
  private apiTrackingUrl = environment.apiUrl + '/tracking';

  constructor(
    private http: HttpClient
  ) { }

  getTrackingDataForClass(classId: number, userId?: number): Promise<Tracking[]> {
    const body: any = { class_id: classId };
    if (userId !== undefined) {
      body.user_id = userId;
    }

    return firstValueFrom(
      this.http.post<Tracking[]>(`${this.apiTrackingUrl}/get/class`, body, httpOptionsNoToken).pipe(
        map((data) =>
          data.map((item) => this.deserializeTrackingObject(item)) // Transform raw API response into Tracking instances
        ),
        //tap((trackingData) => console.log('Fetched tracking data:', trackingData)) // Optional logging
      )
    );
  }

  getTrackingDataForMission(missionId: number, userId?: number): Promise<Tracking[]> {
    const body: any = { class_id: missionId };
    if (userId !== undefined) {
      body.user_id = userId;
    }

    return firstValueFrom(
      this.http.post<Tracking[]>(`${this.apiTrackingUrl}/get/mission`, body, httpOptionsNoToken).pipe(
        map((data) =>
          data.map((item) => this.deserializeTrackingObject(item)) // Transform raw API response into Tracking instances
        ),
        //tap((trackingData) => console.log('Fetched tracking data:', trackingData)) // Optional logging
      )
    );
  }

  /**
   * Fetches the total workout time for a given organization.
   * @param orgId the ID of the organization
   * @returns a promise resolving to the total workout time in seconds
   */
  getOrgTotalWorkoutTime(orgId: number): Promise<number> {
    const body = { org_id: orgId };

    return firstValueFrom(
      this.http.post<{ time: number }>(`${this.apiTrackingUrl}/get/organization/total/time`, body, httpOptionsNoToken).pipe(
        map((res) => res.time), // unpack the object to extract the 'time' value
        //tap((time) => console.log('Total workout time for organization:', time)) // log the extracted time
      )
    );
  }

  /**
  * Fetches the total weight loss for a given organization.
  * @param orgId the ID of the organization
  * @returns a promise resolving to the total weight loss in kilograms
  */
  getOrgTotalWeightLoss(orgId: number): Promise<number> {
    const body = { org_id: orgId };

    return firstValueFrom(
      this.http.post<{ weight: number }>(`${this.apiTrackingUrl}/get/organization/total/weight/loss`, body, httpOptionsNoToken).pipe(
        map((res) => res.weight), // unpack the object to extract the 'weight' value
        //tap((weight) => console.log('Total weight loss for organization:', weight)) // log the extracted weight
      )
    );
  }
  
  /**
   * Fetches the total workout days for a given organization.
   * @param orgId the ID of the organization
   * @returns a promise resolving to the total number of workout days
   */
  getOrgTotalWorkoutDays(orgId: number): Promise<number> {
    const body = { org_id: orgId };

    return firstValueFrom(
      this.http.post<{ days: number }>(`${this.apiTrackingUrl}/get/organization/total/day`, body, httpOptionsNoToken).pipe(
        map((res) => res.days), // unpack the object to extract the 'days' value
        //tap((days) => console.log('Total workout days for organization:', days)) // log the extracted days
      )
    );
  }

  /**
   * Fetches the total workout time for a given class.
   * @param classId the ID of the class
   * @returns a promise resolving to the total workout time in seconds
   */
  getClassTotalWorkoutTime(classId: number): Promise<number> {
    const body = { class_id: classId };

    return firstValueFrom(
      this.http.post<{ time: number }>(`${this.apiTrackingUrl}/get/organization/class/total/time`, body, httpOptionsNoToken).pipe(
        map((res) => res.time), // unpack the object to extract the 'time' value
        //tap((time) => console.log('Total workout time for class:', time)) // log the extracted time
      )
    );
  }

  /**
   * Fetches the total weight loss for a given class.
   * @param classId the ID of the class
   * @returns a promise resolving to the total weight loss in kilograms
   */
  getClassTotalWeightLoss(classId: number): Promise<number> {
    const body = { class_id: classId };

    return firstValueFrom(
      this.http.post<{ weight: number }>(`${this.apiTrackingUrl}/get/organization/class/total/weight/loss`, body, httpOptionsNoToken).pipe(
        map((res) => res.weight), // unpack the object to extract the 'weight' value
        //tap((weight) => console.log('Total weight loss for class:', weight)) // log the extracted weight
      )
    );
  }

  /**
   * Fetches the total workout days for a given class.
   * @param classId the ID of the class
   * @returns a promise resolving to the total number of workout days
   */
  getClassTotalWorkoutDays(classId: number): Promise<number> {
    const body = { class_id: classId };

    return firstValueFrom(
      this.http.post<{ days: number }>(`${this.apiTrackingUrl}/get/organization/class/total/day`, body, httpOptionsNoToken).pipe(
        map((res) => res.days), // unpack the object to extract the 'days' value
        //tap((days) => console.log('Total workout days for class:', days)) // log the extracted days
      )
    );
  }

  public deserializeTrackingObject(tracking: any): Tracking {
    const deserializedData = tracking.data.map((item: any) => {
      let dataValue = item.data_value;

      // Adjust values for '키' and '무게'
      if (item.data_type === '키') {
        dataValue = _.round(dataValue / 100, 1);
      }
      else if (item.data_type === '무게') {
        dataValue = _.round(dataValue / 1000, 1);
      } else if (item.data_type === '유연성') {
        dataValue = _.round((dataValue - 10000) / 10, 1);
      } else if (item.data_type === '순발력') {
        dataValue = _.round(dataValue / 1000, 3);
      }

      return {
        data_type: item.data_type,
        data_value: dataValue,
      };
    });

    return new Tracking(
      tracking.id ?? null,
      null,
      null,
      tracking.content_type ?? '체력측정' ?? null,
      deserializedData,
      new Date(tracking.date) ?? new Date(tracking.create_date) ?? null
    );
  }

  mapContentType(contentType: string): ContentType {
    switch (contentType) {
      case 'NONE':
        return ContentType.None;
      case 'VOD':
        return ContentType.VOD;
      case 'MISSION':
        return ContentType.Mission;
      case 'TOUCH':
        return ContentType.TouchGame;
      case 'MEASUREMENT':
        return ContentType.Measurement;
      default:
        return ContentType.None;
    }
  }

  mapTrackingDataType(trackingDataType: string): TrackingDataType {
    switch (trackingDataType) {
      case 'WEIGHT':
        return TrackingDataType.Weight;
      case 'TIME':
        return TrackingDataType.Time;
      case 'CAL':
        return TrackingDataType.Calories;
      case 'TOUCH':
        return TrackingDataType.Touch;
      case 'DISTANCE':
        return TrackingDataType.Distance;
      case 'STRENGTH':
        return TrackingDataType.Strength;
      case 'STRENGTHGRADE':
        return TrackingDataType.StrengthGrade;
      case 'FLEXIBILITY':
        return TrackingDataType.Flexibility;
      case 'FLEXIBILITYGRADE':
        return TrackingDataType.FlexibilityGrade;
      case 'AGILITY':
        return TrackingDataType.Agility;
      case 'AGILITYGRADE':
        return TrackingDataType.AgilityGrade;
      case 'ENDURANCE':
        return TrackingDataType.Endurance;
      case 'ENDURANCEGRADE':
        return TrackingDataType.EnduranceGrade;
      case 'HEIGHT':
        return TrackingDataType.Height;
      default:
        throw new Error(`Unknown tracking data type: ${trackingDataType}`);
    }
  }
}
