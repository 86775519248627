import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
// import { Member } from 'src/app/shared';
import { AdminService } from "src/app/shared/services/admin.service";
declare var window: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  public pushRightClass: string;
  public isLogin: boolean;
  public name: string;
  public role: string;
  public profile: number;
  photo;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private userService: AdminService
  ) 
  {
    if (localStorage.getItem("token")) {
      this.isLogin = true;
      this.role = JSON.parse(localStorage.getItem("user"))?.role;
    }

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
        this.toggleSidebar();
      }
    });
  }

  ngOnInit(): void {
    this.pushRightClass = "push-right";
    this.photo = JSON.parse(localStorage.getItem("profile"))?.photo;
    window.addEventListener('scroll', this.onScroll);
  }

  onScroll(): void {
    const header = document.querySelector('.kr_header') as HTMLElement;
    const headerNav = document.querySelector('.header_nav') as HTMLElement;
    const catBoxListEls = document.querySelectorAll('.category_box > li > a') as NodeListOf<HTMLElement>;
    const dropdowns = document.querySelectorAll('.dropdown-menu') as NodeListOf<HTMLElement>;
    const dropdownListEls = document.querySelectorAll('.dropdown-menu li a') as NodeListOf<HTMLElement>;

    const darkNavy = '#212935';
    const lightGray = 'rgb(208, 208, 208)';
    const transparentWhite = 'rgba(255, 255, 255, 0.4)'

    if (window.scrollY > 0) {
      header.style.backgroundColor = darkNavy;
      header.style.border = `1px solid ${darkNavy}`;

      headerNav.style.backgroundColor = darkNavy;
      
      catBoxListEls.forEach((child: HTMLElement) => {
        child.style.color = 'white';
      });

      dropdowns.forEach((child: HTMLElement) => {
        child.style.setProperty('background-color', darkNavy, 'important');
      })

      dropdownListEls.forEach((child: HTMLElement) => {
        child.style.color = 'white';
      })
    } else {
      header.style.backgroundColor = transparentWhite;
      header.style.border = `1px solid ${lightGray}`;

      headerNav.style.backgroundColor = transparentWhite;

      catBoxListEls.forEach((child: HTMLElement) => {
        child.style.color = 'rgb(81, 42, 7)';
      });

      dropdowns.forEach((child: HTMLElement) => {
        child.style.setProperty('background-color', 'white', 'important');
      })

      dropdownListEls.forEach((child: HTMLElement) => {
        child.style.color = 'rgb(81, 42, 7)';
      })
    }
  }

  routeTo(to) {
    this.router.navigate([to], { relativeTo: this.route });
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector("body");
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector("body");
    dom.classList.toggle(this.pushRightClass);
  }

  rltAndLtr() {
    const dom: any = document.querySelector("body");
    dom.classList.toggle("rtl");
  }

  logoutUser() {
    // console.log(localStorage.getItem('email'));
    // window.google.accounts.id.revoke('1618033988749895', done => {
    //   console.log(done.error);
    // });

    this.userService.signout().subscribe({
      error: () => {
        // removed for TboxAdmin dev purposes
        // localStorage.clear();
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        localStorage.removeItem('__bootpay_track_uuid__');

        this.router.navigate(["/home"]).then(() => {
          // location.reload();
        });
      },
      complete: () => {
        // removed for TboxAdmin dev purposes
        // localStorage.clear();
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        localStorage.removeItem('__bootpay_track_uuid__');

        this.router.navigate(["/home"]).then(() => {
          location.reload();
        });
      }
    });
  }

  gotoHome(): void {
    this.router.navigate(['/home']);
    $('html,body').stop().animate({
      scrollTop : 0
    });
  }
}

$(document).ready(function () {
  //side menu close event
  $(".kr_header .close_btn_kr, .kr_header .sidenav-bg, .kr_header .list-group-item").click(function () {
    $(window).scrollTop(0);
    $(".kr_header .sidenav-bg").removeClass("side_tog_bg");
    $(".kr_header .sidenav").removeClass("side_tog");
    $(".kr_header .t_bar").removeClass("t_bar_toggle");
    $(".kr_header .m_bar").removeClass("m_bar_toggle");
    $(".kr_header .b_bar").removeClass("b_bar_toggle");
  });

  //side menu toggle button
  $(".kr_header .side_menu_toggle").click(function () {
    $(".kr_header .sidenav-bg").toggleClass("side_tog_bg");
    $(".kr_header .sidenav").toggleClass("side_tog");
    $(".kr_header .t_bar").toggleClass("t_bar_toggle");
    $(".kr_header .m_bar").toggleClass("m_bar_toggle");
    $(".kr_header .b_bar").toggleClass("b_bar_toggle");
  });
 
}); //end
