import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tos-header',
  templateUrl: './tos-header.component.html',
  styleUrl: './tos-header.component.css'
})
export class TosHeaderComponent {
  constructor(public router: Router) {}
  
  navigateToTos() {
    this.router.navigate(['/tos']);
  }

  navigateToPriv() {
    this.router.navigate(['/privacy']);  
  }

  navigateToMemTerms() {
    this.router.navigate(['/membership-terms']);
  }
}
