import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Organization } from "src/app/shared/models/Organization";
import { ClassCourse } from "src/app/shared/models/ClassCourse";
import { ClassInfo } from "src/app/shared/models/ClassInfo";
import { ClassManageMode } from "../../layout-kr/components/manage-classes/manage-classes.component";
import { ClassUser } from "src/app/shared/models/ClassUser";
import { ClassMission } from "../models/ClassMission";

@Injectable({
  providedIn: 'root'
})
export class ClassSelectionService {
  // BehaviorSubjects to hold and observe selectedClass and selectedClassInfo
  private orgSubject = new BehaviorSubject<Organization | null>(null);
  private buildClassListTriggerSubject = new BehaviorSubject<void>(null);
  private selectedClassSubject = new BehaviorSubject<ClassCourse | null>(null);
  private selectedClassInfoSubject = new BehaviorSubject<ClassInfo | null>(null);
  private isMyClassSubject = new BehaviorSubject<boolean>(false);
  private clsManageModeSubject = new BehaviorSubject<ClassManageMode | null>(null);
  private userIdSubject = new BehaviorSubject<number>(null);
  private userFullnameSubject = new BehaviorSubject<string>(null);

  // BehaviorSubjects to hold and observe AddUserList and candidates (for AddUsers and AddMissions)
  private candidatesSubject = new BehaviorSubject<ClassUser[] | null>([]);
  private userListSubject = new BehaviorSubject<ClassUser[] | null>([]);
  private addUserListSubject = new BehaviorSubject<ClassUser[] | null>([]);
  private newMissionSubject = new BehaviorSubject<ClassMission | null>(null);
  private classMissionMapSubject = new BehaviorSubject<Map<number, ClassMission> | null>(null);

  // used to propagate submission form validity (in AddUsers, RemoveUsers, EditClass, CreateClass, AddMission, ManageUsers, AcceptUsers) 
  private submissionValidSubject = new BehaviorSubject<boolean>(true);

  buildClassListTrigger$ = this.buildClassListTriggerSubject.asObservable();

  clsManageMode$ = this.clsManageModeSubject.asObservable();
  org$ = this.orgSubject.asObservable();

  selectedClass$ = this.selectedClassSubject.asObservable();
  selectedClassInfo$ = this.selectedClassInfoSubject.asObservable();
  isMyClass$ = this.isMyClassSubject.asObservable();
  userId$ = this.userIdSubject.asObservable();
  userFullname$ = this.userFullnameSubject.asObservable();

  candidates$ = this.candidatesSubject.asObservable();
  userList$ = this.userListSubject.asObservable();
  addUserList$ = this.addUserListSubject.asObservable();

  classMissionMap$ = this.classMissionMapSubject.asObservable();
  newMission$ = this.newMissionSubject.asObservable();

  submissionValid$ = this.submissionValidSubject.asObservable();

  triggerBuildClass(): void {
    this.buildClassListTriggerSubject.next();
  }

  setOrg(org: Organization): void {
    this.orgSubject.next(org);
  }

  setSelectedClass(cls: ClassCourse): void {
    this.selectedClassSubject.next(cls);
  }

  setSelectedClassInfo(clsInfo: ClassInfo): void {
    this.selectedClassInfoSubject.next(clsInfo);
  }

  setUserId(userId: number): void {
    this.userIdSubject.next(userId);
  }

  setUserFullname(userFullname: string): void {
    this.userFullnameSubject.next(userFullname);
  }

  setIsMyClass(isMyClass: boolean): void {
    this.isMyClassSubject.next(isMyClass);
  }

  setClassManageMode(mode: ClassManageMode): void {
    this.clsManageModeSubject.next(mode);
  }

  setCandidates(candidates: ClassUser[]): void {
    this.candidatesSubject.next(candidates);
  }

  setUserList(userList: ClassUser[]): void {
    this.userListSubject.next(userList);
  }

  setAddUserList(addUserList: ClassUser[]): void {
    this.addUserListSubject.next(addUserList);
  }

  setClassMissionMap(classMissionMap: Map<number, ClassMission>): void {
    this.classMissionMapSubject.next(classMissionMap);
  }

  setNewMission(newMission: ClassMission): void {
    this.newMissionSubject.next(newMission);
  }

  setSubmissionValid(submissionValid: boolean): void {
    this.submissionValidSubject.next(submissionValid);
  }
}
