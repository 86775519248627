import { Injectable } from "@angular/core";
import { User, OrgUserStatus, AdminUserType } from "../models/User";
import { SchoolUser } from "../models/SchoolUser";
import { OrgAdmin } from "../models/OrgAdmin";
import { PendingUser } from "../models/PendingUser";
import { ClassUser, UserMissionStatus } from "../models/ClassUser";
import { AdminService } from "./admin.service";
import { ClassCourseService } from "./class-course.service";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { ClassCourse } from "../models/ClassCourse";

const httpOptionsNoToken = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class RegularUserService {
  apiOrganizationUrl = environment.apiUrl + '/organization';
  classInfoStorageKeyPrefix: string = 'class_info'
  private activeStatus: OrgUserStatus[] = [OrgUserStatus.Accepted, OrgUserStatus.PendingSuspension, OrgUserStatus.PendingRemoval];

  constructor(
    private clService: ClassCourseService,
    private http: HttpClient) { }

  /**
 * Retrieves all regular users for a given organization.
 * @param orgId organization ID.
 * @returns an array of regular users associated with the organization.
 */
  getRegularUsersForOrg(orgId: number, status?: OrgUserStatus): Observable<SchoolUser[]> {
    const body: any = { org_id: orgId };
    status ? body.status_code = status : null;
    // if (status !== undefined) body.status_code = status;

    return this.http.post<SchoolUser[]>(`${this.apiOrganizationUrl}/user/get/organization`, body, httpOptionsNoToken).pipe(
      map(users => users.map(user => this.deserializeRegularUser(user))), // Transform API response into SchoolUser instances
      //tap(users => console.log('Fetched regular users for:', users)) // Log fetched users
    );
  }

  /**
   * Fetches all pending users for a given organization.
   * @param orgId organization ID.
   * @returns an observable of pending users associated with the organization.
   */
  getPendingUsersForOrg(orgId: number): Observable<PendingUser[]> {
    const body = { org_id: orgId, status_code: OrgUserStatus.Pending };

    return this.http.post<PendingUser[]>(`${this.apiOrganizationUrl}/user/get/organization`, body, httpOptionsNoToken).pipe(
      map(users => users.map(user => this.deserializePendingUser(user))),
      //tap(users => console.log('Fetched pending users for:', users))
    );
  }

  /**
   * Fetches all accepted users for a given organization.
   * @param orgId organization ID.
   * @returns an observable of accepted users associated with the organization.
   */
  getAcceptedUsersForOrg(orgId: number): Observable<PendingUser[]> {
    const body = { org_id: orgId, status_code: OrgUserStatus.Accepted };

    return this.http.post<PendingUser[]>(`${this.apiOrganizationUrl}/user/get/organization`, body, httpOptionsNoToken).pipe(
      map(users => users.map(user => this.deserializePendingUser(user))),
      //tap(users => console.log('Fetched accepted users for:', users))
    );
  }

  /**
   * Fetches all class users for a given class ID.
   * @param classId class ID.
   * @returns an observable of class users for the given class.
   */
  getClassUsers(classId: number): Observable<ClassUser[]> {
    const body = { class_id: classId };

    return this.http.post<ClassUser[]>(`${this.apiOrganizationUrl}/user/get/class`, body, httpOptionsNoToken).pipe(
      map(users => users.map(user => {
        // console.log(this.deserializeClassUser(user))
        return this.deserializeClassUser(user)
      }),
        //tap(users => console.log('Fetched class users for class ID:', users))
      ));
  }

  /**
   * Retrieves a regular user by their user ID.
   * @param userId ID of the user to fetch.
   * @returns an observable of the deserialized user.
   */
  getRegularUserById(userId: number): Observable<SchoolUser | null> {
    const body = { user_id: userId };

    return this.http.post<SchoolUser>(`${this.apiOrganizationUrl}/user/get/id`, body, httpOptionsNoToken).pipe(
      map(user => this.deserializeRegularUser(user)),
      //tap(user => console.log('Fetched user by ID:', user))
    );
  }

  // deserialize JSON object into User or SchoolUser class instance
  deserializeRegularUser(user: any): SchoolUser | null {
    return new SchoolUser(
      user.userId,
      user.profileName,
      user.username,
      user.email,
      user.cellphone,
      user.orgId,
      user.primaryGroup ?? null,
      user.secondaryGroup ?? null,
      user.pfpLink,
      user.userStatus as OrgUserStatus,
      new Date(user.requestDate)
    );

    return null;
  }

  /**
 * Updates the status of multiple users.
 * @param userIds - Array of user IDs whose status needs to be updated.
 * @param status - The new status to be set for the users.
 * @returns A promise resolving to 1 for success or 0 for failure.
 */
  setUserStatus(userIds: number[], status: number): Promise<number> {
    const body = {
      userStatus: status,
      userId: JSON.stringify(userIds) // Serialize the user IDs array
    };

    return firstValueFrom(
      this.http.put<number>(`${this.apiOrganizationUrl}/user/state/mod`, body, httpOptionsNoToken).pipe(
        //tap((res) => console.log('Updated user status response:', res)) // Log response for debugging
      )
    );
  }

  /**
 * Fetches the total count of users for a specific organization.
 * @param orgId - The organization ID for which the user count is required.
 * @returns A promise resolving to the total number of users.
 */
  getOrgUserCount(orgId: number): Promise<number> {
    const body = { org_id: orgId }; // Send organization ID in the request body

    return firstValueFrom(
      this.http.post<{ user_count: number }>(`${this.apiOrganizationUrl}/user/count`, body, httpOptionsNoToken).pipe(
        map((res) => res.user_count), // unpack the object and return the user_count value
        //tap((userCount) => console.log('Fetched user count:', userCount)) // log the unpacked user count
      )
    );
  }

  deserializePendingUser(user: any): PendingUser | null {
    if (!user) {
      return null;
    }

    return new PendingUser(
      user.userId,
      user.profileName,
      user.username,
      user.email,
      user.cellphone,
      user.orgId,
      user.pfpLink,
      user.userStatus as OrgUserStatus,
      false,
      new Date(user.requestDate)
    );
  }

  deserializeClassUser(user: any): ClassUser | null {
    return new ClassUser(
      user.userId,
      user.profileName,
      user.username,
      user.email,
      user.cellphone,
      user.orgId,
      null,
      null,
      user.pfpLink,
      user.userStatus as OrgUserStatus,
      new Date(user.lastWorkoutDate),
      user.mostFrequentWorkout,
      user.fitnessLevel,
      user.missionStatus === true ? UserMissionStatus.OK : UserMissionStatus.Bad,
      user.levelUp,
      false
    );
  }
}
