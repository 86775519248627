import { Component, ElementRef, OnDestroy, OnInit, AfterViewInit, ViewChild, HostListener, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MatTabGroup } from "@angular/material/tabs";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { OrgAdmin } from "src/app/shared/models/OrgAdmin";
import { OrgType } from "src/app/shared/models/Organization";
import { Organization } from "src/app/shared/models/Organization";
import { OrganizationService } from "src/app/shared/services/organization.service";
import { AdminHeaderService } from "src/app/shared/services/admin-header.service";

@Component({
  selector: 'app-admin-my-page-header',
  templateUrl: './admin-my-page-header.component.html',
  styleUrl: './admin-my-page-header.component.css'
})
export class AdminMyPageHeaderComponent {  
  user: OrgAdmin;
  org: Organization;
  isLoggedIn: boolean;
  adminHeaderText: SafeHtml;

  constructor(
    public route: ActivatedRoute, 
    private sanitizer: DomSanitizer, 
    public router: Router,
    public orgService: OrganizationService,
    private admHeaderService: AdminHeaderService
  ) {}

  async ngOnInit() {
    this.org = this.orgService.getOrganizationForAdminUser();
    this.isLoggedIn = this.org !== null;
    
    // subscribe to admin header text changes
    this.admHeaderService.currentHeaderText.subscribe(text => {
      this.adminHeaderText = this.sanitizer.bypassSecurityTrustHtml(text);
    })
  }
}
