import { User, PrimaryGroup, SecondaryGroup, OrgUserStatus } from "./User";


export class SchoolUser extends User {
  private primaryGroup: PrimaryGroup; // n학년
  private secondaryGroup: SecondaryGroup; //  m반
  private requestDate: Date;

  constructor(
    userId: number,
    fullname: string,
    username: string,
    email: string,
    cellphone: string,
    orgId?: number,
    primaryGroup?: PrimaryGroup,
    secondaryGroup?: SecondaryGroup,
    pfpLink?: string,
    userStatus?: OrgUserStatus,
    requestDate?: Date
  ) {
    super(userId, fullname, username, email, cellphone, orgId, pfpLink, userStatus);
    this.primaryGroup = primaryGroup;
    this.secondaryGroup = secondaryGroup;
    this.requestDate = requestDate;
  }

  public getPrimaryGroup(): PrimaryGroup {
    return this.primaryGroup;
  }

  public get primaryGroupName(): string {
    return this.primaryGroup.name || '';
  }

  public getSecondaryGroup(): SecondaryGroup {
    return this.secondaryGroup;
  }

  public get secondaryGroupName(): string {
    return this.secondaryGroup.name || '';
  }

  public setPrimaryGroup(PrimaryGroup: PrimaryGroup): void {
    this.primaryGroup = PrimaryGroup;
  }

  public setPrimaryGroupName(pgName: string): void {
    this.primaryGroup.name = pgName;
  }

  public setSecondaryGroup(SecondaryGroup: SecondaryGroup): void {
    this.secondaryGroup = SecondaryGroup;
  }

  public setSecondaryGroupName(sgName: string): void {
    this.secondaryGroup.name = sgName;
  }

  public getRequestDate(): Date {
    return this.requestDate;
  }

  public setRequestDate(requestDate: Date): void {
    this.requestDate = requestDate;
  }
}
