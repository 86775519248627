import { Component, ElementRef, OnInit, AfterViewInit, ViewChild, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { User } from "src/app/shared/models/User";
import { Organization } from "src/app/shared/models/Organization";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { AdminService } from "src/app/shared/services/admin.service";
import { OrganizationService } from "src/app/shared/services/organization.service";
// 헤더 텍스트 설정
import { AdminHeaderService } from "src/app/shared/services/admin-header.service";

import { trigger, state, style, animate, transition } from '@angular/animations';

// 참조영상 재생
import { first, firstValueFrom } from "rxjs";
import { s3Url } from "src/app/shared";
import { VodService } from "src/app/shared/services/vod.service";
import { Vod, VodListCategory, VodCategory } from "../../../shared/models/vod";
import Player from '@vimeo/player';
import { RegularUserService } from "src/app/shared/services/regular-user.service";
import { ClassCourseService } from "src/app/shared/services/class-course.service";
import { BoardService } from "src/app/shared/services/board.service";

@Component({
  selector: "app-body",
  templateUrl: "./body.component.html",
  styleUrls: ["./body.component.css"],
  animations: [
    trigger('toggleDiv', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.3s ease', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('0.3s ease', style({ opacity: 0 })),
      ]),
    ]),
  ]
})

export class BodyComponent implements OnInit, AfterViewInit {
  /** ViewChild decorators for 공지사항 / 서식자료 board paginators */
  @ViewChild('noticePaginator') noticePaginator!: MatPaginator;
  @ViewChild('templatesPaginator') templatesPaginator!: MatPaginator;

  /** ViewChildren decorators for 참조영상 container */
  private videoElement: ElementRef;
  @ViewChild('myVideo', { static: false }) set content(content: ElementRef) {
    if (content) {
      this.videoElement = content;
      if (this.playSource) {
        this.initializeVideoPlayer();
      }
    }
  }

  user: User;

  // 공지사항 board
  noticeBoard: Notice[];
  pinnedNotices: Notice[];
  unpinnedNotices: Notice[];
  slicedNotices: Notice[] = [];
  noticeBoardDataSource: MatTableDataSource<Notice>;

  noticeLoading: boolean = false;
  noticeLoadingError: boolean = false;

  // 서식자료 board
  templatesBoard: Notice[];
  pinnedTemplates: Notice[];
  unpinnedTemplates: Notice[];
  slicedTemplates: Notice[] = [];
  templatesBoardDataSource: MatTableDataSource<Notice>;

  noticeDisplayedColumns: string[] = ['title', 'click_count', 'author'];

  templatesLoading: boolean = false;
  templatesLoadingError: boolean = false;

  // organization of current signed in user
  org: Organization;
  // class list for organization
  classCount: number = 0;
  pendingUserCount: number = 0;
  activeUserCount: number = 0;
  isLoggedIn: boolean;
  // dropdown toggle for stats card at bottom of page
  showStats: boolean = false;

  @Input() open: boolean = false;
  @Input() playSource: string = '';
  videoPlayer: any;
  vodCategory: number = 100;
  vodCategoryList: VodCategory[] = [];
  originalVodList: { id: number, title: string, thumbnail: string, preview: string }[] = [];
  vodListCategory: { id: number; name: string; vod_list: { id: number, title: string, thumbnail: string, preview: string }[] } = {
    id: 0,
    name: '',
    vod_list: []
  };
  selectedVod: Vod = {
    id: 0,
    title: "",
    category_id: 0,
    content: "",
    content_vod: "",
    preview: "",
    thumbnail: "",
    level: 0,
    gangsa: "",
    vod_min: 0,
    favorite: false,
    save_time: 0
  };

  loading: boolean = true;
  loadingFirst: boolean = true;
  continue: boolean = false;
  userView: boolean = false;

  canPlayEvent: boolean = false;
  // boolean to indicate if Vimeo player script has been loaded
  vimeoScriptLoaded: boolean = false;
  players: Player[] = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private regUserService: RegularUserService,
    private userService: AdminService,
    private orgService: OrganizationService,
    private classService: ClassCourseService,
    private admHeaderService: AdminHeaderService,
    private vodService: VodService,
    private boardService: BoardService,
  ) { }


  async ngOnInit(): Promise<void> {
    // fetch current login session and data
    this.user = this.userService.getCurrentAdminSession();
    this.org = this.orgService.getOrganizationForAdminUser();

    // set admin header text
    this.admHeaderService.setHeaderText('지금 바로 T-BOX 사용현황을 확인하세요.');

    try {
      await this.regUserService.getPendingUsersForOrg(this.org.getId()).subscribe(
        (pendingUsers) => {
          this.pendingUserCount = pendingUsers.length

        }
      );
    } catch (error) {
      console.log('error fetching pending user count for organization.');
      this.pendingUserCount = 0;
    }

    try {
      await this.classService.getOpenClassesForOrg(this.org.getId()).subscribe(
        (classes) => {
          this.classCount = classes.length;
        }
      );
    } catch (error) {
      console.log('error fetching pending class count for organization.');
      this.classCount = 0;
    }

    try {
      await this.regUserService.getAcceptedUsersForOrg(this.org.getId()).subscribe(
        (acceptedUsers) => (this.activeUserCount = acceptedUsers.length)
      );
    } catch (error) {
      console.log('error fetching active user count for organization.');
      this.activeUserCount = 0;
    }

    await this.loadNotice();

    await this.loadTemplates();

    try {
      await this.loadVod();
    } catch (error) {
      console.log('error fetching VOD data:', error);
    }

    this.loading = false;
  }

  async loadNotice(): Promise<void> {
    this.noticeLoading = true;

    try {
      this.noticeBoard = await firstValueFrom(this.boardService.getNoticeList());
      this.noticeBoardDataSource = new MatTableDataSource<Notice>(this.noticeBoard);
      this.pinnedNotices = this.noticeBoard.filter(notice => notice.pin).slice(0, 3);
      this.unpinnedNotices = this.noticeBoard.filter(notice => !notice.pin).slice(0, 2);
      this.slicedNotices = [...this.pinnedNotices, ...this.unpinnedNotices];
      this.noticeLoadingError = false;
    } catch (error) {
      console.log('error loading notices:', error);
      this.noticeLoadingError = true;
    }

    this.noticeLoading = false;
  }

  async loadTemplates(): Promise<void> {
    this.templatesLoading = true;

    try {
      this.templatesBoard = await firstValueFrom(this.boardService.getResourcesList());
      this.templatesBoardDataSource = new MatTableDataSource<Notice>(this.templatesBoard);
      this.pinnedTemplates = this.templatesBoard.filter(template => template.pin).slice(0, 3);
      this.unpinnedTemplates = this.templatesBoard.filter(template => !template.pin).slice(0, 2);
      this.slicedTemplates = [...this.pinnedTemplates, ...this.unpinnedTemplates];
      this.templatesLoadingError = false;
    } catch (error) {
      console.log('error loading templates:', error);
      this.templatesLoadingError = true;
    }

    this.templatesLoading = false;
  }

  ngAfterViewInit(): void {
    if (!this.vimeoScriptLoaded) {
      this.loadVimeoScript();
    }

    if (this.videoElement) {
      this.initializeVideoPlayer();
    }
  }

  openNotice() {
    this.router.navigate(['/community', { type: 'nf' }]);
  }

  openNoticeWithId(id: number): void {
    this.router.navigate(['/community', { type: 'nf', id: id }]);
  }

  rotateChevron(chevronId: string, isDropdownOpen: boolean): void {
    const chevron = document.getElementById(chevronId);
    if (chevron) {
      chevron.style.transform = isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)';
      chevron.style.transition = 'transform 0.3s ease';
    }
  }

  toggleStatsDropdown(): void {
    this.showStats = !this.showStats;
    this.rotateChevron('statsChevron', this.showStats);
  }

  openTemplates() {
    this.router.navigate(['/community', { type: 're' }]);
  }

  openTemplateWithId(id: number): void {
    this.router.navigate(['/community', { type: 're', id: id }]);
  }

  async loadVod(): Promise<void> {
    // initialize 참조영상 loading logic

    try {
      this.vodCategoryList = await firstValueFrom(this.vodService.getVodCategoryList(this.vodCategory));
      // only fetch data from Category '100' for now
    } catch (error) {
      console.log('error fetching VOD category:', error);
      this.vodCategoryList = [];
    }

    try {
      this.vodListCategory = await firstValueFrom(this.vodService.getVodListCategory(this.vodCategoryList[0]?.id));
    } catch (error) {
      console.log('error fetching VOD list for selected category:', error);
      this.vodListCategory = null;
    }

    try {
      this.selectedVod = await firstValueFrom(this.vodService.getVodData(this.vodListCategory.vod_list[0]?.id));
    } catch (error) {
      console.log('error fetching data for selected VOD:', error);
      this.selectedVod = null;
    }

    if (!this.selectedVod) {
      this.selectedVod.thumbnail = '../../../../assets/images/tboxfit_logo.svg';
    } else {
      this.selectedVod.thumbnail = s3Url + this.selectedVod?.thumbnail
    }

    if (this.vodListCategory.vod_list && this.vodListCategory.vod_list.length > 0) {
      this.vodListCategory.vod_list.forEach(data => {
        data.thumbnail = s3Url + data.thumbnail;
      });
    }

    this.initVod();
    this.initializeVideoPlayer();
  }

  initVod(): void {
    const regex = /\/(\d{9})([/.])/;

    if (this.selectedVod) {
      this.playSource = this.selectedVod.preview;
      // extract 9-digit numerical Vimeo video ID from URL
      const match = this.playSource.match(regex);
      this.playSource = match ? ('https://player.vimeo.com/video/' + match[1]) : this.playSource;
      this.userView = true;
    } else {
      const match = this.selectedVod.preview.match(regex);
      this.playSource = match ? ('https://player.vimeo.com/video/' + match[1]) : this.playSource;
    }
    // console.log(this.playSource);
  }

  loadVimeoScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if ((window as any).Vimeo) {
        resolve();
      } else {
        const script = document.createElement('script');
        script.src = 'https://player.vimeo.com/api/player.js';
        script.async = true;
        script.onload = () => {
          this.vimeoScriptLoaded = true;
          resolve();
        };
        script.onerror = () => reject(new Error('failed to load Vimeo script.'));
        document.head.appendChild(script);
      }
    });
  }

  initializeVideoPlayer(): void {
    if (this.videoPlayer) {
      this.videoPlayer.destroy();
    }

    if (!this.vimeoScriptLoaded) {
      this.loadVimeoScript().then(() => {
        // wait for Vimeo script to fully load
        this.initializePlayer();
      }).catch((error) => {
        console.error('failed to load Vimeo script:', error);
      });
    } else {
      this.initializePlayer();
    }
  }

  initializePlayer(): void {
    console.log('initializing video player with source:', this.playSource);

    if (!this.loading && this.videoElement) {
      const options = {
        url: this.playSource,
        width: '100%',
        height: '100%'
      };

      if (this.videoElement.nativeElement) {
        // safely access Vimeo Player only after script is fully loaded
        this.videoPlayer = new (window as any).Vimeo.Player(this.videoElement.nativeElement, options);
      }
    }
  }

  openVideo(vodId: number) {
    this.router.navigate([`/vod/${vodId}`]);
  }

  openRefVids() {
    this.router.navigate(['/vod']);
  }

  navigateToManageUsers() {
    this.router.navigate(['/manage/users/accept']);
  }

  navigateToManageClasses() {
    this.router.navigate(['/manage/classes']);
  }

  navigateToManageParticipants() {
    this.router.navigate(['/manage/users']);
  }
}

export interface Notice {
  id: number;
  title: string;
  click_count: number;
  pin: boolean;
  is_hidden: boolean;
  create_date: string;
}

export interface NoticeBody {
  id: number,
  content: string,
  is_hidden: boolean,
  create_date: String
};
