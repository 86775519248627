// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.days-of-week-picker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
    margin: 0px 0;
}

.day-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.1s ease;
    border: 1px solid rgb(200, 200, 200); /* to make circle visible */
}

.day-item span {
    font-size: 1.2em;
    font-weight: bold;
}

.day-item:first-of-type {
    color: #f00;
}

.day-item.selected:first-of-type {
    background-color: rgba(255, 0, 0, 1);
}

.day-item:last-of-type {
    color: #00f;
}

.day-item.selected:last-of-type {
    background-color: rgba(0, 0, 255, 1);
}

.day-item.selected {
    background-color: #fec40d;
    color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/app/layout-kr/components/days-of-week-picker/days-of-week-picker.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,QAAQ;IACR,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;IACtC,oCAAoC,EAAE,2BAA2B;AACrE;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".days-of-week-picker {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    gap: 3px;\r\n    margin: 0px 0;\r\n}\r\n\r\n.day-item {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: 35px;\r\n    height: 35px;\r\n    border-radius: 50%;\r\n    cursor: pointer;\r\n    transition: background-color 0.1s ease;\r\n    border: 1px solid rgb(200, 200, 200); /* to make circle visible */\r\n}\r\n\r\n.day-item span {\r\n    font-size: 1.2em;\r\n    font-weight: bold;\r\n}\r\n\r\n.day-item:first-of-type {\r\n    color: #f00;\r\n}\r\n\r\n.day-item.selected:first-of-type {\r\n    background-color: rgba(255, 0, 0, 1);\r\n}\r\n\r\n.day-item:last-of-type {\r\n    color: #00f;\r\n}\r\n\r\n.day-item.selected:last-of-type {\r\n    background-color: rgba(0, 0, 255, 1);\r\n}\r\n\r\n.day-item.selected {\r\n    background-color: #fec40d;\r\n    color: #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
