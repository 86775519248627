// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body_bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  background: linear-gradient(45deg, #000000, #434343);
  background: url('bg_logo1.jpg') top right;
  background-size: cover;
  background-repeat: no-repeat;
}

.wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 280px);
}

.text_box{
  color: #d2d2d2;
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  padding: 15px;
  font-size: 14px;
  text-align: left;
}

.text_box p{
  padding: 15px 0;
}

.text_box span{
  color: #f44336;
}

hr{
  background-color: #d2d2d2;
}
`, "",{"version":3,"sources":["webpack://./src/app/layout-kr/components/delete-account/account-deleted/account-deleted.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,WAAW;EACX,oDAAoD;EACpD,yCAAoE;EACpE,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".body_bg{\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  z-index: -2;\r\n  background: linear-gradient(45deg, #000000, #434343);\r\n  background: url(../../../../../assets/images/bg_logo1.jpg) top right;\r\n  background-size: cover;\r\n  background-repeat: no-repeat;\r\n}\r\n\r\n.wrap{\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: calc(100vh - 280px);\r\n}\r\n\r\n.text_box{\r\n  color: #d2d2d2;\r\n  border: 1px solid #d2d2d2;\r\n  border-radius: 6px;\r\n  padding: 15px;\r\n  font-size: 14px;\r\n  text-align: left;\r\n}\r\n\r\n.text_box p{\r\n  padding: 15px 0;\r\n}\r\n\r\n.text_box span{\r\n  color: #f44336;\r\n}\r\n\r\nhr{\r\n  background-color: #d2d2d2;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
