import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpParams } from '@angular/common/http';
import { Observable, ObservableInput } from 'rxjs';
import { retry } from 'rxjs/operators';
import { BodyComponent } from 'src/app/layout-kr/components/body/body.component';
import { environment } from 'src/environments/environment';

const httpOptionsNoToken = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  apiSubscriptionUrl = environment.apiUrl + '/subscription';

  constructor(
    private http: HttpClient,
  ) { }
  returnUrl() {
    return this.apiSubscriptionUrl;
  }
  
  getSubscriptionList(): Observable<any> {
    return this.http.get(this.apiSubscriptionUrl + '/', httpOptionsNoToken);
  }

  getSubscriptionUserList(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
        'Content-Type': 'application/json',
      })
    }
    return this.http.get(this.apiSubscriptionUrl + '/user', header);
  }

  getSubscriptionUserDetail(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
        'Content-Type': 'application/json',
      })
    }
    return this.http.get(this.apiSubscriptionUrl + '/user/detail', header);
  }

  getSubscriptionBillingKey(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
        'Content-Type': 'application/json',
      })
    }
    return this.http.get(this.apiSubscriptionUrl + '/billing_key', header);
  }

  unregistSubscription(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
        'Content-Type': 'application/json',
      })
    }
    const body = { }
    return this.http.post(this.apiSubscriptionUrl + '/user/unregist', body, header);
  }

  getSubscriptionReceiptList(): Observable<any> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': JSON.parse(localStorage.getItem('token'))?.access,
        'Content-Type': 'application/json',
      })
    }
    return this.http.get(this.apiSubscriptionUrl + '/receipt', header);
  }

}