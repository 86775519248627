// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#class-name, #class-intro-main {
  border-bottom: 1px solid rgb(204, 204, 204) !important;
}

.pagination-action-bar {
  margin: 25px 0 10px !important;
}

.class-date-div {
  margin-top: 15px !important;
}

.modal-icon-container {
  width: 134px;
  height: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/app/layout-kr/components/create-class/create-class.component.css"],"names":[],"mappings":"AAAA;EACE,sDAAsD;AACxD;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,uBAAuB;AACzB","sourcesContent":["#class-name, #class-intro-main {\r\n  border-bottom: 1px solid rgb(204, 204, 204) !important;\r\n}\r\n\r\n.pagination-action-bar {\r\n  margin: 25px 0 10px !important;\r\n}\r\n\r\n.class-date-div {\r\n  margin-top: 15px !important;\r\n}\r\n\r\n.modal-icon-container {\r\n  width: 134px;\r\n  height: auto !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
