import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { DayOfWeek } from 'src/app/shared/models/ClassInfo';

@Component({
  selector: 'app-days-of-week-picker',
  templateUrl: './days-of-week-picker.component.html',
  styleUrl: './days-of-week-picker.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DaysOfWeekPickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DaysOfWeekPickerComponent),
      multi: true
    }
  ]
})
export class DaysOfWeekPickerComponent {
  @Input() formControl: FormControl;
  selectedDays: DayOfWeek[] = [];

  everyDayToggled: boolean = false;

  daysOfWeek = [
    { label: '일', value: DayOfWeek.Sunday },
    { label: '월', value: DayOfWeek.Monday },
    { label: '화', value: DayOfWeek.Tuesday },
    { label: '수', value: DayOfWeek.Wednesday },
    { label: '목', value: DayOfWeek.Thursday },
    { label: '금', value: DayOfWeek.Friday },
    { label: '토', value: DayOfWeek.Saturday }
  ];

  // register callback for when form value changes
  private onChange: (value: DayOfWeek[]) => void = () => {};
  private onTouched: () => void = () => {};

  /**
   * Called by Angular to write a value to the component.
   * @param {DayOfWeek[]} days - The array of selected days of the week.
   */
  writeValue(days: DayOfWeek[]): void {
    if (days) {
      this.selectedDays = [...days];
    }
  }

  /**
   * Registers a callback function to notify Angular when the component's value has changed.
   * @param {Function} fn - Callback function that will be called with the new value.
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function to notify Angular when the component is touched.
   * @param {Function} fn - Callback function to call when touched.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Toggles the selection of a day of the week.
   * @param {DayOfWeek} day - The day of the week to toggle.
   */
  toggleDay(day: DayOfWeek): void {
    const index = this.selectedDays.indexOf(day);

    if (this.selectedDays.length === 1) { // deselect day picker to select all 7 days of the week (free mode)
      if (index === -1) {
        this.selectedDays.push(day);
      } else {
        this.selectedDays = [];
        const week = [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday, DayOfWeek.Saturday, DayOfWeek.Sunday];
        week.forEach(day => this.selectedDays.push(day));
        this.everyDayToggled = true;
      }
    } else if (this.selectedDays.length === 7 && this.everyDayToggled) { // reset day picker if it is deselected while in free mode
      this.selectedDays = [];
      this.selectedDays.push(day);
      this.everyDayToggled = false;
    } else {
      if (index === -1) {
        this.selectedDays.push(day);
      } else {
        this.selectedDays.splice(index, 1);
      }
    }

    this.onChange(this.selectedDays);
    this.onTouched();
  }

  /**
   * Validates the form control for required selected days.
   * @param {AbstractControl} control - The form control to validate.
   * @returns {{ [key: string]: any } | null} Validation errors or null if valid.
   */
  validate(control: AbstractControl): { [key: string]: any } | null {
    return null;
    // return this.selectedDays.length > 0 ? null : { required: true }
  }
}
