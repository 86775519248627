// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page404{
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #eceef0;
  z-index: 10;
}

.error-template{
  position: relative;
  top: calc(50% - 15%);
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1, h2{
  color: #212935;
  font-size: 37px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/no-page-found/no-page-found.component.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,eAAe;EACf,MAAM;EACN,OAAO;EACP,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".page404{\r\n  margin: 0;\r\n  padding: 0;\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  height: 100vh;\r\n  width: 100vw;\r\n  background-color: #eceef0;\r\n  z-index: 10;\r\n}\r\n\r\n.error-template{\r\n  position: relative;\r\n  top: calc(50% - 15%);\r\n  height: 30%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n\r\nh1, h2{\r\n  color: #212935;\r\n  font-size: 37px;\r\n  font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
