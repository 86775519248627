import { NativeDateAdapter } from "@angular/material/core";
import { Injectable } from "@angular/core";

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    // override format method to return custom formats
    override format(date: Date, displayFormat: string) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        if (displayFormat === 'input') {
            // for input field display as YYYY.MM.DD
            return `${year}.${month}.${day}`;
        } else if (displayFormat === 'monthYearLabel') {
            // for month-year selector header
            return `${year}.${month}`;
        }

        return `${year}.${month}.${day}`;
    }

    override parse(value: any): Date | null {
        if ((typeof value === 'string') && value.length === 10) {
            const [year, month, day] = value.split('.').map(Number);
            return new Date(year, month - 1, day);
        }
        return value ? new Date(value) : null;
    }
}
