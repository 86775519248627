// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrap {
	max-width: 1080px;
	margin: 50px auto 0;
	padding: 1px 10px 50px;
}

.tab_tit {
	margin: 50px 0 30px;
	border-bottom: 2px solid #d2d2d2;
}

.tab_tit h3 {
	color: #434343;
}

img {
	max-width: 100%;
	margin: auto;
	display: block;
}

:host::ng-deep .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
	color: #fec40d;
	font-weight: 800;
}

:host::ng-deep .mat-mdc-tab-body-content {
	overflow: hidden;
}

`, "",{"version":3,"sources":["webpack://./src/app/layout-kr/components/info/about-us/about-us.component.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,mBAAmB;CACnB,sBAAsB;AACvB;;AAEA;CACC,mBAAmB;CACnB,gCAAgC;AACjC;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,eAAe;CACf,YAAY;CACZ,cAAc;AACf;;AAEA;CACC,cAAc;CACd,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".wrap {\r\n\tmax-width: 1080px;\r\n\tmargin: 50px auto 0;\r\n\tpadding: 1px 10px 50px;\r\n}\r\n\r\n.tab_tit {\r\n\tmargin: 50px 0 30px;\r\n\tborder-bottom: 2px solid #d2d2d2;\r\n}\r\n\r\n.tab_tit h3 {\r\n\tcolor: #434343;\r\n}\r\n\r\nimg {\r\n\tmax-width: 100%;\r\n\tmargin: auto;\r\n\tdisplay: block;\r\n}\r\n\r\n:host::ng-deep .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {\r\n\tcolor: #fec40d;\r\n\tfont-weight: 800;\r\n}\r\n\r\n:host::ng-deep .mat-mdc-tab-body-content {\r\n\toverflow: hidden;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
