import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table"
import { MatPaginator } from "@angular/material/paginator";
import { OrgUserStatus } from "src/app/shared/models/User";
import { Organization } from "src/app/shared/models/Organization";
import { ClassUser } from "src/app/shared/models/ClassUser";
import { ClassCourse, } from "src/app/shared/models/ClassCourse";
import { ClassInfo, DayOfWeek } from "src/app/shared/models/ClassInfo";
import { ClassSelectionService } from "../../../../shared/services/class-selection-service";
import { take } from "rxjs";
import { RegularUserService } from "src/app/shared/services/regular-user.service";
import { ClassCourseService } from "src/app/shared/services/class-course.service";

@Component({
  selector: "app-add-users",
  templateUrl: "./add-users.component.html",
  styleUrls: ["./add-users.component.css",
    "../participant-list/participant-list.component.css",
    "../manage-classes.component.css"]
})
export class AddUsersComponent implements OnInit {
  // currently selected class and its class info
  org: Organization | null = null;
  selectedClass: ClassCourse | null = null;
  selectedClassInfo: ClassInfo | null = null;
  isMyClass: boolean = false;

  // Arrays to hold participant list for currently selected class
  userList: ClassUser[] = [];

  /* AddUserList */
  // ViewChild decorator for AddUserList paginator (참여자 추가 리스트 페이지네이션)
  @ViewChild(MatPaginator) modalPaginator!: MatPaginator;

  // ViewChild decorator for AddUser search bar (사용자 추가 검색창) 
  private modalSearchInputEl: ElementRef;
  @ViewChild('modalSearchInput', { static: false }) set modalSearchInput(modalSearchInput: ElementRef) {
    this.modalSearchInputEl = modalSearchInput;
  }

  // ViewChild decorator for AddUser search reset button (추가 사용자 검색 초기화 버튼)
  private modalResetBtnEl: ElementRef;
  @ViewChild('modalResetBtn', { static: false }) set modalResetBtn(modalResetBtn: ElementRef) {
    this.modalResetBtnEl = modalResetBtn;
  }

  // Arrays to hold AddUserList for currently selected class
  addUserList: ClassUser[] = [];
  filteredAddUserList: ClassUser[] = [];
  paginatedAddUserList: ClassUser[] = [];

  activeModalColumn: string = 'name';
  addUserSortDirection: 'asc' | 'desc' = 'asc';

  // columns for AddUserList
  modalDisplayedColumns: string[] = ['modal_select', 'modal_name', 'modal_id', 'modal_email', 'modal_pgName', 'modal_sgName'];
  modalDataSource = new MatTableDataSource<ClassUser>([]);

  modalPage: number = 1; // current page in modal
  modalPageSize: number = 10; // number of items per page in modal
  modalPageSizeOptions: number[] = [10, 20, 30, 50];

  // Array to hold items selected by checkbox(es)
  // 체크박스로 선택된 항목 저장
  candidates: ClassUser[] = [];
  sortedCandidateName: string = '';

  submissionValid: boolean = true;
  loading: boolean = false;

  constructor(
    private regUserService: RegularUserService, // 일반 사용자 data fetch API
    private clsSelectionService: ClassSelectionService, // selectedClass fetch API
    private clService: ClassCourseService
  ) { }

  // Booleans for toggling modals
  modalStates = {
    modal: false, step2Modal: false, step3Modal: false
  };

  // Getters and setters to activate different modals based on the clicked button
  get activeModal(): boolean {
    return this.modalStates?.modal || false;
  }

  set activeModal(value: boolean) {
    if (this.modalStates) {
      this.modalStates.modal = value;
    }
  }

  get activeStep2Modal(): boolean {
    return this.modalStates?.step2Modal || false;
  }

  set activeStep2Modal(value: boolean) {
    if (this.modalStates) {
      this.modalStates.step2Modal = value;
    }
  }

  get activeStep3Modal(): boolean {
    return this.modalStates?.step3Modal || false;
  }

  set activeStep3Modal(value: boolean) {
    if (this.modalStates) {
      this.modalStates.step3Modal = value;
    }
  }

  async ngOnInit(): Promise<void> {
    // subscribe to selected organization changes
    this.clsSelectionService.org$.subscribe(
      (org) => (this.org = org)
    );

    // subscribe to selectedClass and selectedClassInfo changes
    this.clsSelectionService.selectedClass$.subscribe(
      (cls) => {
        this.selectedClass = cls;
        //console.log(this.selectedClass.getParticipantList())
      }
    );

    // subscribe to userList and userList changes
    this.clsSelectionService.userList$.subscribe(
      (userList) => (this.userList = userList)
    );

    try {
      await this.buildAddUserList(); // load AddUserList to display in modal
      this.sortModalData('fullname');
      this.activeModalColumn = 'fullname';
      this.activeModal = true;
    } catch (error) {
      console.log('Error building AddUserList in OnInit:', error);
    }
  }

  // emit toggle events to parent
  @Output() toggleCloseModalEvent = new EventEmitter<void>();
  @Output() toggleProcessUserEvent = new EventEmitter<ClassUser[]>();
  @Input() toggleRemoveUsersEvent = new EventEmitter<void>();

  async buildAddUserList(): Promise<void> {
    if (!this.selectedClass) {
      return;
    }

    try {
      await this.regUserService.getRegularUsersForOrg(this.org.getId(), OrgUserStatus.Accepted).subscribe(
        (users) => {
          //console.log(users);
          this.addUserList = users.map(
            (user) => {
              return new ClassUser(
                user.getUserId(),
                user.getFullname(),
                user.getUsername(),
                user.getEmail(),
                user.getCellphone(),
                user.getOrgId(),
                user.getPrimaryGroup() ?? null,
                user.getSecondaryGroup() ?? null,
                user.getPfpLink(),
                user.getUserStatus(),
                null,
                null,
                null,
                null,
                false,
                false
              )
            }
          )
          .filter(user => !this.selectedClass.getParticipantList().includes(user.getUserId()));
          this.filteredAddUserList = [...this.addUserList];
          // call updatePaginatedAddList to show the first page
          this.updatePaginatedAddList();
        }
      )
    } catch (error) {
      console.log('Error fetching AddUserList:', error);
    }
  }

  async addUsers(): Promise<void> {
    if (this.candidates.length === 0) {
      return;
    }

    try {
      this.loading = true;
      const candidateIds = this.candidates.map(user => user.getUserId());
      const participantList = this.selectedClass.getParticipantList();
      candidateIds.forEach((id) => {
        if (!participantList.includes(id)) {
          participantList.push(id);
        }
      });
      this.selectedClass.setParticipantList(participantList);
      this.clsSelectionService.setSelectedClass(this.selectedClass);
      await this.clService.addParticipants(this.selectedClass.getClassId(), candidateIds);
      this.loading = false;
      this.submissionValid = true;
    } catch (error) {
      this.loading = false;
      this.submissionValid = false;
      console.log('Error adding class participants:', error);
    }

    this.toggleProcessUserEvent.emit();
    this.gotoStep3();
  }

  /** Functions to handle AddUserList pagination */
  updatePaginatedAddList(): void {
    const startIndex = (this.modalPage - 1) * this.modalPageSize;
    const endIndex = startIndex + this.modalPageSize;
    this.paginatedAddUserList = this.filteredAddUserList.slice(startIndex, endIndex);

    this.modalDataSource.data = [...this.paginatedAddUserList];
  }

  onModalPageChange(newPage: number): void {
    this.modalPage = newPage;
    this.updatePaginatedAddList();
  }

  /**
   * Method to toggle the selection of a candidate.
   * @param user 
   */
  toggleCandidate(user: ClassUser): void {
    if (user.selected) {
      if ((this.candidates.length + this.userList.length) >= this.selectedClass.getCapacity()) {
        user.selected = false; // prevent adding if capacity is reached
        return;
      }
      this.candidates.push(user);
    } else {
      this.candidates = this.candidates.filter(u => u.getUserId() !== user.getUserId());
    }
  }

  /** Method to retrieve the first fullname of candidates sorted in ascending order
   */
  updateSortedCandidates(): void {
    this.candidates.sort((a, b) =>
      a.getFullname().localeCompare(b.getFullname(), 'ko-KR'));
    this.sortedCandidateName = this.candidates[0]?.getFullname() || '';
  }

  gotoConfirm(): void {
    this.gotoStep3();
  }

  gotoStep2(): void {
    this.activeStep2Modal = true;
    this.activeModal = false;

    this.updateSortedCandidates(); // sort candidate list by name in ascending order and retrieve the first element
  }

  gotoStep3(): void {
    this.activeStep3Modal = true;
    this.activeStep2Modal = false;
    this.activeModal = false;
  }

  /* AddUserList select / sort functions */
  /** Method to toggle the 'select all' checkbox
   *  @param event: checkbox button click event
   */
  toggleModalSelectAll(event: any): void {
    const checked = event.checked;

    this.modalDataSource.data.forEach(user => {
      if (checked) {
        // add user to candidates if not alrady in list and capacity not exceeded
        if (!user.selected && (this.candidates.length + this.userList.length) < this.selectedClass.getCapacity()) {
          user.selected = true;
          this.candidates.push(user);
        }
      } else {
        // remove user from candidates if unchecked
        user.selected = false;
        this.candidates = this.candidates.filter(u => u.getUserId() !== user.getUserId());
      }
    });
  }

  /**
   * Method to check if all rows in the AddUserList are selected.
   * @returns true if all items selected, false otherwise
   */
  isModalAllSelected(): boolean {
    return (
      this.paginatedAddUserList.every(user => user.selected) ||
      (this.candidates.length + this.userList.length) >= this.selectedClass.getCapacity()
    );
  }

  isModalIndeterminate(): boolean {
    return this.paginatedAddUserList.some(user => user.selected) && !this.isModalAllSelected()
  }

  /** Method to toggle sorting function for a certain column in the AddUserList
   * @param column string value that represents the column to be sorted
   */
  toggleModalSort(column: string) {
    if (this.activeModalColumn === column) {
      this.addUserSortDirection = this.addUserSortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.activeModalColumn = column;
      this.addUserSortDirection = 'asc';
    }

    this.sortModalData(column)
  }

  /**
   * Method that sorts the AddUserList based on a selected column.
   * @param column column to be sorted
   */
  sortModalData(column: string): void {
    this.filteredAddUserList.sort((a, b) => {
      let valueA = null;
      let valueB = null;

      if (column === 'primaryGroupName') {
        valueA = a.getPrimaryGroup()?.name ?? ''; // fallback to empty string if null/undefined
        valueB = b.getPrimaryGroup()?.name ?? '';
      } else if (column === 'secondaryGroupName') {
        valueA = a.getSecondaryGroup()?.name ?? ''; // fallback to empty string if null/undefined
        valueB = b.getSecondaryGroup()?.name ?? '';
      } else {
        valueA = a[column] ?? ''; // fallback to empty string if null/undefined
        valueB = b[column] ?? '';
      }


      // handle other string columns
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        // split Korean/English part and number part
        const textPartA = valueA.match(/[^\d]+/g)?.[0] || ''; // text part (Korean / English)
        const numberPartA = parseInt(valueA.match(/\d+/g)?.[0] || '0', 10); // number part

        const textPartB = valueB.match(/[^\d]+/g)?.[0] || ''; // text part (Korean / English)
        const numberPartB = parseInt(valueB.match(/\d+/g)?.[0] || '0', 10); // number part

        // first, compare text part using localeCompare with support for both Korean and English
        const textCompare = textPartA.localeCompare(textPartB, 'ko-KR');
        if (textCompare != 0) {
          return this.addUserSortDirection === 'asc' ? textCompare : -textCompare;
        }

        // if text parts are equal, compare the number part numerically
        return this.addUserSortDirection === 'asc' ? numberPartA - numberPartB : numberPartB - numberPartA;
      }

      // fallback to standard comparison for non-string values
      if (this.addUserSortDirection === 'asc') {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    });

    // update paginated AddUserList after sorting
    this.modalPage = 1;
    this.updatePaginatedAddList();
  }

  /* AddUserList search functions */
  modalSearchPerformed: boolean = false;
  modalSearchResultsNotFound: boolean = false;

  /**
   * Method to search AddUserList by name, username, or email
   * @param query input string
   */
  searchAddUserList(query: string) {
    if (this.modalSearchInputEl.nativeElement.value === '') {
      this.resetAddUserList();
      return;
    }

    const lowerQuery = query.toLowerCase();

    this.filteredAddUserList = this.addUserList.filter(user => {
      return (
        user.getFullname().toLowerCase().includes(lowerQuery) ||
        user.getUsername().toLowerCase().includes(lowerQuery) ||
        user.getEmail().toLowerCase().includes(lowerQuery) ||
        user.getCellphone().includes(lowerQuery) ||
        (lowerQuery.includes(user.getSecondaryGroup()?.name) && lowerQuery.includes('반') && !lowerQuery.includes('학년')) ||
        (lowerQuery.includes(user.getPrimaryGroup()?.name) && lowerQuery.includes('학년') && !lowerQuery.includes('반')) ||
        (lowerQuery.includes('학년') && lowerQuery.includes('반') && lowerQuery.includes(user.getPrimaryGroup()?.name) && lowerQuery.includes(user.getSecondaryGroup()?.name))
      );
    });

    if (this.filteredAddUserList.length === 0) {
      this.modalSearchResultsNotFound = true;
      this.modalSearchPerformed = true;
      return;
    }

    this.modalDataSource.data = this.filteredAddUserList;
    this.modalSearchPerformed = true;
    this.modalSearchResultsNotFound = false;

    this.modalPage = 1; // reset to first page
    this.updatePaginatedAddList(); // update AddUserList
  }

  /**
   * Method to render / hide the 'X' reset button if input field is non-empty / empty.
   */
  onModalInputChange(): void {
    this.modalResetBtnEl.nativeElement.style.visibility = this.modalSearchInputEl.nativeElement.value.length > 0 ? 'visible' : 'hidden';
  }

  /** 
   * Method to change pagination paging size for AddUserList.
   * @param event dropdown option selection event
   */
  onModalPageSizeChange(event: any): void {
    this.modalPageSize = event.target.value; // update page size
    this.modalPage = 1; // reset to first page
    this.updatePaginatedAddList();
  }

  /**
   * Method to search AddUserList to its defaults when the reset button is pressed.
   */
  async resetAddUserList(): Promise<void> {
    this.resetModalSearchInput();

    if (!this.modalSearchPerformed) {
      return;
    }

    try {
      await this.buildAddUserList(); // rebuild AddUserList after searching
      this.modalDataSource.data = this.filteredAddUserList;

      this.sortModalData('fullname');
      this.addUserSortDirection === 'desc' ? this.addUserSortDirection = 'asc' : this.addUserSortDirection = 'asc';
    } catch (error) {
      console.log('Error resetting AddUserList:', error);
    }

    this.modalSearchPerformed = false;
  }

  /** Method to reset the AddUserList search bar to an empty field when the reset button is pressed. */
  resetModalSearchInput(): void {
    if (this.modalSearchInputEl.nativeElement === undefined) {
      return;
    }

    if (this.modalSearchResultsNotFound) {
      this.modalSearchResultsNotFound = false;
    }

    if (this.modalSearchInputEl.nativeElement.value.length > 0) {
      this.modalSearchInputEl.nativeElement.value = '';
    }

    if (this.modalResetBtnEl.nativeElement.style.visibility === 'visible') {
      this.modalResetBtnEl.nativeElement.style.visibility = 'hidden';
    }
  }

  /**
   * Method to emit the toggleProcessUser event.
   */
  onToggleProcessUser(): void {
    this.toggleProcessUserEvent.emit(this.candidates);

    this.gotoConfirm();
  }

  /**
   * Method to emit the toggleCancelEdits event.
   */
  onToggleCloseModal(): void {
    this.toggleCloseModalEvent.emit();
  }

  /**
   * Method to scroll to the top with an offset (in pixels)
   * @param offset top offset in pixels
   */
  scrollTop(offset: number): void {
    window.scrollTo({ top: offset, behavior: 'smooth' }); // scroll to top smoothly
  }
}
