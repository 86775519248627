import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  selected;
  constructor(
    public router:Router,
    private route:ActivatedRoute,
  ) { }
  ngOnInit(): void {}
  ngDoCheck()	{
    this.selected = +this.route.snapshot.paramMap.get('id');
  }
}
