import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncompleteGuard implements CanActivate {

  constructor(
    private router: Router,
  ) {}
  
  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
  canActivate() {
    if ( localStorage.getItem('loginStatus') !== 'incomplete' ) {
      return true;
    }
    
    let lang = location.pathname.substr(1,2);
    if( lang === 'kr' ) {
      this.router.navigate(['kr/incomplete']);
    } else if ( lang === 'en' ){
      this.router.navigate(['en/incomplete']);
    }
    return false;
  }
  
}
