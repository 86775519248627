import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table"
import { MatPaginator } from "@angular/material/paginator";
import { OrgUserStatus } from "src/app/shared/models/User";
import { Organization } from "src/app/shared/models/Organization";
import { OrganizationService } from "src/app/shared/services/organization.service";
import { AdminHeaderService } from "src/app/shared/services/admin-header.service";
import { PendingUser } from "../../../shared/models/PendingUser";
import { DatePipe } from "@angular/common";
import { RegularUserService } from "src/app/shared/services/regular-user.service";
import { lastValueFrom } from "rxjs";

@Component({
  selector: 'app-accept-users',
  templateUrl: './accept-users.component.html',
  styleUrl: './accept-users.component.css'
})
export class AcceptUsersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public searchInputEl: ElementRef;
  @ViewChild('searchInput', { static: false }) set searchInput(searchInput: ElementRef) {
    this.searchInputEl = searchInput;
  }

  public resetBtnEl: ElementRef;
  @ViewChild('resetBtn', { static: false }) set resetBtn(resetBtn: ElementRef) {
    this.resetBtnEl = resetBtn;
  }

  constructor(
    private orgService: OrganizationService,
    private regUserService: RegularUserService,
    private admHeaderService: AdminHeaderService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  actionType: string;
  filterStatus: OrgUserStatus[];
  targetStatus: OrgUserStatus;

  get activeModal(): boolean {
    switch (this.actionType) {
      case 'accept':
        return this.acceptModalsOpen[0];
      case 'reject':
        return this.rejectModalsOpen[0];
      default:
        return this.acceptModalsOpen[0];
    }
  }

  set activeModal(value: boolean) {
    switch (this.actionType) {
      case 'accept':
        this.acceptModalsOpen[0] = value;
        break;
      case 'reject':
        this.rejectModalsOpen[0] = value;
        break;
      default:
        this.acceptModalsOpen[0] = value;
        break;
    }
  }

  get activeStep2Modal(): boolean {
    switch (this.actionType) {
      case 'accept':
        return this.acceptModalsOpen[1];
      case 'reject':
        return this.rejectModalsOpen[1];
      default:
        return this.acceptModalsOpen[1];
    }
  }

  set activeStep2Modal(value: boolean) {
    switch (this.actionType) {
      case 'accept':
        this.acceptModalsOpen[1] = value;
        break;
      case 'reject':
        this.rejectModalsOpen[1] = value;
        break;
      default:
        this.acceptModalsOpen[1] = value;
        break;
    }
  }

  modeText: string = '사용';
  statusText: string = '승인';

  displayedColumns: string[] = ['select', 'name', 'id', 'email', 'request_date', 'status'];
  dataSource = new MatTableDataSource<PendingUser>([]);

  page: number = 1; // current page
  pageSize: number = 10; // number of items per page
  pageSizeOptions: number[] = [10, 20, 50, 100, 200, 500];

  pendingUserCount: number = 0;
  userList: PendingUser[] = [];
  filteredUserList: PendingUser[] = [];
  paginatedUserList: PendingUser[] = [];
  sortDirection: 'asc' | 'desc' = 'asc';
  activeColumn: string = 'requestDate';

  org: Organization;

  acceptModalsOpen: boolean[] = [false, false];
  rejectModalsOpen: boolean[] = [false, false];

  candidates: PendingUser[] = [];
  sortedCandidateName: string = '';

  submissionValid: boolean = true;
  loading: boolean = false;
  loadingError: boolean = false;

  ngOnInit(): void {
    this.actionType = 'accept';
    this.setManageMode();
    this.fetchOrg();
    this.buildUserList();
    this.sortData('requestDate');
    this.toggleSort('requestDate'); // default view: show pending user list by request date, from most to least recent
    this.scrollTop(39);
  }

  checkPredicates(val: any, predicates: any[]) {
    if (predicates === null || predicates === undefined) {
      return false;
    }

    for (let pred of predicates) {
      if (val === pred) {
        return true;
      }
    }
    return false;
  }

  /**
   * function to update UI and functionality based on the current route and its associated type
   */
  setManageMode(): void {
    switch (this.actionType) {
      case 'accept':
        this.filterStatus = [OrgUserStatus.Pending, OrgUserStatus.Suspended];
        this.targetStatus = OrgUserStatus.Accepted;
        this.activeModal = this.acceptModalsOpen[0];
        this.activeStep2Modal = this.acceptModalsOpen[1];
        this.modeText = '사용';
        this.statusText = '승인';
        break;
      case 'reject':
        this.filterStatus = [OrgUserStatus.Pending, OrgUserStatus.Suspended];
        this.targetStatus = OrgUserStatus.Rejected;
        this.activeModal = this.rejectModalsOpen[0];
        this.activeStep2Modal = this.rejectModalsOpen[1];
        break;
      default:
        this.filterStatus = [OrgUserStatus.Pending, OrgUserStatus.Suspended];
        this.targetStatus = OrgUserStatus.Accepted;
        this.activeModal = this.acceptModalsOpen[0];
        this.activeStep2Modal = this.acceptModalsOpen[1];
        this.modeText = '사용';
        break;
    }
  }

  fetchOrg(): void {
    this.org = this.orgService.getOrganizationForAdminUser();
  }

  async buildUserList(): Promise<void> {
    this.loading = true;

    try {
      const users = await lastValueFrom(this.regUserService.getRegularUsersForOrg(this.org.getId()));

      this.userList = users
        .filter(user =>
          user.getOrgId() === this.org.getId() &&
          this.checkPredicates(user.getUserStatus(), this.filterStatus)
        )
        .map(user => new PendingUser(
          user.getUserId(),
          user.getFullname(),
          user.getUsername(),
          user.getEmail(),
          user.getCellphone(),
          user.getOrgId(),
          user.getPfpLink(),
          user.getUserStatus(),
          false, // selected
          user.getRequestDate() // requestDate
        ));

      this.filteredUserList = [...this.userList];
      this.pendingUserCount = this.userList.length;

      // update UI
      this.updatePaginatedList();
      this.admHeaderService.setHeaderText(
        `현재 <span style="color: #fec40d;">${this.getPendingUserCount()}</span>명이 ${this.modeText} 요청을 하였습니다.`
      );

      this.loadingError = false; // clear any loading errors
    } catch (error) {
      console.error('Error fetching user list:', error);
      this.loadingError = true;
    }

    this.loading = false;
  }

  dateToString(date: Date): string {
    if (!date) {
      return '--';
    }

    return this.datePipe.transform(date, 'yyyy.MM.dd') || '';
  }

  updatePaginatedList() {
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedUserList = this.filteredUserList.slice(startIndex, endIndex);

    this.dataSource.data = [...this.paginatedUserList];
  }

  onPageChange(newPage: number) {
    this.page = newPage;
    this.updatePaginatedList();
    this.scrollTop(39);
  }

  getNumUsers(): number {
    return this.userList.length;
  }

  getPendingUserCount(): number {
    return this.pendingUserCount;
  }

  /**
   * function to toggle the selection of a candidate
   * @param user 
   */
  toggleCandidate(user: PendingUser): void {
    if (user.selected) {
      this.candidates.push(user);
    } else {
      this.candidates = this.candidates.filter(u => u.getUserId() !== user.getUserId());
    }
  }

  /** function to retrieve the first fullname of candidates sorted in ascending order */
  updateSortedCandidates(): void {
    this.candidates.sort((a, b) =>
      a.getFullname().localeCompare(b.getFullname(), 'ko-KR'));
    this.sortedCandidateName = this.candidates[0]?.getFullname() || '';
  }

  /** function to open candidates accept / reject modal  */
  openModal(modal: string): void {
    this.actionType = modal;
    this.setManageMode();
    this.updateSortedCandidates();

    if (this.candidates.length > 0) {
      this.activeModal = true;
    }
  }

  /** function to close candidates accept / reject modal
   *  @param 'accept' or 'reject'
   */
  closeModal(): void {
    if (this.activeStep2Modal) {
      this.activeStep2Modal = false;
    }

    this.activeModal = false;

    this.candidates = [];

    this.dataSource.data.forEach(user => {
      user.selected = false;
    });

    if (this.isAllSelected()) {
      this.toggleSelectAll({ checked: false });
    }

    this.scrollTop(39);
  }

  /** function to approve selected candidates */
  async processUser(action: 'accept' | 'reject'): Promise<void> {
    if (this.candidates.length === 0) {
      return;
    }

    let targetStatus: OrgUserStatus = OrgUserStatus.Accepted;
    switch (action) {
      case 'accept':
        targetStatus = OrgUserStatus.Accepted;
        break;
      case 'reject':
        targetStatus = OrgUserStatus.Rejected;
        break;
      default:
        targetStatus = OrgUserStatus.Pending;
        break;
    }

    if (action === 'reject') {
      this.candidates = this.candidates.filter(user => user.getUserStatus() !== OrgUserStatus.Suspended);
    }

    try {
      const candidateIds = this.candidates.map(user => user.getUserId());
      await this.regUserService.setUserStatus(candidateIds, targetStatus)
      this.submissionValid = true;
    } catch (error) {
      this.submissionValid = false;
      console.log('Error accepting users:', error);
    }

    this.buildUserList(); // rebuild pending user list after accepting / rejecting candidates
    this.sortData('requestDate'); // default view: show pending user list by request date, from most to least recent
    this.admHeaderService.setHeaderText(`현재 <span style="color: #fec40d;">${this.getPendingUserCount()}</span>명이 ${this.modeText} 요청을 하였습니다.`);
    // live update pending user count on header
    this.gotoConfirm();
  }

  gotoConfirm(): void {
    this.activeStep2Modal = true;
  }

  /* pending user list select/sort functions */
  /** toggles select all checkbox */
  toggleSelectAll(event: any): void {
    const checked = event.checked;

    this.dataSource.data.forEach(user => {
      user.selected = checked;

      // add user to candidates if checked and not already in the list
      if (checked && !this.candidates.find(u => u.getUserId() === user.getUserId())) {
        this.candidates.push(user);
      }

      // remove user from candidates if unchecked
      if (!checked) {
        this.candidates = this.candidates.filter(u => u.getUserId() !== user.getUserId());
      }
    });
  }

  /** checks if all rows are selected */
  isAllSelected(): boolean {
    return this.paginatedUserList.every(user => user.selected);
  }

  /** checks if only some rows are selected */
  isIndeterminate() {
    return this.paginatedUserList.some(user => user.selected) && !this.isAllSelected;
  }

  /**
   * function to assign a text label and color to a status code
   * @param status User Status in integer form
   */
  getStatusLabelAndColor(status: number): { label: string, color: string } {
    switch (status) {
      case OrgUserStatus.Pending:
        return { label: '승인 대기', color: 'rgb(239, 186, 62)' };
      case OrgUserStatus.Accepted:
        return { label: '사용 중', color: 'green' };
      case OrgUserStatus.Rejected:
        return { label: '거절', color: 'red' };
      case OrgUserStatus.Suspended:
        return { label: '사용 중지', color: 'black' };
      default:
        return { label: 'null', color: 'black' };
    }
  }

  /** toggles sorting function for a certain column */
  toggleSort(column: string) {
    if (this.activeColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.activeColumn = column;
      if (column === 'requestDate') {
        this.sortDirection = 'desc'; // sort dates in descending order (most to least recent) by default
      } else {
        this.sortDirection = 'asc';
      }
    }

    this.sortData(column);
  }

  /**
   * function that sorts the user list based on a selected column.
   * @param column column to be sorted
   */
  sortData(column: string): void {
    if (column === 'requestDate') {
      this.sortByRequestDate();
      return;
    }

    this.filteredUserList.sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      // handle other string columns
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        // split Korean/English part and number part
        const textPartA = valueA.match(/[^\d]+/g)?.[0] || ''; // text part (Korean / English)
        const numberPartA = parseInt(valueA.match(/\d+/g)?.[0] || '0', 10) // number part

        const textPartB = valueB.match(/[^\d]+/g)?.[0] || ''; // text part (Korean / English)
        const numberPartB = parseInt(valueB.match(/\d+/g)?.[0] || '0', 10) // number part

        // first, compare text part using localeCompare with support for both Korean and English 
        const textCompare = textPartA.localeCompare(textPartB, 'ko-KR'); // handles both Korean and English
        if (textCompare != 0) {
          return this.sortDirection === 'asc' ? textCompare : -textCompare;
        }

        // if text parts are equal, compare the nubmer part numerically
        return this.sortDirection === 'asc' ? numberPartA - numberPartB : numberPartB - numberPartA;
      }

      // fallback to standard comparison for non-string values 
      if (this.sortDirection === 'asc') {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    });

    // update paginated list after sorting
    this.page = 1;
    this.updatePaginatedList();
  }

  sortByRequestDate(): void {
    this.filteredUserList.sort((a, b) => {
      let valueA = a['requestDate'];
      let valueB = b['requestDate'];

      // handle cases where request date is '--'
      if (a.getUserStatus() === OrgUserStatus.Accepted && b.getUserStatus() !== OrgUserStatus.Accepted) {
        return 1; // push '--' to the bottom
      } else if (a.getUserStatus() !== OrgUserStatus.Accepted && b.getUserStatus() === OrgUserStatus.Accepted) {
        return -1; // keep valid dates above '--'
      } else if (a.getUserStatus() === OrgUserStatus.Accepted && b.getUserStatus() === OrgUserStatus.Accepted) {
        // if both are '--', sort by name as a fallback
        const nameCompare = a.getFullname().localeCompare(b.getFullname(), 'ko-KR');
        return this.sortDirection === 'asc' ? -nameCompare : nameCompare;
      }

      // handle valid dates
      const dateA = valueA;
      const dateB = valueB;

      // compare the dates
      if (this.sortDirection === 'asc') {
        return dateA.getTime() - dateB.getTime(); // ascending order
      } else {
        return dateB.getTime() - dateA.getTime(); // descending order
      }
    });

    // update paginated list after sorting
    this.page = 1;
    this.updatePaginatedList();
  }

  /* pending user list search functions */
  searchPerformed: boolean = false;
  searchResultsNotFound: boolean = false;

  /**
   * function to search pending user list by name, username, or email
   * @param query input string
   */
  searchPendingUserList(query: string) {
    if (this.searchInputEl.nativeElement.value === '') {
      this.resetPendingUserList();
      return;
    }

    const lowerQuery = query.toLowerCase();

    this.filteredUserList = this.userList.filter(user => {
      return (
        user.getFullname().toLowerCase().includes(lowerQuery) ||
        user.getUsername().toLowerCase().includes(lowerQuery) ||
        user.getEmail().toLowerCase().includes(lowerQuery)
      );
    });

    if (this.filteredUserList.length === 0) {
      this.searchResultsNotFound = true;
      this.searchPerformed = true;
      return;
    }

    this.dataSource.data = this.filteredUserList;
    this.searchPerformed = true;
    this.searchResultsNotFound = false;

    this.page = 1; // reset to first page
    this.updatePaginatedList(); // update table
  }

  /**
   * function to render / hide the 'X' reset button if input field is non-empty / empty
   */
  onInputChange() {
    this.resetBtnEl.nativeElement.style.visibility = this.searchInputEl.nativeElement.value.length > 0 ? 'visible' : 'hidden';
  }

  /** 
   * functon to change pagination paging size
   */
  onPageSizeChange(event: any): void {
    this.pageSize = event.target.value; // update page size
    this.page = 1; // reset to first page
    this.updatePaginatedList(); // update paginated data
  }

  /**
   * function to reset pending user list to its defaults when reset button is pressed
   */
  resetPendingUserList() {
    this.resetSearchInput();

    if (!this.searchPerformed) {
      return;
    }

    this.filteredUserList = [...this.userList];
    this.dataSource.data = this.filteredUserList;

    this.sortData('requestDate');
    this.sortDirection === 'desc' ? this.sortDirection = 'asc' : this.sortDirection = 'asc';
  }

  resetSearchInput() {
    if (this.searchResultsNotFound) {
      this.searchResultsNotFound = false;
    }

    if (this.searchInputEl.nativeElement.value.length > 0) {
      this.searchInputEl.nativeElement.value = '';
    }

    if (this.resetBtnEl.nativeElement.style.visibility === 'visible') {
      this.resetBtnEl.nativeElement.style.visibility = 'hidden';
    }
  }

  /**
   * function to scroll to the top with an offset (in pixels)
   * @param offset top offset in pixels
   */
  scrollTop(offset: number): void {
    window.scrollTo({ top: offset, behavior: 'smooth' }); // scroll to top smoothly
  }
}

// 썸네일 눌렀을 때 스크롤 탑
$(document).ready(function () {
  $(document).on("click", ".thumbnail_box", function () {
    $("html, body").animate({
      scrollTop: 0
    });
  });
});

