import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminHeaderService {
  private headerTextSource = new BehaviorSubject<string>('지금 바로 T-BOX 사용현황을 확인하세요.'); // default AdminHeader text
  currentHeaderText = this.headerTextSource.asObservable();

  constructor() { }

  // method to update AdminHeader text
  setHeaderText(message: string) {
    this.headerTextSource.next(message);
  }
}
