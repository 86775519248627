// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body_bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -2;
	background: white;
	background-size: cover;
	background-repeat: no-repeat;
}

.wrap {
	max-width: 1080px;
	margin: 30px auto 0;
	padding: 1px 10px 50px;
}

.tab_tit {
	margin: 50px 0 30px;
	border-bottom: 2px solid #0f0b0a;
}

.tab_tit h3 {
	color: #0f0b0a
}

.txt_box {
	width: 100%;
	max-height: 50vh;
	overflow: auto;
	border: 1px solid #d2d2d2;
	padding: 50px 100px 50px;
	font-size: 13px;
	color: black;
	background-color: white;
}

p {
	margin: 10px 0 0;
	color: #333;
	font-size: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/app/layout-kr/components/info/privacy-policy/privacy-policy.component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,MAAM;CACN,OAAO;CACP,WAAW;CACX,YAAY;CACZ,WAAW;CACX,iBAAiB;CACjB,sBAAsB;CACtB,4BAA4B;AAC7B;;AAEA;CACC,iBAAiB;CACjB,mBAAmB;CACnB,sBAAsB;AACvB;;AAEA;CACC,mBAAmB;CACnB,gCAAgC;AACjC;;AAEA;CACC;AACD;;AAEA;CACC,WAAW;CACX,gBAAgB;CAChB,cAAc;CACd,yBAAyB;CACzB,wBAAwB;CACxB,eAAe;CACf,YAAY;CACZ,uBAAuB;AACxB;;AAEA;CACC,gBAAgB;CAChB,WAAW;CACX,eAAe;AAChB","sourcesContent":[".body_bg {\r\n\tposition: absolute;\r\n\ttop: 0;\r\n\tleft: 0;\r\n\twidth: 100%;\r\n\theight: 100%;\r\n\tz-index: -2;\r\n\tbackground: white;\r\n\tbackground-size: cover;\r\n\tbackground-repeat: no-repeat;\r\n}\r\n\r\n.wrap {\r\n\tmax-width: 1080px;\r\n\tmargin: 30px auto 0;\r\n\tpadding: 1px 10px 50px;\r\n}\r\n\r\n.tab_tit {\r\n\tmargin: 50px 0 30px;\r\n\tborder-bottom: 2px solid #0f0b0a;\r\n}\r\n\r\n.tab_tit h3 {\r\n\tcolor: #0f0b0a\r\n}\r\n\r\n.txt_box {\r\n\twidth: 100%;\r\n\tmax-height: 50vh;\r\n\toverflow: auto;\r\n\tborder: 1px solid #d2d2d2;\r\n\tpadding: 50px 100px 50px;\r\n\tfont-size: 13px;\r\n\tcolor: black;\r\n\tbackground-color: white;\r\n}\r\n\r\np {\r\n\tmargin: 10px 0 0;\r\n\tcolor: #333;\r\n\tfont-size: 13px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
