export enum OrgType {
  School,
  University,
  Company,
  Government,
  Military
}

export class Organization {
  private orgId: number = 0;
  private orgType: OrgType = OrgType.School;
  private orgName: string = '';
  private email: string = '';
  private userList: number[] = [];
  private classList: number[] = [];
  private pfpLink?: string = '';
  private telNo?: string = '';
  private roadAddr?: string = '';
  private edOfcName?: string = '';
  private edOfcCode?: string = '';

  constructor(orgId: string, orgName: string, email: string, userList: number[], classList: number[], pfpLink: string, orgType?: OrgType, telNo?: string, roadAddr?: string, edOfcName?: string, edOfcCode?: string) {
    this.orgId = parseInt(orgId);
    this.orgType = orgType ?? OrgType.School;
    this.orgName = orgName;
    this.email = email;
    this.userList = userList;
    this.classList = classList;
    this.pfpLink = pfpLink ?? '../../../assets/images/defpf.svg';
    this.telNo = telNo;
    this.roadAddr = roadAddr;
    this.edOfcName = edOfcName;
    this.edOfcCode = edOfcCode;
  }

  public getId(): number {
    return this.orgId;
  }

  public setId(value: number): void {
    this.orgId = value;
  }

  public getOrgName(): string {
    return this.orgName;
  }

  public setOrgName(value: string): void {
    this.orgName = value;
  }

  public getEmail(): string {
    return this.email;
  }

  public setEmail(value: string): void {
    this.email = value;
  }

  public getUserList(): number[] {
    return this.userList;
  }

  public setUserList(userList: number[]): void {
    this.userList = userList;
  }

  public getClassList(): number[] {
    return this.classList;
  }

  public setClassList(classList: number[]): void {
    this.classList = classList;
  }

  public getOrgType(): OrgType | undefined {
    return this.orgType;
  }

  public setOrgType(orgType: OrgType): void {
    this.orgType = orgType;
  }

  public getPfpLink(): string {
    return this.pfpLink;
  }

  public setPfpLink(value: string): void {
    this.pfpLink = value;
  }

  public getTelNo(): string {
    return this.telNo;
  }

  public setTelNo(telNo: string): void {
    this.telNo = telNo;
  }

  public getRoadAddr(): string {
    return this.roadAddr;
  }

  public setAddr(roadAddr: string): void {
    this.roadAddr = roadAddr;
  }

  public getEdOfcName(): string {
    return this.edOfcName;
  }

  public setEdOfcName(edOfcName: string): void {
    this.edOfcName = edOfcName;
  }

  public getEdOfcCode(): string {
    return this.edOfcCode;
  }

  public setEdOfcCode(edOfcCode: string): void {
    this.edOfcCode = edOfcCode;
  }
}
