import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Member } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    // private member: Member
  ) {}

  canActivate() {
    // if ( this.member.role === 1 ){ //TBM_ROLE.SU ) {
    if ( JSON.parse(localStorage.getItem('user'))?.role == 1){ //TBM_ROLE.SU ) {
      return true;
    }
    
    this.router.navigate(['home']);
 
    return false;
  }
}
