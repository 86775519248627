import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table"
import { MatPaginator } from "@angular/material/paginator";
import { User, OrgUserStatus } from "src/app/shared/models/User";
import { Organization } from "src/app/shared/models/Organization";
import { OrganizationService } from "src/app/shared/services/organization.service";
import { AdminHeaderService } from "src/app/shared/services/admin-header.service";
import { PendingUser } from "src/app/shared/models/PendingUser";
import { DatePipe } from "@angular/common";
import { RegularUserService } from "src/app/shared/services/regular-user.service";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-manage-users",
  templateUrl: "./manage-users.component.html",
  styleUrls: ["./manage-users.component.css"]
})
export class ManageUsersComponent implements OnInit {
  // ViewChild decorator for list paginator (페이지네이션)
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  // ViewChild decorator for search bar (검색창)
  private searchInputEl: ElementRef;
  @ViewChild('searchInput', { static: false }) set searchInput(searchInput: ElementRef) {
    this.searchInputEl = searchInput;
  }

  // ViewChild decorator for search reset button (검색 초기화 버튼)
  private resetBtnEl: ElementRef;
  @ViewChild('resetBtn', { static: false }) set resetBtn(resetBtn: ElementRef) {
    this.resetBtnEl = resetBtn;
  }

  constructor(
    private orgService: OrganizationService, // school (organization) data fetch API
    private regUserService: RegularUserService, // 일반 사용자 data fetch API
    private admHeaderService: AdminHeaderService, // 상단 헤더 문구 설정 API
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef, // required to update this.activeColumn on view changes
    private datePipe: DatePipe
  ) { }

  // Getters and setters to activate different modals based on the clicked button
  get activeModal(): boolean {
    return this.modalStates[this.actionType]?.modal || false;
  }

  set activeModal(value: boolean) {
    if (this.modalStates[this.actionType]) {
      this.modalStates[this.actionType].modal = value;
    }
  }

  get activeStep2Modal(): boolean {
    return this.modalStates[this.actionType]?.step2Modal || false;
  }

  set activeStep2Modal(value: boolean) {
    if (this.modalStates[this.actionType]) {
      this.modalStates[this.actionType].step2Modal = value;
    }
  }

  // user management modes depending on selected dropdown value
  manageMode: 'all' | 'accepted' | 'restore' | 'suspend' | 'remove' | 'accept' | 'removed' = 'all';
  // modal text depending on selected mode
  actionType: string = '';
  modeText: string = '사용';
  // target status after processing user
  targetStatus: OrgUserStatus.Accepted | OrgUserStatus.Suspended | OrgUserStatus.Removed | OrgUserStatus.Removed;

  // columns for user list
  displayedColumns: string[] = ['select', 'name', 'id', 'email', 'status', 'request_date'];
  dataSource = new MatTableDataSource<PendingUser>([]);

  page: number = 1; // current page
  pageSize: number = 10; // number of items per page
  pageSizeOptions: number[] = [10, 20, 50, 100, 200, 500]; // options for page size dropdown

  // 사용자 상태별 보기 드롭다운
  statusFilterOption: string = '전체';
  dropdownOptions: string[] = ['전체', '사용 중', '사용 중지', '사용 중지 요청', '소속 해제 요청', '소속 해제'];
  showAllFilter: OrgUserStatus[] = [OrgUserStatus.PendingRemoval, OrgUserStatus.Accepted, OrgUserStatus.Suspended, OrgUserStatus.PendingSuspension];
  activeFilter: OrgUserStatus[] = [OrgUserStatus.Accepted, OrgUserStatus.PendingSuspension, OrgUserStatus.PendingRemoval]
  dropdownFilters: OrgUserStatus[] = this.showAllFilter;

  // Arrays to hold user list fetched from RegularUserService
  userList: PendingUser[] = [];
  filteredUserList: PendingUser[] = [];
  paginatedUserList: PendingUser[] = [];
  sortDirection: 'asc' | 'desc' = 'asc';
  activeColumn: string = 'name';

  // Organization of currently signed in Admin
  org: Organization;

  // Booleans for toggling modals
  modalStates = {
    remove: { modal: false, step2Modal: false },
    suspend: { modal: false, step2Modal: false },
    restore: { modal: false, step2Modal: false },
    accept: { modal: false, step2Modal: false }
  };

  // Array to hold items selected by checkbox(es)
  // 체크박스로 선택된 항목 저장
  candidates: PendingUser[] = [];
  sortedCandidateName: string = '';

  submissionValid: boolean = true;
  loading: boolean = false;
  loadingError: boolean = false;

  ngOnInit(): void {
    this.fetchOrg();
    this.manageMode = 'all';
    this.buildUserList();
    this.sortFilteredList();
    this.updateDisplayedColumns();
    this.scrollTop(39);
  }

  /**
   * Method to check a status value against an array of multiple status values
   * @param val subject status value
   * @param predicates status values to be compared against
   * @returns true if val matches any of the predicates
   */
  checkPredicates(val: OrgUserStatus, predicates: OrgUserStatus[]) {
    return predicates ? predicates.includes(val) : false;
  }

  /**
   * Method to update modal text based on the clicked button.
   */
  setModalText(): void {
    switch (this.actionType) {
      case 'remove':
        this.targetStatus = OrgUserStatus.Removed;
        this.activeModal = this.modalStates['remove']?.modal;
        this.activeStep2Modal = this.modalStates['remove']?.step2Modal;
        this.modeText = '소속 해제';
        break;
      case 'suspend':
        this.targetStatus = OrgUserStatus.Suspended;
        this.activeModal = this.modalStates['suspend']?.modal;
        this.activeStep2Modal = this.modalStates['suspend']?.step2Modal;
        this.modeText = '사용 중지';
        break;
      case 'restore':
        this.targetStatus = OrgUserStatus.Accepted;
        this.activeModal = this.modalStates['restore']?.modal;
        this.activeStep2Modal = this.modalStates['restore']?.step2Modal;
        this.modeText = '중지 해제';
        break;
      default:
        this.modeText = '';
        break;
    }
  }

  /**
   * Method to set modal icon based on the clicked button.
   */
  getModalIcon(): string {
    if (!this.submissionValid) {
      return '../../../../assets/icons/Icon_64px_col-12.png';
    }

    const iconMapping = {
      remove: '../../../../assets/icons/Icon_64px_col-78.png',
      suspend: '../../../../assets/icons/Icon_64px_col-79.png',
      restore: '../../../../assets/icons/Icon_64px_col-76.png',
    }

    return iconMapping[this.actionType] || '../../../../assets/icons/Icon_64px_col-78.png';
  }

  /**
   * Method to fetch the organization data of the currently signed in Admin user.
   */
  fetchOrg(): void {
    this.org = this.orgService.getOrganizationForAdminUser();
  }

  async buildUserList(): Promise<void> {
    this.loading = true;
    try {
      const users = await lastValueFrom(this.regUserService.getRegularUsersForOrg(this.org.getId()))
      this.userList = users
        .map(user => {
          const pendingUser: PendingUser = new PendingUser(
            user.getUserId(),
            user.getFullname(),
            user.getUsername(),
            user.getEmail(),
            user.getCellphone(),
            user.getOrgId(),
            user.getPfpLink(),
            user.getUserStatus(),
            false, // selected
            user.getUserStatus() === OrgUserStatus.Accepted ? null : user.getRequestDate()
          );
          return pendingUser;
        })
        .filter(user => user.getOrgId() === this.org.getId() && this.checkPredicates(user.getUserStatus(), this.dropdownFilters));

      this.filteredUserList = [...this.userList];

      // call updatePaginatedList to show the first page
      this.updatePaginatedList();

      // update user count in header
      this.updateHeaderText();

      this.loadingError = false; // clear any loading errors
    } catch (error) {
      console.log('error fetching user list:', error);
      this.loadingError = true;
    }
    this.loading = false;
  }

  dateToString(date: Date): string {
    if (!date) {
      return '--';
    }

    return this.datePipe.transform(date, 'yyyy.MM.dd') || '';
  }

  /**
   * Method to update header text and user counts according to this.manageMode
   */
  updateHeaderText(): void {
    if (this.manageMode === 'all') {
      this.admHeaderService.setHeaderText(`현재 <span style="color: #fec40d;">${this.getUserCountByFilters(this.showAllFilter)}</span>명이 소속되어 있습니다.`);
    } else if (this.manageMode === 'accepted') {
      this.admHeaderService.setHeaderText(`현재 <span style="color: #fec40d;">${this.getUserCountByFilters(this.activeFilter)}</span>명이 사용 중입니다.`);
    } else if (this.manageMode === 'remove') {
      this.admHeaderService.setHeaderText(`현재 <span style="color: #fec40d;">${this.getUserCountByFilters([OrgUserStatus.PendingRemoval])}</span>명이 소속 해제 요청을 하였습니다.`);
    } else if (this.manageMode === 'restore') {
      this.admHeaderService.setHeaderText(`현재 <span style="color: #fec40d;">${this.getUserCountByFilters([OrgUserStatus.Suspended])}</span>명이 사용 중지 상태입니다.`);
    } else if (this.manageMode === 'suspend') {
      this.admHeaderService.setHeaderText(`현재 <span style="color: #fec40d;">${this.getUserCountByFilters([OrgUserStatus.PendingSuspension])}</span>명이 사용 중지 요청을 하였습니다.`);
    } else if (this.manageMode === 'removed') {
      this.admHeaderService.setHeaderText(`현재 <span style="color: #fec40d;">${this.getUserCountByFilters([OrgUserStatus.PendingSuspension])}</span>명이 소속 해제 상태입니다.`);
    }
  }

  /**
   * Method to rebuild the user list with given status filter(s)
   * @param filters OrgUserStatus predicate(s)
   */
  async buildFilteredList(filters: OrgUserStatus[]): Promise<void> {
    this.loading = true;
    try {
      const users = await lastValueFrom(this.regUserService.getRegularUsersForOrg(this.org.getId()));
      this.userList = users
        .map(user => {
          const pendingUser: PendingUser = new PendingUser(
            user.getUserId(),
            user.getFullname(),
            user.getUsername(),
            user.getEmail(),
            user.getCellphone(),
            user.getOrgId(),
            user.getPfpLink(),
            user.getUserStatus(),
            false, // selected
            user.getUserStatus() === OrgUserStatus.Accepted ? null : user.getRequestDate()
          );
          return pendingUser;
        })
        .filter(user => this.checkPredicates(user.getUserStatus(), filters))

      this.filteredUserList = [...this.userList];

      this.sortFilteredList();

      // call updatePaginatedList to show the first page
      this.updatePaginatedList();

      // update user count in header
      this.updateHeaderText();

      this.loadingError = false; // clear any loading errors
    } catch (error) {
      console.log('error building filtered list:', error);
      this.loadingError = true;
    }
    this.loading = false;
  }

  sortFilteredList(): void {
    if (this.manageMode === 'accepted' || this.manageMode === 'restore' || this.manageMode === 'accept' || this.manageMode === 'removed') {
      // sort by name in ascending order for '사용 중' and '사용 중지' users
      this.activeColumn = 'fullname';
      this.sortData('fullname');
    } else if (this.manageMode === 'all') {
      this.activeColumn = 'status';
      this.sortData('status');
    } else {
      // sort by request date otherwise
      this.activeColumn = 'requestDate';
      this.sortData('requestDate');
    }

    // trigger change detection to correctly highlight this.activeColumn
    this.cdRef.detectChanges();
  }

  /**
   * Method to reset the user list to its original form (전체보기) when the '전체' dropdown option is selected.
   */
  resetFilteredList(): void {
    this.buildFilteredList(this.showAllFilter);
  }

  /** Functions to handle user list pagination */
  updatePaginatedList() {
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedUserList = this.filteredUserList.slice(startIndex, endIndex);

    this.dataSource.data = [...this.paginatedUserList];
  }

  onPageChange(newPage: number) {
    this.page = newPage;
    this.updatePaginatedList();
    this.scrollTop(39);
  }

  /**
   * Returns the request date of a user in YYYY-MM-DD format, and '--' if date does not exist
   * @param userId 
   * @returns request date of a user in YYYY-MM-DD format, and '--' if date does not exist
   */
  async getRequestDateForUser(userId: number): Promise<string> {
    const user = await lastValueFrom(this.regUserService.getRegularUserById(userId))
    if (user.getUserStatus() === OrgUserStatus.Removed || user.getUserStatus() === OrgUserStatus.Accepted) {
      return '--';
    }

    // return request date based on userId
    const today = new Date();
    const requestDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    requestDate.setDate(today.getDate() - userId + 1);
    return requestDate.toISOString().split('T')[0];
  }

  /**
   * Method to return the number of users that are NOT of a given status.
   * @param filter OrgUserStatus predicate
   * @returns the number of items of filteredUserList that do NOT match the given filter
   */
  getFilteredUserCount(filter?: OrgUserStatus): number {
    if (!filter) {
      return this.filteredUserList.length;
    }

    return this.filteredUserList.filter(user => user.getUserStatus() !== filter).length;
  }

  /**
   * Method to return the number of users that are of given status(es).
   * @param filters OrgUserStatus predicate(s)
   * @returns the number of items of filteredUserList that match the given filter(s)
   */
  getUserCountByFilters(filters: OrgUserStatus[]): number {
    return this.filteredUserList.filter(user => this.checkPredicates(user.getUserStatus(), filters)).length;
  }

  getUserCountByCurrentFilters() {
    return this.getUserCountByFilters(this.dropdownFilters);
  }

  /**
   * Method to toggle the selection of a candidate.
   * @param user 
   */
  toggleCandidate(user: PendingUser): void {
    if (user.selected) {
      this.candidates.push(user);
    } else {
      this.candidates = this.candidates.filter(u => u.getUserId() !== user.getUserId());
    }
  }

  /** Method to retrieve the first fullname of candidates sorted in ascending order
   */
  updateSortedCandidates(): void {
    this.candidates.sort((a, b) =>
      a.getFullname().localeCompare(b.getFullname(), 'ko-KR'));
    this.sortedCandidateName = this.candidates[0]?.getFullname() || '';
  }

  /** Method to open candidates accept / reject modal.  */
  openModal(modal: 'remove' | 'suspend' | 'restore'): void {
    this.actionType = modal;
    this.setModalText();
    this.updateSortedCandidates();

    if (this.candidates.length > 0) {
      this.activeModal = true;
    }
  }

  /** Method to close candidates accept / reject modal.
   *  @param 'remove', 'suspend', or 'restore'
   */
  closeModal(): void {
    if (this.activeStep2Modal) {
      this.activeStep2Modal = false;
    }

    this.activeModal = false;

    this.candidates = [];

    this.dataSource.data.forEach(user => {
      user.selected = false;
    });

    if (this.isAllSelected()) {
      this.toggleSelectAll({ checked: false });
    }

    this.scrollTop(39);
  }

  /** Method to approve selected candidates.
   */
  async processUser(): Promise<void> {
    this.setModalText();
    this.activeModal = false;
    this.activeStep2Modal = true;

    if (this.candidates.length === 0) {
      return;
    }

    let targetStatus: OrgUserStatus = OrgUserStatus.Accepted;
    switch (this.actionType) {
      case 'remove':
        targetStatus = OrgUserStatus.Removed;
        break;
      case 'suspend':
        targetStatus = OrgUserStatus.Suspended;
        break;
      default:
        targetStatus = OrgUserStatus.Accepted;
        break;
    }

    try {
      const candidateIds = this.candidates.map(user => user.getUserId());
      await this.regUserService.setUserStatus(candidateIds, targetStatus)
      this.submissionValid = true;
    } catch (error) {
      this.submissionValid = false;
      console.log('Error accepting users:', error);
    }

    this.buildFilteredList(this.dropdownFilters); // rebuild user list after processing candidates
    this.updateHeaderText(); // live update user count in header
    this.gotoConfirm(); // 확인하기 window
  }

  gotoConfirm(): void {
    this.activeStep2Modal = true;
  }

  /* pending user list select/sort functions */
  /** toggles select all checkbox */
  toggleSelectAll(event: any): void {
    const checked = event.checked;

    this.dataSource.data.forEach(user => {
      user.selected = checked;

      // add user to candidates if checked and not already in the list
      if (checked && !this.candidates.find(u => u.getUserId() === user.getUserId())) {
        this.candidates.push(user);
      }

      // remove user from candidates if unchecked
      if (!checked) {
        this.candidates = this.candidates.filter(u => u.getUserId() !== user.getUserId());
      }
    });
  }

  /** checks if all rows are selected */
  isAllSelected(): boolean {
    return this.paginatedUserList.every(user => user.selected);
  }

  /** checks if only some rows are selected */
  isIndeterminate() {
    return this.paginatedUserList.some(user => user.selected) && !this.isAllSelected;
  }

  /**
   * function to assign a text label and color to a status code
   * @param status User Status in integer form
   */
  getStatusLabelAndColor(status: number): { label: string, color: string } {
    switch (status) {
      case 100:
        return { label: '승인 대기', color: 'rgb(239, 186, 62)' };
      case 200:
        return { label: '사용 중', color: 'green' };
      case 400:
        return { label: '거절', color: 'red' };
      case 300:
        return { label: '사용 중지', color: 'red' };
      case 310:
        return { label: '중지 요청', color: 'orange' };
      case 500:
        return { label: '소속 해제', color: 'black' };
      case 510:
        return { label: '해제 요청', color: 'darkgrey' };
      default:
        return { label: 'null', color: 'black' };
    }
  }

  /** toggles sorting function for a certain column */
  toggleSort(column: string) {
    if (this.activeColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.activeColumn = column;
      this.sortDirection = 'asc';
    }

    this.sortData(column);
  }

  /**
   * function that sorts the user list based on a selected column.
   * @param column column to be sorted
   */
  sortData(column: string) {
    if (column === 'requestDate') {
      this.sortByRequestDate(column);
    }

    if (column == 'status') {
      this.sortByStatus();
    }

    this.filteredUserList.sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      // handle other string columns
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        // split Korean/English part and number part
        const textPartA = valueA.match(/[^\d]+/g)?.[0] || ''; // text part (Korean / English)
        const numberPartA = parseInt(valueA.match(/\d+/g)?.[0] || '0', 10) // number part

        const textPartB = valueB.match(/[^\d]+/g)?.[0] || ''; // text part (Korean / English)
        const numberPartB = parseInt(valueB.match(/\d+/g)?.[0] || '0', 10) // number part

        // first, compare text part using localeCompare with support for both Korean and English 
        const textCompare = textPartA.localeCompare(textPartB, 'ko-KR'); // handles both Korean and English
        if (textCompare != 0) {
          return this.sortDirection === 'asc' ? textCompare : -textCompare;
        }

        // if text parts are equal, compare the nubmer part numerically
        return this.sortDirection === 'asc' ? numberPartA - numberPartB : numberPartB - numberPartA;
      }

      // fallback to standard comparison for non-string values 
      if (this.sortDirection === 'asc') {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    });

    // update paginated list after sorting
    this.page = 1;
    this.updatePaginatedList();
  }

  sortByRequestDate(column: string): void {
    this.filteredUserList.sort((a, b) => {
      const requestDateA = a.getRequestDate();
      const requestDateB = b.getRequestDate();

      // handle cases where request date is '--'
      if (a.getUserStatus() === OrgUserStatus.Accepted && b.getUserStatus() !== OrgUserStatus.Accepted) {
        return 1; // push '--' to the bottom
      } else if (a.getUserStatus() !== OrgUserStatus.Accepted && b.getUserStatus() === OrgUserStatus.Accepted) {
        return -1; // keep valid dates above '--'
      } else if (a.getUserStatus() === OrgUserStatus.Accepted && b.getUserStatus() === OrgUserStatus.Accepted) {
        // if both are '--', sort by name as a fallback
        const nameCompare = a.getFullname().localeCompare(b.getFullname(), 'ko-KR');
        return this.sortDirection === 'asc' ? -nameCompare : nameCompare;
      }

      // handle valid dates
      const dateA = new Date(requestDateA);
      const dateB = new Date(requestDateB);

      // compare the dates
      if (this.sortDirection === 'asc') {
        return dateB.getTime() - dateA.getTime(); // ascending order
      } else {
        return dateA.getTime() - dateB.getTime(); // descending order
      }
    });

    // update paginated list after sorting
    this.page = 1;
    this.updatePaginatedList();
  }

  sortByStatus(): void {
    this.filteredUserList.sort((a, b) => {
      const statusA = a.getUserStatus();
      const statusB = b.getUserStatus();

      if (this.sortDirection === 'asc') {
        return statusA - statusB; // ascending order for numeric status
      } else {
        return statusB - statusA;
      }
    });

    // update paginated list after sorting
    this.page = 1;
    this.updatePaginatedList();
  }

  /* pending user list search functions */
  searchPerformed: boolean = false;
  searchResultsNotFound: boolean = false;

  /**
   * function to search pending user list by name, username, or email
   * @param query input string
   */
  searchPendingUserList(query: string) {
    if (this.searchInputEl.nativeElement.value === '') {
      this.resetUserList();
      return;
    }

    const lowerQuery = query.toLowerCase();

    this.filteredUserList = this.userList.filter(user => {
      return (
        user.getFullname().toLowerCase().includes(lowerQuery) ||
        user.getUsername().toLowerCase().includes(lowerQuery) ||
        user.getEmail().toLowerCase().includes(lowerQuery)
      );
    });

    if (this.filteredUserList.length === 0) {
      this.searchResultsNotFound = true;
      this.searchPerformed = true;
      return;
    }

    this.dataSource.data = this.filteredUserList;
    this.searchPerformed = true;
    this.searchResultsNotFound = false;

    this.page = 1; // reset to first page
    this.updatePaginatedList(); // update table
  }

  /**
   * Method to render / hide the 'X' reset button if input field is non-empty / empty.
   */
  onInputChange() {
    this.resetBtnEl.nativeElement.style.visibility = this.searchInputEl.nativeElement.value.length > 0 ? 'visible' : 'hidden';
  }

  /** 
   * Method to change pagination paging size.
   */
  onPageSizeChange(event: any): void {
    this.pageSize = event.target.value; // update page size
    this.page = 1; // reset to first page
    this.updatePaginatedList(); // update paginated data
  }

  /**
   * Method to remove the '요청 날짜' column from the table if the '사용 중' status dropdown is selected.
   */
  updateDisplayedColumns(): void {
    // if filter is not '전체', add request_date column
    if (this.statusFilterOption === '사용 중') {
      this.displayedColumns = ['select', 'name', 'id', 'email', 'status'];
    } else {
      this.displayedColumns = ['select', 'name', 'id', 'email', 'status', 'request_date'];
    }
  }

  /**
   * Method to update the user list according to the selected status dropdwon option
   * @param event dropdown option change event
   */
  onStatusFilterChange(event: any): void {
    this.statusFilterOption = event.target.value; // update status filter

    switch (this.statusFilterOption) {
      case ('전체'):
        this.dropdownFilters = this.showAllFilter;
        this.manageMode = 'all';
        break;
      case ('사용 중'):
        this.manageMode = 'accepted';
        this.dropdownFilters = [OrgUserStatus.Accepted];
        break;
      case ('사용 중지'):
        this.manageMode = 'restore';
        this.dropdownFilters = [OrgUserStatus.Suspended];
        break;
      case ('사용 중지 요청'):
        this.manageMode = 'suspend';
        this.dropdownFilters = [OrgUserStatus.PendingSuspension];
        break;
      case ('소속 해제 요청'):
        this.manageMode = 'remove';
        this.dropdownFilters = [OrgUserStatus.PendingRemoval];
        break;
      case ('소속 해제'):
        this.manageMode = 'removed';
        this.dropdownFilters = [OrgUserStatus.Removed];
        break;
      default:
        this.dropdownFilters = this.showAllFilter;
        break;
    }

    this.updateDisplayedColumns(); // remove '요청 날짜' column when displaying '사용 중' users
    this.buildFilteredList(this.dropdownFilters); // rebuild user list by status filter(s)
    this.updatePaginatedList(); // update pagination
  }

  /**
   * Method to reset pending user list to its defaults when the reset button is pressed.
   */
  async resetUserList(): Promise<void> {
    this.resetSearchInput();

    if (!this.searchPerformed) {
      return;
    }

    try {
      await this.buildFilteredList(this.dropdownFilters);
      this.dataSource.data = this.filteredUserList;

      this.sortData('requestDate');
      this.sortDirection === 'desc' ? this.sortDirection = 'asc' : this.sortDirection = 'asc';
    } catch (error) {
      console.log('error rebuilding filtered user list.');
    }
  }

  /** Method to reset the search bar to an empty field when the reset button is pressed. */
  resetSearchInput() {
    if (this.searchResultsNotFound) {
      this.searchResultsNotFound = false;
    }

    if (this.searchInputEl.nativeElement.value.length > 0) {
      this.searchInputEl.nativeElement.value = '';
    }

    if (this.resetBtnEl.nativeElement.style.visibility === 'visible') {
      this.resetBtnEl.nativeElement.style.visibility = 'hidden';
    }
  }

  /**
   * function to scroll to the top with an offset (in pixels)
   * @param offset top offset in pixels
   */
  scrollTop(offset: number): void {
    window.scrollTo({ top: offset, behavior: 'smooth' }); // scroll to top smoothly
  }
}
