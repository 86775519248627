import { Injectable } from "@angular/core";
import { Organization } from "./Organization";

export enum OrgUserStatus {
  Pending = 100, // 승인 대기
  Canceled = 110,
  Accepted = 200, // 승인
  Rejected = 400, // 거절
  Suspended = 300, // 사용 중지
  PendingSuspension = 310, // 사용 중지 대기
  Removed = 500, // 소속 해제
  PendingRemoval = 510 // 소속 해제 대기
}

export type PrimaryGroup = {
  id: number,
  name: string
};

export type SecondaryGroup = {
  id: number,
  primaryGroupId: number,
  name: string
};

export enum AdminUserType {
  SuperAdmin,
  SubAdmin
}

export class User {
  private userId: number;
  private fullname: string;
  private username: string;
  private email: string;
  private cellphone: string;
  private orgId: number; // optional organization data for BIZ users
  private pfpLink: string; // profile photo link
  private userStatus: OrgUserStatus;

  constructor(
    userId: number,
    fullname: string,
    username: string,
    email: string,
    cellphone: string,
    orgId?: number,
    pfpLink?: string,
    userStatus?: OrgUserStatus
  ) {
    this.userId = userId;
    this.fullname = fullname;
    this.username = username;
    this.email = email;
    this.cellphone = cellphone;
    this.orgId = orgId ?? 0;
    this.pfpLink = pfpLink ?? '../../../assets/images/defpf.svg';
    this.userStatus = userStatus ?? OrgUserStatus.Pending;
  }

  public getUserId(): number {
    return this.userId;
  }

  public setUserId(value: number): void {
    this.userId = value;
  }

  public getFullname(): string {
    return this.fullname;
  }

  public setFullname(value: string): void {
    this.fullname = value;
  }

  public getEmail(): string {
    return this.email;
  }

  public setEmail(value: string): void {
    this.email = value;
  }

  public getCellphone(): string {
    return this.cellphone;
  }

  public setCellphone(value: string): void {
    this.cellphone = value;
  }

  public getUsername(): string {
    return this.username;
  }

  public setUsername(value: string): void {
    this.username = value;
  }

  public getUserStatus(): OrgUserStatus | undefined {
    return this.userStatus;
  }

  public setUserStatus(value: OrgUserStatus | undefined): void {
    this.userStatus = value;
  }

  public getOrgId(): number {
    return this.orgId;
  }

  public setOrgId(value: number): void {
    this.orgId = value;
  }

  public getPfpLink(): string | undefined {
    return this.pfpLink;
  }

  public setPfpLink(value: string | undefined): void {
    this.pfpLink = value;
  }
}

@Injectable()
export class Member {
  loggedIn: boolean;
  id: number;
  fullname: string;
  email: string;
  username: string;
  cellphone: string;
  // password: string;
  // role: Role;
  role: number;
  photoUrl?: string;
  type: string;
  token?: string;
  thisOrg?: Organization; // optional username for Organization (BIZ) users
}

export const ERROR: number = 0;
export const SU: number = 1;
export const NONE: number = 2;
export const MEMBER: number = 3;
export const BIZ: number = 4;
export const INSTRUCTOR: number = 5;
export const O2O: number = 6;
