// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body .add-missions-list {
    width: 100%;
    overflow-y: auto; /* enable vertical scrolling within table or list */
    max-height: 31vh; /* restrict height of table or list */
    display: block; /* allow scrolling */
}

.notif-board-content {
  display: flex;
  justify-content: space-between !important;
  width: 60% !important;
}

.class-date-div {
  margin: 0 !important;
}

::ng-deep .cdk-overlay-container {
  z-index: 1500 !important;
}

.mission-date-input {
  background-color: white !important;
  cursor: pointer;
}

.form-control {
  font-size: 1.5em !important;
}

.translate-right-7 {
  transform: translateX(7px);
}

.mission-confirm-icon {
  width: 100px;
  height: 100px;
  margin: 20px 20px 30px;
}

.modal-footer {
  margin-bottom: 0 !important;
}

.conflicting-mission-wrap {
  border-radius: 20px;
  border: 1px solid rgb(255, 0, 0, 0.6);
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/layout-kr/components/manage-classes/add-missions/add-missions.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB,EAAE,mDAAmD;IACrE,gBAAgB,EAAE,qCAAqC;IACvD,cAAc,EAAE,oBAAoB;AACxC;;AAEA;EACE,aAAa;EACb,yCAAyC;EACzC,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,kCAAkC;EAClC,eAAe;AACjB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;EACnB,qCAAqC;EACrC,YAAY;AACd","sourcesContent":[".modal-body .add-missions-list {\r\n    width: 100%;\r\n    overflow-y: auto; /* enable vertical scrolling within table or list */\r\n    max-height: 31vh; /* restrict height of table or list */\r\n    display: block; /* allow scrolling */\r\n}\r\n\r\n.notif-board-content {\r\n  display: flex;\r\n  justify-content: space-between !important;\r\n  width: 60% !important;\r\n}\r\n\r\n.class-date-div {\r\n  margin: 0 !important;\r\n}\r\n\r\n::ng-deep .cdk-overlay-container {\r\n  z-index: 1500 !important;\r\n}\r\n\r\n.mission-date-input {\r\n  background-color: white !important;\r\n  cursor: pointer;\r\n}\r\n\r\n.form-control {\r\n  font-size: 1.5em !important;\r\n}\r\n\r\n.translate-right-7 {\r\n  transform: translateX(7px);\r\n}\r\n\r\n.mission-confirm-icon {\r\n  width: 100px;\r\n  height: 100px;\r\n  margin: 20px 20px 30px;\r\n}\r\n\r\n.modal-footer {\r\n  margin-bottom: 0 !important;\r\n}\r\n\r\n.conflicting-mission-wrap {\r\n  border-radius: 20px;\r\n  border: 1px solid rgb(255, 0, 0, 0.6);\r\n  padding: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
