import { CUSTOM_ELEMENTS_SCHEMA, NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule, DatePipe, registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeKo from "@angular/common/locales/ko";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { FlatpickrModule } from "angularx-flatpickr";
import { ngbModule } from "src/ngb-module";
import { MaterialModule } from "src/material-module";
import { MatTableModule } from "@angular/material/table";
import { register } from "swiper/element/bundle";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AuthGuard, Member, QuizIncompleteGuard, LessonIncompleteGuard } from "./shared";
import { PendingChangesGuard } from "./shared/guards/pending-changes.guard";
import { NoPageFoundComponent } from "./no-page-found/no-page-found.component";

import { HeaderComponent } from "./layout-kr/header/header.component";
import { FooterComponent } from "./layout-kr/footer/footer.component";
import { BodyComponent } from "./layout-kr/components/body/body.component";
import { AboutUsComponent } from "./layout-kr/components/info/about-us/about-us.component";
import { MembershipTermsComponent } from "./layout-kr/components/info/membership-terms/membership-terms.component";
import { PrivacyPolicyComponent } from "./layout-kr/components/info/privacy-policy/privacy-policy.component";
import { TermsOfServiceComponent } from "./layout-kr/components/info/terms-of-service/terms-of-service.component";

import { SignupComponent } from "./layout-kr/components/signup/signup.component";
import { AccountDeletedComponent } from "./layout-kr/components/delete-account/account-deleted/account-deleted.component";
import { ReactivateAccountComponent } from "./layout-kr/components/delete-account/reactivate-account/reactivate-account.component";
import { LoginComponent, LoginRemindIDComponent, LoginResetPWComponent, LoginRegularComponent, LoginOptionsComponent } from "./layout-kr/components/login/login.component";
import { VodComponent } from "./layout-kr/components/vod/vod.component";
import {
  MultiprofileComponent,
  MultiprofileRegularComponent,
  MultiprofileNewprofileComponent,
  MultiprofileEditComponent,
  MultiprofileDeleteComponent
} from "./layout-kr/components/multiprofile/multiprofile.component";
import { ProfileComponent, VodViewLogComponent, ProfileFindJusoComponent, ProfileSubHistory, ProfileMySubscription, SubTerminationPopup, Favorite } from "./layout-kr/components/user/profile/profile.component";
import { SetupComponent } from "./layout-kr/components/user/setup/setup.component";

import { CommunityComponent, CommunityViewerComponent, CommunityNoticeComponent } from "./layout-kr/components/community/community.component";

import { AdminMyPageHeaderComponent } from "./layout-kr/components/admin-my-page-header/admin-my-page-header.component";
import { LogoSlideComponent } from "./layout-kr/components/logo-slide/logo-slide.component";

import { CreateClassComponent } from "./layout-kr/components/create-class/create-class.component";
import { ManageClassesComponent } from "./layout-kr/components/manage-classes/manage-classes.component";
import { AddUsersComponent } from "./layout-kr/components/manage-classes/add-users/add-users.component";
import { RemoveUsersComponent } from "./layout-kr/components/manage-classes/remove-users/remove-users.component";
import { EditClassComponent } from "./layout-kr/components/manage-classes/edit-class/edit-class.component";
import { ClassProgressComponent } from "./layout-kr/components/manage-classes/class-progress/class-progress.component";

import { ParticipantListComponent } from "./layout-kr/components/manage-classes/participant-list/participant-list.component";
import { DaysOfWeekPickerComponent } from "./layout-kr/components/days-of-week-picker/days-of-week-picker.component";

import { ManageUsersComponent } from "./layout-kr/components/manage-users/manage-users.component";
import { AcceptUsersComponent } from "./layout-kr/components/accept-users/accept-users.component";

import { TosHeaderComponent } from "./layout-kr/components/info/tos-header/tos-header.component";

import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { AddMissionsComponent } from "./layout-kr/components/manage-classes/add-missions/add-missions.component";
import { ViewTrackingComponent } from "./layout-kr/components/manage-classes/view-tracking/view-tracking.component";
import { ViewMissionsComponent } from "./layout-kr/components/manage-classes/view-tracking/view-missions/view-missions.component";
import { OrgProgressComponent } from "./layout-kr/components/org-progress/org-progress.component";
import { EditMissionsComponent } from "./layout-kr/components/manage-classes/add-missions/edit-missions/edit-missions.component";

registerLocaleData(localeKo, "ko");

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
};

register();

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    BodyComponent,
    AdminMyPageHeaderComponent,

    AboutUsComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    MembershipTermsComponent,
    NoPageFoundComponent,

    SignupComponent,
    LoginComponent,
    LoginRegularComponent,
    LoginRemindIDComponent,
    LoginResetPWComponent,
    LoginOptionsComponent,
    MultiprofileComponent,
    MultiprofileRegularComponent,
    MultiprofileNewprofileComponent,
    MultiprofileEditComponent,
    MultiprofileDeleteComponent,
    ProfileComponent,
    ProfileFindJusoComponent,
    ProfileSubHistory,
    ProfileMySubscription,
    VodViewLogComponent,
    Favorite,
    AccountDeletedComponent,
    ReactivateAccountComponent,
    SetupComponent,

    SubTerminationPopup,

    VodComponent,
    CommunityComponent,
    CommunityViewerComponent,
    CommunityNoticeComponent,

    CreateClassComponent,
    ManageClassesComponent,
    EditClassComponent,
    ClassProgressComponent,
    OrgProgressComponent,

    ParticipantListComponent,
    DaysOfWeekPickerComponent,

    AddMissionsComponent,
    EditMissionsComponent,
    
    AddUsersComponent,
    RemoveUsersComponent,

    ViewTrackingComponent,
    ViewMissionsComponent,

    AcceptUsersComponent,
    ManageUsersComponent,

    TosHeaderComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    CommonModule,
    FlatpickrModule.forRoot(),
    FormsModule,
    HttpClientModule,
    MaterialModule,
    MatTableModule,
    ngbModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LogoSlideComponent,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [AppComponent, DaysOfWeekPickerComponent],
  providers: [{ provide: LOCALE_ID, useValue: 'ko' }, AuthGuard, Member, DatePipe, PendingChangesGuard, QuizIncompleteGuard, LessonIncompleteGuard],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
