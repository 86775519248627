import { AboutUsComponent } from "./layout-kr/components/info/about-us/about-us.component";
import { AccountDeletedComponent } from "./layout-kr/components/delete-account/account-deleted/account-deleted.component";
import { AdminGuard, AuthGuard, NoAuthGuard, IncompleteGuard } from "./shared";
import { BodyComponent } from "./layout-kr/components/body/body.component";

import { CreateClassComponent } from "./layout-kr/components/create-class/create-class.component";
import { ManageClassesComponent } from "./layout-kr/components/manage-classes/manage-classes.component";

import { CommunityComponent } from "./layout-kr/components/community/community.component";
import { LoginComponent } from "./layout-kr/components/login/login.component";
import { MembershipTermsComponent } from "./layout-kr/components/info/membership-terms/membership-terms.component";
import { NgModule } from "@angular/core";
import { PrivacyPolicyComponent } from "./layout-kr/components/info/privacy-policy/privacy-policy.component";
import { ProfileComponent } from "./layout-kr/components/user/profile/profile.component";
import { ReactivateAccountComponent } from "./layout-kr/components/delete-account/reactivate-account/reactivate-account.component";
import { Routes, RouterModule } from "@angular/router";
import { SetupComponent } from "./layout-kr/components/user/setup/setup.component";
import { SignupComponent } from "./layout-kr/components/signup/signup.component";
import { TermsOfServiceComponent } from "./layout-kr/components/info/terms-of-service/terms-of-service.component";
import { NoPageFoundComponent } from "./no-page-found/no-page-found.component";
import { VodComponent } from "./layout-kr/components/vod/vod.component";
import { MultiprofileComponent } from "./layout-kr/components/multiprofile/multiprofile.component";

import { AcceptUsersComponent } from "./layout-kr/components/accept-users/accept-users.component";
import { ManageUsersComponent } from "./layout-kr/components/manage-users/manage-users.component";


const routes: Routes = [

  {
    path: "",
    children: [
      { path: "", redirectTo: "home", pathMatch: "full" },
      
      // Login/Signup Pages
      { path: "login", component: LoginComponent, canActivate: [NoAuthGuard] },
      { path: "signup", component: SignupComponent, canActivate: [NoAuthGuard] },
      { path: "incomplete", component: SetupComponent },
      { path: '',
        canActivateChild: [AuthGuard],
        children: [
          // Main / Home Page
          { path: "home", component: BodyComponent },
          // Delete Account Pages
          { path: "account-deleted", component: AccountDeletedComponent },
          { path: "reactivate-account", component: ReactivateAccountComponent },
          
          // { path: "multiprofile", component: MultiprofileComponent },

          // User Pages
          { path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
          // Header Pages
          { path: "community", component: CommunityComponent },
          // Manage Classes page
          { path: "manage/classes", component: ManageClassesComponent },
          { path: "manage/classes/create", component: CreateClassComponent },
          // Manage Users page (with Accept, Reject, Suspend subpages)
          { path: 'manage/users', component: ManageUsersComponent, data: { action: 'remove' }, runGuardsAndResolvers: 'always' },
          { path: 'manage/users/accept', component: AcceptUsersComponent, data: { action: 'accept'}, runGuardsAndResolvers: 'always' },
          // VOD page
          { path: "vod", component: VodComponent },
          { path: "vod/:vodId", component: VodComponent }
        ]
      },
      // Info Pages
      { path: "about/:id", component: AboutUsComponent },
      { path: "membership-terms", component: MembershipTermsComponent },
      { path: "privacy", component: PrivacyPolicyComponent },
      { path: "tos", component: TermsOfServiceComponent },

    ]
  },
  { path: "**", component: NoPageFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
