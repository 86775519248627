import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClassSelectionService } from '../../../../shared/services/class-selection-service';
import { MatTableDataSource } from '@angular/material/table';
import { Tracking, TrackingData } from 'src/app/shared/models/Tracking';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { TrackingService } from 'src/app/shared/services/tracking.service';

@Component({
  selector: 'app-view-tracking',
  templateUrl: './view-tracking.component.html',
  styleUrls: ['./view-tracking.component.css',
    '../add-missions/add-missions.component.css',
    '../edit-class/edit-class.component.css',
    '../participant-list/participant-list.component.css',
    '../manage-classes.component.css'
  ],
})
export class ViewTrackingComponent implements OnInit, AfterViewInit {
  viewMode: 'tracking' | 'missions' = 'missions';
  @Input() viewModeInit!: 'tracking' | 'missions'; // component can be initialized in either 'tracking' or 'missions' view
  @Input() classId!: number;
  @Input() totalMissionDataCount: number = 0;
  @Output() userIdOutput = new EventEmitter<number>();
  @Output() toggleCloseModalEvent = new EventEmitter<void>();
  userId: number;
  userFullname: string;
  className: string;

  userTrackingList: Tracking[] = [];
  paginatedTrackingList: Tracking[] = [];
  totalTrackingDataCount: number = 0;

  // columns for Tracking list
  modalDisplayedColumns: string[] = ['index', 'data', 'createDate'];
  modalDataSource = new MatTableDataSource<Tracking>([]);

  activeModalColumn: string = 'createDate';
  trackingSortDirection: 'asc' | 'desc' = 'desc';

  modalPage: number = 1; // current page in modal
  modalPageSize: number = 10; // default number of items per page in modal
  modalPageSizeOptions: number[] = [5, 10, 20, 50, 100];

  // 종목별 보기 드롭다운
  currentDropdownOption: string = '체력측정';
  dropdownOptions: string[] = ['체력측정', '미션'];
  dropdownFilters: string[] = ['체력측정'];

  trackingDataLoaded: boolean = false;
  trackingFetchErrors: boolean = false;

  constructor(
    private clsSelectionService: ClassSelectionService,
    private trackingService: TrackingService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private cdRef: ChangeDetectorRef
  ) { }

  // Booleans for toggling modals
  modalStates = {
    modal: false, step2Modal: false, step3Modal: false
  };

  // Getters and setters to activate different modals based on the clicked button
  get activeModal(): boolean {
    return this.modalStates?.modal || false;
  }

  set activeModal(value: boolean) {
    if (this.modalStates) {
      this.modalStates.modal = value;
    }
  }

  get activeStep2Modal(): boolean {
    return this.modalStates?.step2Modal || false;
  }

  set activeStep2Modal(value: boolean) {
    if (this.modalStates) {
      this.modalStates.step2Modal = value;
    }
  }

  get activeStep3Modal(): boolean {
    return this.modalStates?.step3Modal || false;
  }

  set activeStep3Modal(value: boolean) {
    if (this.modalStates) {
      this.modalStates.step3Modal = value;
    }
  }

  async ngOnInit(): Promise<void> {
    this.viewMode = this.viewModeInit;

    this.currentDropdownOption = this.viewMode === 'missions' ? '미션' : '체력측정';

    this.userIdOutput.emit(this.userId);

    this.clsSelectionService.userId$.subscribe((userId) => {
      this.userId = userId;
    });

    this.clsSelectionService.userFullname$.subscribe((userFullname) => {
      this.userFullname = userFullname;
    });

    this.clsSelectionService.selectedClass$.subscribe((cls) => {
      this.className = cls.getClassName();
    });
  }

  async ngAfterViewInit(): Promise<void> {
    try {
      await this.buildTrackingList();
      this.totalTrackingDataCount = this.userTrackingList.length;
      this.sortModalData('createDate');
    } catch (error) {
      console.log('error fetching tracking data on init:', error);
      this.trackingFetchErrors = true;
      this.trackingDataLoaded = true;
      this.cdRef.detectChanges();
    }
  }

  async buildTrackingList(): Promise<void> {
    try {
      this.trackingDataLoaded = false;
      this.trackingFetchErrors = false; // reset errors at the start

      this.userTrackingList = await this.trackingService.getTrackingDataForClass(this.classId, this.userId);
      this.userTrackingList.forEach(
        (item) => {
          item.setClassId(this.classId);
          item.setUserId(this.userId);
        }
      );
    } catch (error) {
      console.log('error building raw tracking list for user:', error);
      this.trackingFetchErrors = true;
      this.trackingDataLoaded = true;
      this.cdRef.detectChanges();
      return;
    } finally {
      this.trackingDataLoaded = !this.trackingFetchErrors; // set loaded to true only if no errors
      this.cdRef.detectChanges(); // ensure changes are reflected in UI
    }
  }

  updatePaginatedTrackingList(): void {
    const startIndex = (this.modalPage - 1) * this.modalPageSize;
    const endIndex = startIndex + this.modalPageSize;

    // use the deduplicated list for pagination
    this.paginatedTrackingList = this.userTrackingList.slice(startIndex, endIndex);

    // update the data source for the table
    this.modalDataSource.data = [...this.paginatedTrackingList];
    this.trackingDataLoaded = true;
  }

  /**
   * Method to reset the user list to its original form (전체보기) when the '전체' dropdown option is selected.
   */
  resetFilteredList(): void {
    //this.buildFilteredList(this.showAllFilter);
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  isTrackedCategory(dataType: string): boolean {
    const trackedCategories = [
      '무게',
      '근력 등급',
      '유연성 등급',
      '순발력 등급',
      '심폐지구력 등급',
      '키'
    ];
    return trackedCategories.includes(dataType);
  }

  isMeasurementItem(item: Tracking): boolean {
    return item.getContentType() === '체력측정';
  }

  getUniqueMeasurementItems(element: Tracking): Tracking[] {
    // directly return the data if the content type matches
    if (element.getContentType() === '체력측정') {
      return this.userTrackingList.filter((item) =>
        this.isSameDay(item.getCreateDate(), element.getCreateDate())
      );
    }

    return [];
  }

  formatTrackingData(element: Tracking): SafeHtml {
    const generateTableRow = (data: TrackingData[], dataTypeOrder: string[]) => {
      return dataTypeOrder
        .map((orderedType) => {
          const primaryData = data.find((item) => item.data_type === orderedType);
          const gradeData = data.find(
            (item) => item.data_type === `${orderedType} 등급`
          );

          if (!primaryData && !gradeData) {
            return ''; // skip if no relevant data
          }

          // column 1: icon + data_type
          const iconPath = this.mapMeasurementIcon(orderedType);
          const column1 = iconPath
            ? `<td class="table-cell-left">
                  <div class="width-40"></div>
                  <img src="${iconPath}" class="list-icon">
                  <div class="table-cell-text">${this.formatMeasurementType(
              orderedType
            )}</div>
               </td>`
            : `<td class="table-cell-left">
                  <div class="width-40"></div>
                  <div class="table-cell-text">${this.formatMeasurementType(
              orderedType
            )}</div>
               </td>`;

          // column 2: primary data_value
          const column2 = primaryData
            ? `<td class="table-cell">${primaryData.data_value}${this.formatUnit(
              primaryData.data_type
            )}</td>`
            : '<td class="table-cell"> - </td>';

          // column 3: grade data_value
          const column3 = gradeData
            ? `<td class="table-cell">${gradeData.data_value}${this.formatUnit(
              gradeData.data_type
            )}</td>`
            : '<td class="table-cell"> - </td>';

          // return the full row
          return `<tr class="table-row">${column1}${column2}${column3}</tr>`;
        })
        .filter((row) => row !== '') // remove empty rows
        .join('');
    };

    const generateBMIRow = (data: TrackingData[]) => {
      const heightData = data.find((item) => item.data_type === '키');
      const weightData = data.find((item) => item.data_type === '무게');

      if (heightData && weightData) {
        const heightInMeters = heightData.data_value / 100;
        const weightInKg = weightData.data_value;
        const bmi = (weightInKg / (heightInMeters * heightInMeters)).toFixed(1);

        const iconPath = this.mapMeasurementIcon('BMI');
        const column1 = iconPath
          ? `<td class="table-cell-left">
                <div class="width-40"></div>
                <img src="${iconPath}" class="list-icon">
                <div class="table-cell-text">BMI</div>
             </td>`
          : `<td class="table-cell-left">
                <div class="width-40"></div>
                <div class="table-cell-text">BMI</div>
             </td>`;

        const column2 = `<td class="table-cell">${bmi}</td>`;
        const column3 = '<td class="table-cell"> - </td>';

        return `<tr class="table-row">${column1}${column2}${column3}</tr>`;
      }

      return ''; // skip BMI row if height or weight is missing
    };

    // updated data type order
    const dataTypeOrder = [
      '근력',
      '유연성',
      '순발력',
      '심폐지구력',
      '키',
      '무게',
    ];

    const trackingData = element.getTrackingData();
    const formattedData = generateTableRow(trackingData, dataTypeOrder);
    const bmiRow = generateBMIRow(trackingData);

    return this.sanitizer.bypassSecurityTrustHtml(`
      <table class="tracking-table">
        ${formattedData}
        ${bmiRow}
      </table>
    `);
  }

  formatMeasurementType(dataType: string): string {
    switch (dataType) {
      case '칼로리':
        return '';
      case '순발력 등급':
        return '순발력 ';
      case '유연성 등급':
        return '유연성 ';
      case '심폐지구력 등급':
        return '심폐지구력 ';
      case '키':
        return '신장 ';
      case '근력 등급':
        return '근력 ';
      case '무게':
        return '체중 ';
      default:
        return dataType;
    }
  }

  formatUnit(dataType: string): string {
    switch (dataType) {
      case '무게':
        return 'kg';
      case '시간':
        return '분';
      case '칼로리':
        return 'kcal 소모';
      case '터치':
        return '회';
      case '거리':
        return 'm';
      case '근력':
        return '회';
      case '근력 등급':
        return '등급';
      case '순발력':
        return '초';
      case '순발력 등급':
        return '등급';
      case '유연성':
        return 'cm';
      case '유연성 등급':
        return '등급';
      case '심폐지구력':
        return '세트';
      case '심폐지구력 등급':
        return '등급';
      case '키':
        return 'cm';
      default:
        return '';
    }
  }

  formatContentType(elementData: Tracking): string {
    if (this.viewMode === 'missions') {
      return '미션';
    }

    const contentType = elementData.getContentType();

    if (!contentType) {
      return '--';
    }

    return this.mapContentType(contentType);
  }

  /** Method to toggle sorting function for a certain column in the trackingList
   * @param column string value that represents the column to be sorted
   */
  toggleModalSort(column: string): void {
    if (this.activeModalColumn === column) {
      this.trackingSortDirection = this.trackingSortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.activeModalColumn = column;
      this.trackingSortDirection = 'desc';
    }

    this.sortModalData(column);
  }

  /**
 * Method that sorts the trackingList based on a selected column.
 * @param column column to be sorted
 */
  sortModalData(column: string): void {
    // check if column is createDate
    if (column === 'createDate') {
      this.sortByCreateDate();
    } else if (column === 'contentType') {
      this.sortByContentType();
    }

    // update paginated trackingList after sorting
    this.modalPage = 1;
    this.updatePaginatedTrackingList();
  }

  sortByCreateDate(): void {
    this.userTrackingList = this.userTrackingList.sort((a, b) => {
      let valueA = a['createDate'];
      let valueB = b['createDate'];

      // compare the dates
      if (this.trackingSortDirection === 'asc') {
        return valueA.getTime() - valueB.getTime(); // ascending order
      } else {
        return valueB.getTime() - valueA.getTime();
      }
    });
  }

  sortByContentType(): void {
    this.userTrackingList = this.userTrackingList.sort((a, b) => {
      let valueA = a['contentType'];
      let valueB = b['contentType'];

      if (this.trackingSortDirection === 'asc') {
        // standard comparison for other values
        return valueA.localeCompare(valueB, 'en');
      } else {
        return valueB.localeCompare(valueA, 'en');
      }
    });
  }

  mapMeasurementIcon(dataType: string): string {
    const iconLocation = '../../../../assets/measurement_icons/';
    let filePath = '';
    switch (dataType) {
      case '시간':
        return '../../../../assets/icons/Icon_64px_col-98.png';
      case '터치':
        filePath = 'touch.png';
        break;
      case '무게':
        filePath = 'weight.gif';
        break;
      case '근력':
        filePath = 'strength.png';
        break;
      case '근력 등급':
        filePath = 'strength.png';
        break;
      case '유연성':
        filePath = 'flexibility.png';
        break;
      case '유연성 등급':
        filePath = 'flexibility.png';
        break;
      case '순발력':
        filePath = 'agility.png';
        break;
      case '순발력 등급':
        filePath = 'agility.png';
        break;
      case '심폐지구력':
        filePath = 'endurance.png';
        break;
      case '심폐지구력 등급':
        filePath = 'endurance.png';
        break;
      case '키':
        filePath = 'height.png';
        break;
      case '칼로리':
        return '../../../../assets/icons/Icon_64px_col-99.png';
      case 'BMI':
        return '../../../../assets/icons/Icon_64px_col-92.png';
      default:
        return ''
    }
    return iconLocation + filePath;
  }

  mapContentType(contentType: string): string {

    switch (contentType) {
      case '':
        return '--';
      default:
        return contentType;
    }
  }

  onModalPageChange(newPage: number): void {
    this.modalPage = newPage;
    this.updatePaginatedTrackingList();
  }

  /**
   * Method to change content type filter option.
   * @param selectedOption the selected option from the button bar
   */
  onDropdownOptionChange(selectedOption: string): void {
    this.currentDropdownOption = selectedOption; // update content value
    switch (this.currentDropdownOption) {
      case '체력측정':
        this.viewMode = 'tracking';
        break;
      case '미션':
        this.viewMode = 'missions';
        break;
      default:
        this.viewMode = 'tracking';
        break;
    }
  }

  /** 
    * Method to change pagination paging size for trackingList.
    * @param event dropdown option selection event
    */
  onModalPageSizeChange(event: any): void {
    this.modalPageSize = event.target.value; // update page size
    this.modalPage = 1; // reset to first page
    this.updatePaginatedTrackingList();
  }

  onToggleCloseModal(): void {
    this.toggleCloseModalEvent.emit();
  }

  dateToString(date: Date): string {
    if (!date) {
      return '--';
    }

    return this.datePipe.transform(date, 'yyyy.MM.dd') || '';
  }

  updateMissionCount(count: number): void {
    this.totalMissionDataCount = count;
  }

  /**
   * Method to scroll to the top with an offset (in pixels)
   * @param offset top offset in pixels
   */
  scrollTop(offset: number): void {
    window.scrollTo({ top: offset, behavior: 'smooth' }); // scroll to top smoothly
  }
}
