import { Injectable } from '@angular/core';
import { OrganizationService } from './organization.service';
import { AdminService } from './admin.service';
import { ClassCourse, ClassType } from '../models/ClassCourse';
import { ClassInfo } from '../models/ClassInfo';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Organization, OrgType } from '../models/Organization';
import { firstValueFrom } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

const httpOptionsNoToken = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class ClassCourseService {
  apiOrganizationUrl = environment.apiUrl + '/organization';
  orgId: number = null;
  currAdminId: number = null;

  constructor(
    private orgService: OrganizationService,
    private userService: AdminService,
    private http: HttpClient
  ) {
    this.orgId = orgService.getOrganizationForAdminUser().getId();
    this.currAdminId = this.userService.getCurrentAdminSession().getUserId();
  }

  /**
   * Method to return all classes (open, closed, archived) for the current user's organization.
   * @returns total class list for organization
   */
  getClassCoursesForOrg(classType?: number): Observable<ClassCourse[]> {
    let body;
    if (classType) {
      body = {
        org_id: this.orgId,
        class_type: classType
      }
    } else {
      body = {
        org_id: this.orgId
      }
    }

    return this.http.post<any[]>(this.apiOrganizationUrl + '/class/get', body, httpOptionsNoToken).pipe(
      map((rawClasses) => {
        return rawClasses.map((rawClass) => this.deserializeClass(rawClass));
      }),
      //tap((classes) => console.log('Deserialized classes:', classes)) // optional logging
    );
  }

  getPublicClassCoursesForOrg(): Observable<ClassCourse[]> {
    const body = {
      org_id: this.orgId,
      class_type: 1
    };

    return this.http.post<any[]>(this.apiOrganizationUrl + '/class/get', body, httpOptionsNoToken).pipe(
      map((rawClasses) => {
        return rawClasses.map((rawClass) => this.deserializeClass(rawClass));
      }),
      //tap((classes) => console.log('Deserialized public classes:', classes)) // optional logging
    );
  }

  getPrivateClassCoursesForOrg(): Observable<ClassCourse[]> {
    const body = {
      org_id: this.orgId,
      class_type: 0
    };

    return this.http.post<any[]>(this.apiOrganizationUrl + '/class/get', body, httpOptionsNoToken).pipe(
      map((rawClasses) => {
        return rawClasses.map((rawClass) => this.deserializeClass(rawClass));
      }),
      // //tap((classes) => console.log('Deserialized closed classes:', classes)) // optional logging
    );
  }


  /**
   * Method to return all classes (open, closed, archived) for the current user.
   * @returns total class list managed by user
   */
  getClassCoursesForAdmin(classType?: number): Observable<ClassCourse[]> {
    const body: any = {
      org_id: this.orgId,
      admin_id: this.currAdminId
    };

    if (classType !== undefined) {
      body.class_type = classType; // add class_type only if provided
    }

    return this.http.post<ClassCourse[]>(`${this.apiOrganizationUrl}/class/get/admin`, body, httpOptionsNoToken).pipe(
      map((classes) => {
        return classes
          .map(cls => this.deserializeClass(cls)) // deserialize each class
      }),
      //tap((filteredClasses) => console.log('Filtered classes for admin:', filteredClasses)) // optional logging
    );
  }

  getOpenClassesForOrg(classType?: number): Observable<ClassCourse[]> {
    const body: any = { org_id: this.orgId }; // initialize body with common fields
    /**
     * if (classType !== undefined) {
      body.class_type = classType; // add class_type conditionally
    }
     */

    return this.http.post<ClassCourse[]>(`${this.apiOrganizationUrl}/class/get/open`, body, httpOptionsNoToken).pipe(
      map((classes) => {
        return classes
          .map(cls => this.deserializeClass(cls)) // deserialize each class
      }),
      //tap((filteredClasses) => console.log('Filtered classes for admin:', filteredClasses)) // optional logging
    );
  }

  getClosedClassesForOrg(classType?: number): Observable<ClassCourse[]> {
    const body: any = { org_id: this.orgId }; // initialize body with common fields
    /**
     * if (classType !== undefined) {
      body.class_type = classType; // add class_type conditionally
    }
     */

    return this.http.post<ClassCourse[]>(`${this.apiOrganizationUrl}/class/get/close`, body, httpOptionsNoToken).pipe(
      map((classes) => {
        return classes
          .map(cls => this.deserializeClass(cls)) // deserialize each class
      }),
      //tap((filteredClasses) => console.log('Filtered classes for admin:', filteredClasses)) // optional logging
    );
  }

  getClassById(classId: number): Observable<ClassCourse> {
    const body = {
      class_id: classId
    };

    return this.http.post<ClassCourse>(`${this.apiOrganizationUrl}/class/get/id`, body, httpOptionsNoToken).pipe(
      map((cls) => this.deserializeClass(cls)), // deserialize the fetched class
      //tap((deserializedClass) => console.log('Fetched class:', deserializedClass)) // optional logging
    );
  }

  /**
   * Method to return the class info for a class with a given ID.
   * @param classId class ID to fetch associated class info
   */
  getClassInfoById(classId: number): Observable<ClassInfo> {
    const body = {
      class_id: classId
    };

    return this.http.post<ClassInfo>(`${this.apiOrganizationUrl}/class/info/get`, body, httpOptionsNoToken).pipe(
      map((cls) => this.deserializeClassInfo(cls)), // deserialize the fetched class
      // //tap((deserializedClassInfo) => console.log('Fetched class info:', deserializedClassInfo)) // optional logging
    );
  }

  /**
 * Updates the details of a class course, including file upload for the thumbnail.
 * @param cls the class course to update
 * @param thumbnailFile the thumbnail file to upload
 * @returns a promise resolving to 1 for success or 0 for failure
 */
  setClassCourse(cls: ClassCourse, thumbnailFile?: File): Promise<number> {
    const formData = new FormData();

    // Append class course fields
    formData.append('className', cls.getClassName());
    formData.append('classId', cls.getClassId().toString());
    formData.append('capacity', cls.getCapacity().toString());
    formData.append('classType', cls.getClassType().toString());
    formData.append('startDate', new Date(cls.getStartDate()).toISOString());
    formData.append('endDate', new Date(cls.getEndDate()).toISOString());
    formData.append('classIntro', cls.getClassIntro());

    // Append the thumbnail file if provided
    if (thumbnailFile) {
      formData.append('thumbnail', thumbnailFile);
    } else {
      // Trim the '(dev)?cdn.tboxfit.com/' prefix if present
      let thumbnailPath = cls.getThumbnail();
      const prefix = environment.cdnUrl + '/';
      if (thumbnailPath.startsWith(prefix)) {
        thumbnailPath = thumbnailPath.substring(prefix.length);
      }
      formData.append('thumbnail', thumbnailPath);
    }

    return firstValueFrom(
      this.http.put<number>(`${this.apiOrganizationUrl}/class/mod`, formData).pipe(
        //tap((res) => console.log('Response:', res)) // Log the response
      )
    );
  }

  /**
   * Updates the information of a class.
   * @param clsInfo the class information to update
   * @returns a promise resolving to 1 for success or 0 for failure
   */
  setClassInfo(clsId: number, clsInfo: ClassInfo): Promise<number> {
    const body = new FormData() as any;
    body.append('classId', clsId);
    body.append('subInstructorName', clsInfo.getSubInstructorName());
    body.append('classGoal', clsInfo.getClassGoal());
    body.append('classTarget', clsInfo.getClassTarget());
    body.append('classLocation', clsInfo.getClassLocation());
    body.append('sessionsPerWeek', clsInfo.getSessionsPerWeek().toString());
    body.append('sessionDuration', clsInfo.getSessionDuration().toString());
    body.append('approxCostPerSession', clsInfo.getApproxCostPerSession().toString());
    body.append('classDays', JSON.stringify(clsInfo.getClassDays())); // Stringify array
    body.append('startTime', clsInfo.getClassTimes().start.toString());
    body.append('finishTime', clsInfo.getClassTimes().end.toString());
    body.append('requiredMaterials', clsInfo.getRequiredMaterials());
    body.append('classContact', clsInfo.getClassContact());

    console.log(Array.from(body.entries()));


    return firstValueFrom(
      this.http.put<number>(`${this.apiOrganizationUrl}/class/info/mod`, body).pipe(
        //tap((res) => console.log('Response:', res)) // Debugging log
      )
    );
  }

  /**
 * Creates a new class course.
 * @param cls the class course to create
 * @param thumbnailFile the thumbnail file to upload
 * @returns a promise resolving to 1 for success or 0 for failure
 */
  createClassCourse(cls: ClassCourse, thumbnailFile?: File): Promise<number> {
    const formData = new FormData();

    // Append class course fields
    formData.append('className', cls.getClassName());
    formData.append('mainInstructorId', cls.getMainInstructorId().toString());
    formData.append('orgId', cls.getOrgId().toString());
    formData.append('capacity', cls.getCapacity().toString());
    formData.append('classType', cls.getClassType().toString());
    formData.append('startDate', new Date(cls.getStartDate()).toISOString());
    formData.append('endDate', new Date(cls.getEndDate()).toISOString());

    return firstValueFrom(
      this.http.post<number>(`${this.apiOrganizationUrl}/class/add`, formData).pipe(
        //tap((res) => console.log('Response:', res)) // Log the response
      )
    );
  }

  /**
 * Creates new information for a class.
 * @param clsInfo the class information to create
 * @returns a promise resolving to 1 for success or 0 for failure
 */
  createClsInfo(clsInfo: ClassInfo, thumbnailFile: File, classIntro: string): Promise<number> {
    const formData = new FormData();

    // Append class information fields
    formData.append('classId', clsInfo.getClassId()['class_id']);
    formData.append('subInstructorName', clsInfo.getSubInstructorName());
    formData.append('classGoal', clsInfo.getClassGoal());
    formData.append('classTarget', clsInfo.getClassTarget());
    formData.append('classLocation', clsInfo.getClassLocation());
    formData.append('sessionsPerWeek', clsInfo.getSessionsPerWeek().toString());
    formData.append('sessionDuration', clsInfo.getSessionDuration().toString());
    formData.append('approxCostPerSession', clsInfo.getApproxCostPerSession().toString());
    formData.append('classDays', JSON.stringify(clsInfo.getClassDays()));
    formData.append('startTime', clsInfo.getClassTimes().start.toString());
    formData.append('finishTime', clsInfo.getClassTimes().end.toString());
    formData.append('requiredMaterials', clsInfo.getRequiredMaterials());
    formData.append('classContact', clsInfo.getClassContact());
    formData.append('thumbnail', thumbnailFile);
    formData.append('classIntro', classIntro);

    return firstValueFrom(
      this.http.post<number>(`${this.apiOrganizationUrl}/class/info/add`, formData).pipe(
        //tap((res) => console.log('Response:', res)) // Log the response
      )
    );
  }

  /**
 * Adds participants to a class.
 * @param classId the ID of the class
 * @param participantList the list of participant IDs to add
 * @returns a promise resolving to 1 for success or 0 for failure
 */
  addParticipants(classId: number, participantList: number[]): Promise<number> {
    const rawList = JSON.stringify(participantList);
    const body = {
      classId: classId,
      participantList: rawList
    };

    return firstValueFrom(
      this.http.post<number>(`${this.apiOrganizationUrl}/class/user/add`, body, httpOptionsNoToken).pipe(
        //tap(response => console.log(`Add participants response: ${response === 1 ? 'Success' : 'Failure'}`)) // Optional logging
      )
    );
  }

  /**
   * Removes participants from a class.
   * @param classId the ID of the class
   * @param participantList the list of participant IDs to remove
   * @returns a promise resolving to 1 for success or 0 for failure
   */
  removeParticipants(classId: number, participantList: number[]): Promise<number> {
    const rawList = JSON.stringify(participantList);
    const body = {
      classId: classId,
      participantList: rawList
    };

    return firstValueFrom(
      this.http.post<number>(`${this.apiOrganizationUrl}/class/user/remove`, body, httpOptionsNoToken).pipe(
        //tap(response => console.log(`Remove participants response: ${response === 1 ? 'Success' : 'Failure'}`)) // Optional logging
      )
    );
  }

  deserializeClass(classCourse: any): ClassCourse {
    const startDate = new Date(classCourse.startDate);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(classCourse.endDate);
    endDate.setHours(23, 59, 59, 999);

    let res = new ClassCourse(
      classCourse.className,
      classCourse.classId,
      classCourse.mainInstructorName,
      classCourse.mainInstructorId,
      classCourse.orgId,
      classCourse.capacity,
      classCourse.classType,
      classCourse.open,
      classCourse.participantList,
      startDate,
      endDate,
      classCourse.classIntro,
      environment.cdnUrl + '/' + classCourse.thumbnail
    );

    // call checkStatus  to set 'open' based on endDate
    // this.checkStatus(res);

    return res;
  }

  deserializeClassInfo(classInfo: any): ClassInfo {
    return new ClassInfo(
      classInfo.classId,
      classInfo.subInstructorName,
      classInfo.classGoal,
      classInfo.classTarget,
      classInfo.classLocation,
      classInfo.sessionsPerWeek,
      classInfo.sessionDuration,
      classInfo.approxCostPerSession,
      classInfo.classDays,
      { start: this.trimSeconds(classInfo.start_time), end: this.trimSeconds(classInfo.finish_time) },
      classInfo.requiredMaterials,
      classInfo.classContact
    );
  }

  trimLeadingZeros(num: string | number): number {
    return Number(String(num).replace(/^0+/, ''));
  }

  trimSeconds(time: string): string {
    return time.replace(/:\d{2}$/, '');
  }
}
